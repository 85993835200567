import React, { Component } from "react";
import PropTypes, { element } from "prop-types";
import { connect } from "react-redux";
import TopBarProgress from "react-topbar-progress-indicator";
import {
  Row,
  Col,
  Checkbox,
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  notification,
  Tooltip,
  Alert,
  message,
  Spin

} from "antd";
import { getCustomers } from "../../../../redux/actions/customerAction";
import { getBrandBySearch } from "../../../../redux/actions/brandAction";
import {
  getSalesBill,
  getSalesUsingBarcode,
  saleReturn,
  updateSaleReturnDefected,
  deletesaleReturn,
  deletesaleReturnDefected,
  updateSaleReturn

} from "../../../../redux/actions/saleAction";
import debounce from 'lodash.debounce';
import BarcodeReader from "react-barcode-reader";
import ShowSalesReturnDetails from "./ShowSalesReturnDetails";
import { StopOutlined, LoadingOutlined } from "@ant-design/icons";
import AddedListReturn from "./AddedListReturn";
import { uuid } from "uuidv4";
import AddListReturnDefected from "./AddListReturnDefected";
import { ArrowLeftOutlined } from "@ant-design/icons";
import AddedListReturNewnDefective from "./AddedListReturnNewDefective";
import moment from "moment";
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
let deleteCount = 0
const dateFormat = "DD/MM/YYYY";

class UpdateSalesReturn extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.formRefMy = React.createRef();
    console.log("this.props.history.location.state?", this.props.history.location.state);
    const returnBillType = this.props.history.location.state?.type
    const saleReturnBillDate = this.props.history.location.state?.type === "Normal"
      ? this.props.history.location.state?.saleReturnObj?.[0]?.date
        ? moment(this.props.history.location.state.saleReturnObj[0].date).toDate()
        : moment().toDate()
      : this.props.history.location.state?.saleReturnDefectedObj?.[0]?.date
        ? moment(this.props.history.location.state.saleReturnDefectedObj[0].date).toDate()
        : moment().toDate();
    const currentDate = moment().format("DD/MM/YYYY");
    const currentTime = moment().format("HH:mm:ss");
    this.state = {
      saveDisableStatus: false,
      lastPath: "",
      salesReturnData: [],
      showReturnListData: [],
      customerName: "",
      customerId: "",
      barcode: "",
      returnType: null,
      maxReturnQty: "",
      quantity: "",
      totalBoxes: "",
      totalAmount: "",
      totalQuantity: "",
      brand: "",
      amount: "",
      uuID: "",
      MOQ: "",
      pd: "",
      id: "",
      remark: "",
      disableField: false,
      isUpdated: false,
      saleReturnBillID: "",
      saleReturnDefectedBillID: "",
      wsp: '',
      color: "",
      // size: '',
      brand: '',
      designNumber: '',
      invoiceId: "",
      saleReturnBillDate: saleReturnBillDate,
      deletedDefSale: [],
      returnBillType: returnBillType,
    };
    this.handleScan = this.handleScan.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.debouncedGetData = debounce(this.getData, 500);
  }
  handleBlur(e) {
    if (e.currentTarget.value === "0") e.currentTarget.value = "1";
  }
  onChange(event) {
    // if (event.target.name === "quantity" && this.state.quantity > 0) {
    //   if (event.target.value > this.props?.salesBillsBarcode?.quantity) {
    //     alert("Can not input quantity greater then sale quantity");
    //     return this.setState({
    //       quantity: this.props?.salesBillsBarcode?.quantity,
    //     });
    //   }
    // }
    this.setState({ [event.target.name]: event.target.value });
  }
  getData = (name, value) => {
    if (name == "customer") this.props.getCustomers("STORE_USER");
    if (name == "brand") this.props.getBrandBySearch(value);
  };
  onBarcodeChange = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (this.state.customerId !== "") {
      if (event.target.value.length == 9) {
        await this.props.getSalesUsingBarcode(
          event.target.value,
          this.state.customerId
        );
      }
      this.setState({
        quantity: this.props?.salesBillsBarcode?.MOQ,
        invoiceId: this.props?.salesBillsBarcode?.invoiceId,
        maxReturnQty: this.props?.salesBillsBarcode?.quantity,
        MOQ: this.props?.salesBillsBarcode?.MOQ,
        wsp: this.props?.salesBillsBarcode?.purchaseDetails?.wsp,
        color: this.props?.salesBillsBarcode?.purchaseDetails?.color,
        size: this.props?.salesBillsBarcode?.purchaseDetails?.size,
        brand: this.props?.salesBillsBarcode?.purchaseDetails?.brand,
        designNumber: this.props?.salesBillsBarcode?.purchaseDetails?.designNumber,
        amount: this.props?.salesBillsBarcode?.purchaseDetails?.wsp,
      });
    }

  };
  async handleScan(data) {
    if (data.length == 9 || data.length == 9) {
      await this.props.getSalesUsingBarcode(data, this.state.customerId);
      this.setState({
        quantity: this.props?.salesBillsBarcode?.MOQ,
        invoiceId: this.props?.salesBillsBarcode?.invoiceId,
        maxReturnQty: this.props?.salesBillsBarcode?.quantity,
        MOQ: this.props?.salesBillsBarcode?.MOQ,
        barcode: data,
        wsp: this.props?.salesBillsBarcode?.purchaseDetails?.wsp,
        color: this.props?.salesBillsBarcode?.purchaseDetails?.color,
        size: this.props?.salesBillsBarcode?.purchaseDetails?.size,
        brand: this.props?.salesBillsBarcode?.purchaseDetails?.brand,
        designNumber: this.props?.salesBillsBarcode?.purchaseDetails?.designNumber,
      });
    }
  }
  handleChangeCustomer = (key, value) => {
    let customerId = value.value;
    let customerName = value.name;
    this.setState({
      customerId: customerId,
      customerName: customerName,
    });
    this.props.getSalesBill(customerId);
  };

  handleChangeBrand = (key, value) => {
    let brandName = value.name;
    this.setState({
      brand: brandName,
    });
    this.debouncedGetData("brand")
  };

  handleReturnChange = (key, value) => {

    this.setState({
      returnType: key,
    });
  };

  createSalesReturn = () => {
    this.setState({ saveDisableStatus: true })
    let userId = localStorage.getItem("userId")
    let obj = {
      salesReturnData: this.state.showReturnListData,
      isUpdated: this.state.isUpdated,
      saleReturnBillID: this.state.saleReturnBillID,
      remark: this.state.remark,
      userId: userId,
      date: this.state.saleReturnBillDate
    }

    let defectiveData = this.state.showReturnListData.map(obj => {
      return {
        brand: obj.brand,
        quantity: Number(obj.quantity),
        maxReturnQty: obj.maxReturnQty ? obj.maxReturnQty : 0,
        amount: Number(obj.amount),
        customerName: obj.customerName,
        userId: obj.userId,
        MOQ: obj.MOQ ? obj.MOQ : 0,
        barcode: obj.barcode ? obj.barcode : 0,
        wsp: obj.wsp ? obj.wsp : 0,
        color: obj.color ? obj.color : "",
        size: obj.size ? obj.size : "",
        designNumber: obj.designNumber ? obj.designNumber : "",
        invoiceId: obj.invoiceId ? obj.invoiceId : "",
        _id: obj._id ? obj._id : ""
      }
    })

    let obj2 = {
      salesReturnData: defectiveData,
      isUpdated: this.state.isUpdated,
      saleReturnDefectedBillID: this.state.saleReturnDefectedBillID,
      remark: this.state.remark,
      invoiceId: this.state.showReturnListData[0].invoiceId,
      userId: userId,
      date: this.state.saleReturnBillDate,
      deleteDefectiveSale: this.state.deletedDefSale

      // saleReturnBillID:this.state.saleReturnBillID
    }
    console.log("obj", obj);

    if (this.state.returnType === "1") {
      this.props.updateSaleReturn(obj).then((response) => {
        if (response.state === 200) {
          this.setState({ saveDisableStatus: false })
        }
      }).catch((error) => console.log("error", error)).finally(() =>
        setTimeout(() => {
          window.location.reload(0)
        }, 1000)
      )
    } else {
      this.props.updateSaleReturnDefected(obj2)
        .then((response) => {
          if (response.state === 200) {
            this.setState({ saveDisableStatus: false })
          }
        }).catch((error) => console.log("error", error)).finally(() =>
          setTimeout(() => {
            window.location.reload(0)
          }, 1000)
        )
    }
  };


  deleteDefectiveSale = (text) => {
    let temp = this.state.showReturnListData

    if (text.deleteCount) {
      const temp1 = temp.filter(obj => obj.deleteCount !== text.deleteCount)
      this.setState({
        showReturnListData: temp1,
        salesReturnData: temp1
      })
    } else {
      const temp1 = temp.filter(obj => obj._id !== text._id)
      this.setState({
        showReturnListData: temp1,
        salesReturnData: temp1
      })
    }
    // let temp = this.state.showReturnListData
    // const temp1 = temp.filter(obj => obj._id !== text._id)
    // this.setState({
    //   showReturnListData: temp1,
    //   salesReturnData: temp1
    // })
    // if (text.barcode) {
    //   let temp = this.state.showReturnListData
    //   const temp1 = temp.filter(obj => obj.barcode !== text.barcode)
    //   this.setState({
    //     showReturnListData: temp1,
    //     salesReturnData: temp1
    //   })
    // } else {
    //   console.log("inside elseeee delete")
    //   let temp = this.state.showReturnListData
    //   const temp1 = temp.filter(obj => obj.brand !== text.brand)
    //   this.setState({
    //     showReturnListData: temp1,
    //     salesReturnData: temp1
    //   })
    // }
  }

  editDefectiveReturn = (text) => {
    let temp = this.state.showReturnListData
    const temp1 = temp.filter(obj => obj._id !== text._id)
    this.setState({
      showReturnListData: temp1,
      salesReturnData: temp1
    })

    this.setState({
      invoiceId: text.invoiceId,
      barcode: text.barcode ? text.barcode : "",
      quantity: text.quantity,
      maxReturnQty: text.maxReturnQty,
      brand: text.brand,
      amount: text.amount,
      userId: text.userId,
      MOQ: text.MOQ,
      wsp: text.wsp,
      color: text.color,
      size: text.size,
      designNumber: text.designNumber,
      customerName: text.customerName,
      _id: text._id,
      isUpdated: false
    });
    this.formRefMy.current.setFieldsValue({
      brand: text.brand,
      customerName: text.customerName
    });

  }



  onAddQntyDefective = (text) => {
    this.setState((prevState) => ({
      showReturnListData: prevState.showReturnListData.map(item =>
        item._id === text._id ? { ...item, quantity: item.quantity + 1 } : item
      ),
      salesReturnData: prevState.salesReturnData.map(item =>
        item._id === text._id ? { ...item, quantity: item.quantity + 1 } : item
      )
    }));
  }

  onRemoveQntyDefective = (text) => {
    this.setState((prevState) => ({
      showReturnListData: prevState.showReturnListData.map(item =>
        item._id === text._id ? { ...item, quantity: item.quantity - 1 } : item
      ),
      salesReturnData: prevState.salesReturnData.map(item =>
        item._id === text._id ? { ...item, quantity: item.quantity - 1 } : item
      )
    }));
  }






  addSalesReturn = () => {
    if (this.state.quantity % this.props.salesBillsBarcode.MOQ != 0) {
      message.warn("add quantity only in multiple of MOQ ")
      return this.setState({
        quantity: this.props?.salesBillsUser?.quantity
      });
    } else {

      this.setState({ disableField: true });
      if (this.state.returnType === "1") {
        let returnObj = {
          id: this.props.salesBillsBarcode._id,
          quantity: this.state.quantity,
          invoiceId: this.state.invoiceId,
          barcode: this.state.barcode,
          customerName: this.state.customerName,
          maxReturnQty: this.state.maxReturnQty,
          MOQ: this.state.MOQ,
          wsp: this.state.wsp,
          color: this.state.color,
          size: this.state.size,
          brand: this.state.brand,
          designNumber: this.state.designNumber,

        };
        this.setState({
          salesReturnData: returnObj,
          ...this.state.salesReturnData
        })
        this.state.salesReturnData.push(returnObj);

        //TABLE
        let showReturnDataObj = {
          id: this.props.salesBillsBarcode._id,
          quantity: this.state.quantity,
          invoiceId: this.state.invoiceId,
          barcode: this.state.barcode,
          customerName: this.state.customerName,
          maxReturnQty: this.state.maxReturnQty,
          MOQ: this.state.MOQ,
          wsp: this.state.wsp,
          color: this.state.color,
          size: this.state.size,
          brand: this.state.brand,
          designNumber: this.state.designNumber,
        };

        this.setState({
          salesReturnData: [
            showReturnDataObj,
            ...this.state.showReturnListData,
          ],
        });
        // this.props.saleReturn({ salesReturnData: this.state.showReturnListData }, this.props.history);
        this.setState({
          barcode: "",
          quantity: "",
          maxReturnQty: "",
          invoiceId: "",
          brand: "",
          amount: "",
          MOQ: "",
          wsp: "",
          color: "",
          size: "",
          brand: "",
          designNumber: ""
        });

        this.formRef.current.setFieldsValue({
          customerName: undefined,
        });
      } else {
        let returnDefectedObj = {
          brand: this.state.brand,
          quantity: this.state.quantity,
          maxReturnQty: this.state.maxReturnQty,
          amount: this.state.amount,
          userId: this.state.customerId,
          MOQ: this.state.MOQ,
          barcode: this.state.barcode,
          wsp: this.state.wsp,
          invoiceId: this.state.invoiceId,
          color: this.state.color,
          size: this.state.size,
          brand: this.state.brand,
          designNumber: this.state.designNumber,
        };
        // this.setState({
        //   showReturnListData:returnDefectedObj,
        //   ...this.state.showReturnListData
        // })
        this.setState({
          salesReturnData: returnDefectedObj,
          ...this.state.salesReturnData
        })
        // this.state.salesReturnData.push(returnDefectedObj);

        //TABLE
        let showReturnDataDefctiveObj = {
          brand: this.state.brand,
          quantity: this.state.quantity,
          maxReturnQty: this.state.maxReturnQty,
          amount: this.state.amount,
          customerName: this.state.customerName,
          userId: this.state.customerId,
          MOQ: this.state.MOQ,
          invoiceId: this.state.invoiceId,
          barcode: this.state.barcode,
          wsp: this.state.wsp,
          color: this.state.color,
          size: this.state.size,
          brand: this.state.brand,
          designNumber: this.state.designNumber,
        };
        // this.setState({
        //   showReturnListData: [
        //     showReturnDataDefctiveObj,
        //     ...this.state.showReturnListData,
        //   ],
        // });
        this.setState({
          salesReturnData: [
            showReturnDataDefctiveObj,
            ...this.state.showReturnListData,
          ],
        });
        this.setState({
          barcode: "",
          quantity: "",
          maxReturnQty: "",

          brand: "",
          amount: "",
          userId: "",
          MOQ: "",
          wsp: "",
          color: "",
          size: "",
          brand: "",
          designNumber: "",
        });
        // this.formRef.current.setFieldsValue({
        //   customerName: undefined,
        // });
      };





      //for checking existing obj {dg}
      const existingObjIndex = this.state.showReturnListData.findIndex(obj => obj.barcode === this.state.barcode);
      if (existingObjIndex >= 0) {

        const existingObj = this.state.showReturnListData[existingObjIndex];


        if (Number(this.state.quantity) + Number(existingObj.quantity) <= Number(existingObj.maxReturnQty)) {
          this.setState({
            salesReturnData: this.setState.showReturnListData
          })
          const updatedObj = { ...existingObj, quantity: Number(existingObj.quantity) + Number(this.state.quantity) };

          const updatedListData = this.state.salesReturnData;
          updatedListData[existingObjIndex] = updatedObj;

          const updatedListData1 = this.state.showReturnListData;
          updatedListData1[existingObjIndex] = updatedObj;
          this.setState({
            showReturnListData: updatedListData,
            salesReturnData: updatedListData1,
          });




          // ==================

          //   const updatedObj = { ...existingObj, quantity: Number(existingObj.quantity) + Number(existingObj.MOQ) };
          //   const updatedListData = this.state.showReturnListData;
          //   updatedListData[existingObjIndex] = updatedObj;
          //   // const updatedListData1 = this.state.showReturnListData;
          //   // updatedListData1[existingObjIndex] = updatedObj;
          //  console.log("check nagor", updatedListData)
          //   this.setState({
          //     //  showReturnListData: [updatedListData,
          //     //   ...this.state.showReturnListData

          //     // ],
          //     //  salesReturnData:[updatedListData,
          //     //   ...this.state.salesReturnData]
          //     showReturnListData: updatedListData,
          //     // salesReturnData: updatedListData1,
          //   });

        } else {
          message.warn("cannot Add more than maximum Quantity")
          this.setState({
            showReturnListData: this.state.showReturnListData,
            salesReturnData: this.state.salesReturnData,
          })
        }
      } else {
        if (this.state.returnType === "1") {
          const newObj = {
            quantity: this.state.quantity,
            invoiceId: this.state.invoiceId,
            id: this.props.salesBillsBarcode._id,
            barcode: this.state.barcode,
            customerName: this.state.customerName,
            maxReturnQty: this.state.maxReturnQty,
            MOQ: this.state.MOQ,
            wsp: this.state.wsp,
            color: this.state.color,
            size: this.state.size,
            brand: this.state.brand,
            designNumber: this.state.designNumber,
          };

          this.setState({
            showReturnListData: [newObj,
              ...this.state.showReturnListData,
            ],
          });
        }
        else {
          const newRDObj = {
            brand: this.state.brand,
            quantity: this.state.quantity,
            maxReturnQty: this.state.maxReturnQty,
            amount: this.state.amount,
            customerName: this.state.customerName,
            userId: this.state.customerId,
            MOQ: this.state.MOQ,
            barcode: this.state.barcode,
            wsp: this.state.wsp,
            color: this.state.color,
            size: this.state.size,
            brand: this.state.brand,
            invoiceId: this.state.invoiceId,
            designNumber: this.state.designNumber,

          };
          this.setState({
            showReturnListData: [newRDObj,
              ...this.state.showReturnListData
            ],
          });
        }
      }
    };

  }




  checkBarcodeExists = (barcode) => {
    let exist = this.state.showReturnListData.findIndex(
      (element) => element.barcode === barcode
    );
    return exist;
  };


  deleteSale = (id) => {
    let temp = this.state.showReturnListData
    const temp1 = temp.filter(obj => obj.id !== id)
    this.setState({
      showReturnListData: temp1,
      salesReturnData: temp1
    })
  }


  checkMinimumQty = (data) => {
    if (data.currentStock === 0) {
      return data.totalQuantity
    }
    if (data.currentStock >= data.MOQ) {
      return data.totalQuantity - data.currentStock
    } else {
      return data.MOQ
    }
  }

  onRemoveQnty = (obj) => {
    let index = this.checkBarcodeExists(obj.barcode);
    let temp = this.state.showReturnListData;
    let returnTemp = this.state.salesReturnData;
    let minOrderQuantiy = this.checkMinimumQty(temp[index])
    minOrderQuantiy = minOrderQuantiy > temp[index].MOQ ? minOrderQuantiy : temp[index].MOQ
    if (temp[index].quantity > minOrderQuantiy) {
      temp[index].quantity = Number(temp[index].quantity) - Number(temp[index].MOQ);
      returnTemp[index].quantity = Number(returnTemp[index].quantity) //- Number(temp[index].MOQ);
    } else {
      message.warn("Minimum quantity reached!")
    }
    this.setState({
      showReturnListData: temp,
      salesReturnData: returnTemp,
      pd: uuid(),
    });
  };
  onAddQnty = async (obj) => {
    if (this.state.returnType === "1") {
      if (obj.saleReturnId) {
        let index = this.checkBarcodeExists(obj.barcode);
        let saleData = await this.props.getSalesUsingBarcode(obj.barcode, obj.userId)


        this.setState({ maxReturnQty: this.props?.salesBillsBarcode?.totalSalesQuantity, })
        let temp = this.state.showReturnListData;
        let returnTemp = this.state.salesReturnData;
        // console.log("test successfull===", temp, returnTemp, temp[index].MOQ, temp[index].maxReturnQty);
        // console.log("temp[index].quantity", temp[index].quantity)
        // console.log("temp[index].MOQ", temp[index].MOQ)
        if (Number(temp[index].quantity) + 1 <= Number(this.state.maxReturnQty)) {

          temp[index].quantity = Number(temp[index].quantity) + Number(temp[index].MOQ);
          returnTemp[index].quantity = Number(returnTemp[index].quantity) //+ Number(temp[index].MOQ);
          this.setState({
            showReturnListData: temp,
            salesReturnData: returnTemp,
            pd: uuid(),

          });
        } else {
          message.warn("Maximum quantity reached!")
        }

      } else {
        let index = this.checkBarcodeExists(obj.barcode);
        // console.log("this.props?.salesBillsBarcode?.quantity", obj.barcode, obj.userId, obj)
        // let saleData =await this.props.getSalesUsingBarcode(obj.barcode, obj.userId)
        // this.setState({maxReturnQty: this.props?.salesBillsBarcode?.quantity,})
        // console.log("saleData", saleData, this.state.maxReturnQty)
        let temp = this.state.showReturnListData;
        let returnTemp = this.state.salesReturnData;
        if (Number(temp[index].quantity) + 1 <= Number(temp[index].maxReturnQty)) {

          temp[index].quantity = Number(temp[index].quantity) + Number(temp[index].MOQ);
          returnTemp[index].quantity = Number(returnTemp[index].quantity) //+ Number(temp[index].MOQ);
          this.setState({
            showReturnListData: temp,
            salesReturnData: returnTemp,
            pd: uuid(),

          });
        } else {
          message.warn("Maximum quantity reached!")
        }
      }
    } else {
      if (obj.saleReturnId) {
        let index = this.checkBarcodeExists(obj.barcode);
        let saleData = await this.props.getSalesUsingBarcode(obj.barcode, obj.userId)
        this.setState({ maxReturnQty: this.props?.salesBillsBarcode?.totalSalesQuantity, })
        let temp = this.state.showReturnListData;
        let returnTemp = this.state.salesReturnData;
        if (Number(temp[index].quantity) + 1 <= Number(this.state.maxReturnQty)) {

          temp[index].quantity = Number(temp[index].quantity) + Number(temp[index].MOQ);
          returnTemp[index].quantity = Number(returnTemp[index].quantity) //+ Number(temp[index].MOQ);
          this.setState({
            showReturnListData: temp,
            salesReturnData: returnTemp,
            pd: uuid(),

          });
        } else {
          message.warn("Maximum quantity reached!")
        }
      } else {
        let index = this.checkBarcodeExists(obj.barcode);

        let temp = this.state.showReturnListData;
        let returnTemp = this.state.salesReturnData;
        if (Number(temp[index].quantity) + 1 <= temp[index].maxReturnQty) {

          temp[index].quantity = Number(temp[index].quantity) + Number(temp[index].MOQ);
          returnTemp[index].quantity = Number(returnTemp[index].quantity) //+ Number(temp[index].MOQ);
          this.setState({
            showReturnListData: temp,
            salesReturnData: returnTemp,
            pd: uuid(),

          });
        } else {
          message.warn("Maximum quantity reached!")
        }
      }
    }
  };
  deletesaleReturn = (_id) => {
    // console.log("delete id---", _id)
    this.props.deletesaleReturn(_id)
    let temp = this.state.showReturnListData
    temp = temp.filter(obj => obj._id !== _id)
    let returnTemp = this.state.salesReturnData
    returnTemp = returnTemp.filter(obj => obj._id !== _id)
    this.setState({
      showReturnListData: temp,
      salesReturnData: returnTemp,
      // pd: uuid(),
    });
  };

  deletesaleReturnDefected = (text) => {
    let id = text._id
    this.props.deletesaleReturnDefected(id)
    let temp = this.state.showReturnListData
    temp = temp.filter(obj => obj._id !== id)
    let returnTemp = this.state.salesReturnData
    returnTemp = returnTemp.filter(obj => obj._id !== id)
    this.setState({
      showReturnListData: temp,
      salesReturnData: returnTemp,
      // pd: uuid(),
    });
  };


  addDefectiveSalesReturn = () => {

    this.setState({ disableField: true });
    if (this.state.quantity && this.state.amount && this.state.brand && this.state.customerName) {

      //TABLE
      let showReturnDataDefctiveObj = {
        brand: this.state.brand,
        quantity: this.state.quantity,
        maxReturnQty: this.state.maxReturnQty ? this.state.maxReturnQty : 0,
        amount: this.state.amount,
        userId: this.state.customerId,
        MOQ: this.state.MOQ ? this.state.MOQ : 0,
        barcode: this.state.barcode ? this.state.barcode : 0,
        wsp: this.state.wsp ? this.state.wsp : 0,
        invoiceId: this.state.invoiceId ? this.state.invoiceId : 0,
        color: this.state.color ? this.state.color : "",
        size: this.state.size ? this.state.size : "",
        designNumber: this.state.designNumber ? this.state.designNumber : "",
        deleteCount: deleteCount,
        _id: this.state._id

      };

      this.setState({
        salesReturnData: [
          showReturnDataDefctiveObj,
          ...this.state.showReturnListData,
        ],
      });

      const newRDObj = {
        brand: this.state.brand,
        quantity: this.state.quantity,
        maxReturnQty: this.state.maxReturnQty ? this.state.maxReturnQty : 0,
        amount: this.state.amount,
        customerName: this.state.customerName,
        userId: this.state.customerId,
        MOQ: this.state.MOQ ? this.state.MOQ : 0,
        barcode: this.state.barcode ? this.state.barcode : 0,
        wsp: this.state.wsp ? this.state.wsp : 0,
        color: this.state.color ? this.state.color : "",
        size: this.state.size ? this.state.size : "",
        designNumber: this.state.designNumber ? this.state.designNumber : "",
        invoiceId: this.state.invoiceId ? this.state.invoiceId : "",
        deleteCount: deleteCount,
        _id: this.state._id

      };
      this.setState({
        showReturnListData: [newRDObj,
          ...this.state.showReturnListData
        ],
      });
    } else {
      message.error("Please Fill All Details")
    };
    deleteCount++
    this.setState({
      barcode: "",
      quantity: "",
      maxReturnQty: "",
      // brand: "",
      amount: "",
      userId: "",
      MOQ: "",
      wsp: "",
      color: "",
      size: "",
      // brand: "",
      designNumber: "",
      _id: ""
    });
    this.setState({
      isUpdated: true
    })
  }


  onDateChange = (date, dateString) => {

    let currentTime = moment().format('HH:mm:ss');
    let d = moment(dateString + ' ' + currentTime, 'DD/MM/YYYY HH:mm:ss').toDate();
    console.log("d-123", d);

    this.setState({ saleReturnBillDate: d });
  }


  //for history
  componentDidMount() {
    if (
      this.props.history.location.state == undefined ||
      this.props.history.location.state == null
    )
      return null;
    if (
      this.props.history.location.state != undefined ||
      this.props.history.location.state != null
    ) {
      // this.setState({
      //   updateBill: this.props.history.location.state.updateBill,
      // });
      this.setState({ lastPath: this.props?.history?.location?.from })
      if (this.props.history.location.state.saleReturnObj) {
        let saleReturnObj = this.props.history.location.state.saleReturnObj;
        if (saleReturnObj) {
          this.setState({ remark: saleReturnObj[0]?.remark })
          this.setState({ update: true });
          this.setState({ isUpdated: true })
        }
        if (this.props.history.location.state.type === 'Normal') {
          this.setState({ returnType: '1' })
        }

        // this.setState({ tempSaleObj: saleObj });
        let data = saleReturnObj[1];
        let billData = saleReturnObj[0];
        this.setState({
          salesBillsUser: saleReturnObj,
          pd: uuid(),
        })
        let e = 0;
        let f = data.length;
        let finalSaleReturnData = [];

        if (saleReturnObj[0] && saleReturnObj[0].remark) {
          this.setState({ remark: saleReturnObj[0].remark })
        }
        while (e < f) {
          // data[e].details.boxes = data[e].quantity / data[e].details.MOQ;
          //data[e].details.saleReturnQty = data[e].quantity;
          data[e].details._id = data[e]._id
          data[e].details.quantity = data[e].quantity;
          data[e].details.created_at = billData.date
          data[e].details.barcode = data[e].barcode
          data[e].details.invoiceId = data[e].invoiceId

          data[e].details.MOQ = data[e].MOQ;
          data[e].details.totalBoxes = billData.totalBoxes;
          // data[e].details.finalAmount = data[e].details.MOQ * data[e].details.wsp;
          // data[e].details.salesPersonId = data[e].salesPersonId;
          data[e].details.saleReturnId = data[e].saleReturnId;
          data[e].details.price = data[e].price
          data[e].details.invoiceId = data[e].invoiceId;
          data[e].details.barcode = data[e].barcode;

          // =============
          data[e].details.designNumber = data[e].purchaseDetails.designNumber;
          data[e].details.brand = data[e].purchaseDetails.brand;
          data[e].details.size = data[e].purchaseDetails.size;
          data[e].details.color = data[e].purchaseDetails.color;
          data[e].details.wsp = data[e].purchaseDetails.wsp;

          data[e].details.currentStock = data[e].currentStock;
          // data[e].details.created_at = billData[e].created_at
          // billData[e].totalBoxes= billData[e].totalBoxes;
          // billData[e].totalQuantity= billData[e].totalQuantity;
          // billData[e].totalAmount= billData[e].totalAmount;
          // data[e].details.maxReturnQty = this.state.maxReturnQty;

          finalSaleReturnData.push(data[e].details);
          ++e;
        }
        this.props.getSalesBill(billData.userId._id);

        this.setState({
          showReturnListData: finalSaleReturnData,
          // salesBillsUser:finalSaleReturnData,
          salesReturnData: finalSaleReturnData,
          MOQ: billData.MOQ ? billData.MOQ : "",
          totalBoxes: billData.totalBoxes,
          totalQuantity: billData.totalQuantity,
          totalAmount: billData.totalAmount,
          saleReturnBillID: billData._id,
          saleReturnBillDate: billData.date ? moment(billData.date).toDate() : moment().toDate(),

          invoiceNumber: data.invoiceId,
          customerName: billData.userId.name,
          customerId: billData.userId._id,
          saleReturnId: data.saleReturnId,
          isUpdated: true,
          // agentId: agentIdSalesOrder,
          // agent: agentSalesOrder,
          salesPersonId: data.salesPersonId,
          isUpdated: true,
          pd: uuid(),


        });
        this.formRefMy.current.setFieldsValue({
          customerName: billData.userId.name,
          billDate: billData.date ? moment(`${billData.date}`).toDate() : moment(new Date())

        });
        // // temp = [this.state.selectedData, ...this.state.myPurchaseData];
      }
      else {
        console.log("Hello World");

        let saleReturnDefectiveObj = this.props.history.location.state.saleReturnDefectedObj;
        if (this.props.history.location.state.type === 'Defective') {
          this.setState({ returnType: '2' })
        }
        if (saleReturnDefectiveObj) {
          this.setState({ remark: saleReturnDefectiveObj[0]?.remark })
          this.setState({ update: true });
          this.setState({ isUpdated: true });
        }
        // this.setState({ tempSaleObj: saleObj });
        let data = saleReturnDefectiveObj[1];
        let billData = saleReturnDefectiveObj[0];
        this.setState({
          salesBillsUser: saleReturnDefectiveObj,
          pd: uuid(),
        })
        let e = 0;
        let f = data.length;
        let finalSaleReturnData = [];
        while (e < f) {
          // data[e].details.boxes = data[e].quantity / data[e].details.MOQ;
          //data[e].details.saleReturnQty = data[e].quantity;
          data[e].details._id = data[e]._id
          data[e].details.quantity = data[e].quantity;
          data[e].details.created_at = billData.created_at
          data[e].details.brand = data[e].brand
          data[e].details.customerName = billData.userId.name
          data[e].details.saleReturnDefectedQnt = data[e].quantity


          data[e].details.saleReturnId = data[e].saleReturnId;
          data[e].details.amount = data[e].amount
          data[e].details.MOQ = data[e].MOQ
          // data[e].details.invoiceId = data[e].invoiceId;
          data[e].details.barcode = data[e].barcode;


          finalSaleReturnData.push(data[e].details);
          ++e;
        }
        this.setState({
          showReturnListData: finalSaleReturnData,
          // salesBillsUser:finalSaleReturnData,
          salesReturnData: finalSaleReturnData,
          totalQuantity: billData.totalQuantity,
          totalAmount: billData.totalAmount,
          saleReturnDefectedBillID: billData._id,
          saleReturnBillDate: billData.date ? moment(billData.date).toDate() : moment().toDate(),
          invoiceNumber: data[0]?.invoiceId,
          customerName: billData.userId.name,
          customerId: billData.userId._id,
          saleReturnId: data.saleReturnId,
          isUpdated: true,

          pd: uuid(),

        });


        this.formRefMy.current.setFieldsValue({
          customerName: billData.userId.name,
          // returnType : 2
          billDate: billData.date ? moment(billData.date).toDate() : moment().toDate()

        });
      }

      return this.props.history.replace({ state: null });
    }
  }

  deleteDefSale = (item) => {
    this.setState((prevState) => ({
      deletedDefSale: [...prevState.deletedDefSale, item]
    }));
  };


  redirectBackButtonFun = () => {
    this.props.history.push(`${this.state.lastPath || "/dagaImpex/saleReturnBill"}`, true)
  }
  render() {
    // console.log("this.state.returnType", this.state.returnType);
    const dateObject = moment(this.state?.saleReturnBillDate).format(dateFormat)
    // const defactedDateObject = this.state.returnType === "2" && moment(this.state.defactedBillDate).format(dateFormat)
    let brandOptions = this.props?.brandBysearch?.data?.map((brand) => {
      return (
        <Option name={brand.name} value={brand._id}>
          {brand.name}
        </Option>
      );
    });
    let customerOptions = this.props.customers.map((customer) => {
      return (
        <Option name={customer.name} value={customer._id}>
          {customer.name}
        </Option>
      );
    });
    if (this.state.quantity) {
      if (this.state.returnType == "1") {
        this.addSalesReturn()
      }
    }

    var disabled = false;
    if (this.state.disableField) {
      disabled = true;
    }

    let amount;
    let finalAmount;
    let NumberOfPackets;
    let NumberOfBoxes;

    if (this.state.returnBillType === "Normal") {

      amount = this.state.showReturnListData?.reduce((sum, item) => {
        const matchingOrder = this.props.salesReturnOrders.find(order => order.barcode === item.barcode);
        const purchasePrice = matchingOrder?.purchaseDetails?.purchasePrice || 0;

        const amount = Number(item.quantity || 0) * Number(purchasePrice);
        const discountedAmount = (amount * Number(item.discount || 0)) / 100;
        const finalAmount = amount - discountedAmount;

        return sum + finalAmount;
      }, 0);


      const discountAmount = (amount * Number(this.props.history.location.state?.saleReturnObj[0]?.saleBillData?.discount || 0)) / 100;
      const discountedAmount = (amount - discountAmount)
      const TaxAmount = (discountedAmount * Number(this.props.history.location.state?.saleReturnObj[0]?.saleBillData?.tax || 5)) / 100
      finalAmount = discountedAmount + TaxAmount

      NumberOfPackets = this.state?.salesReturnData?.reduce((total, item) => {
        let calculatedQuantity = 0;

        if (item.packagingType === "Loose" && item.packagingValue === "Collective") {
          calculatedQuantity = item.quantity / item.MOQ;
        } else if (item.packagingType === "Loose" && item.packagingValue === "Individual") {
          calculatedQuantity = item.quantity;
        }

        return total + calculatedQuantity;
      }, 0);

      NumberOfBoxes = this.state.salesReturnData?.reduce((total, item) => {
        let calculatedQuantity = 0;

        if (item.packagingType === "Box" && item.packagingValue === "Collective") {
          calculatedQuantity = item.quantity / item.MOQ;
        } else if (item.packagingType === "Box" && item.packagingValue === "Individual") {
          calculatedQuantity = item.quantity;
        }

        return total + calculatedQuantity;
      }, 0);
    }


    return (
      <div className="add-purchase ">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid">
          {this.state.saveDisableStatus ? (
            <Spin indicator={antIcon} />
          ) : (<>
            <Row className="title-row">
              <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                <div className="page-header">Update Sales Return</div>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                <Button
                  type="primary"
                  className="save-modal text-white"
                  onClick={() => this.redirectBackButtonFun()}
                >
                  <ArrowLeftOutlined /> Back
                </Button>
              </Col>
            </Row>
            <div className="card mt-4">
              {console.log("this.state.deletedDefSale", this.state.deletedDefSale)}
              <div className="card-body">
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form
                      ref={this.formRefMy}
                      name="add-return-sale"
                      className="add-return-sale"
                      onFinish={this.showDataOnList}
                      initialValues={{ remember: true }}
                    >
                      <Row gutter={[16, 0]}>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Tooltip title="Return Type">
                            <Select
                              disabled={disabled || this.state.update}
                              //disabled={disabled}
                              value={this.state.returnType}
                              style={{ width: "100%" }}
                              showSearch
                              placeholder="Select Return Type"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                              onChange={this.handleReturnChange}
                            // value={this.state.update ? "1" : undefined}

                            >
                              <Option value="1">Normal</Option>
                              <Option value="2">Defective</Option>
                            </Select>
                          </Tooltip>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Tooltip title="Select Customer">
                            <Form.Item
                              name="customerName"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Customer Name !",
                                },
                              ]}
                            >
                              <Select
                                disabled={true}
                                style={{ width: "100%" }}
                                showSearch
                                placeholder="Select Customer"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(optionB.children.toLowerCase())
                                }
                                onChange={this.handleChangeCustomer}
                                onClick={() => this.getData("customer")}
                                onInputKeyDown={() => this.getData("customer")}
                              >
                                {customerOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Tooltip title="Bill Date">
                            <Form.Item
                              name="billDate"

                            >
                              {console.log("this.state.saleReturnBillDate", dateObject)}

                              <DatePicker
                                disabled={this.state.returnType === "2" ? true : false}
                                onChange={this.onDateChange}
                                placeholder="Bill Date"
                                defaultValue={moment(dateObject, dateFormat)}

                                format={dateFormat}
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Tooltip title="Barcode">
                            <Form.Item
                              name="barcode"
                              rules={[
                                {
                                  // required: true,
                                  // message: "Please Enter Barcode Number !",
                                },
                              ]}
                            >

                              <BarcodeReader
                                onError={this.handleError}
                                onScan={this.state.isUpdated ? false : this.handleScan}
                              />
                              <Input
                                placeholder="Enter Barcode Number"
                                name="barcode"
                                value={this.state.barcode}
                                onChange={this.onBarcodeChange}
                                required
                                disabled={this.state.isUpdated}
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        {this.state.returnType == "2" &&
                          <>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                              <Tooltip title="Enter Brand">

                                <Form.Item
                                  name="brand"
                                  rules={[
                                    {
                                      // required: true,
                                      // message: "Please Select Brand Name !",
                                    },
                                  ]}
                                >
                                  <Select
                                    disabled={this.state.isUpdated}
                                    style={{ width: "100%" }}
                                    showSearch
                                    placeholder="Select Brand"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                      optionA.children
                                        .toLowerCase()
                                        .localeCompare(optionB.children.toLowerCase())
                                    }
                                    onChange={this.handleChangeBrand}
                                    onClick={() => this.debouncedGetData("brand")}
                                    onInputKeyDown={() => this.debouncedGetData("brand")}
                                  >
                                    {brandOptions}
                                  </Select>
                                </Form.Item>
                              </Tooltip>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                              <Tooltip title="Amount ">
                                <Form.Item>
                                  <Input
                                    min="0"
                                    step="1"
                                    type="number"
                                    placeholder="Price of one quantity"
                                    name="amount"
                                    value={this.state.amount}
                                    onChange={this.onChange}
                                    disabled={this.state.isUpdated}
                                  />
                                </Form.Item>
                              </Tooltip>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>

                              <Tooltip title="Quantity">
                                <Form.Item>
                                  <Input
                                    min="0"
                                    step="1"
                                    type="number"
                                    placeholder="No of Quantity"
                                    name="quantity"
                                    value={this.state.quantity}
                                    onChange={this.onChange}
                                    disabled={this.state.isUpdated}
                                  />
                                </Form.Item>
                              </Tooltip>
                            </Col>
                          </>
                        }

                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Tooltip title="Remark">
                            <Form.Item>
                              <Input
                                placeholder="Enter Remark"
                                name="remark"
                                type="text"
                                value={this.state.remark}
                                onChange={this.onChange}
                              // disabled={this.state.isUpdated}
                              // required
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          {
                            this.state.returnType === "2" && this.state.quantity <= 0 ? <>
                              {
                                this.state.quantity == 0 ? <>
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="close-modal"
                                    block
                                    onClick={() => message.warn("Can not return 0 quantity")}
                                  >
                                    Add Return
                                  </Button>

                                </> : <>
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="close-modal"
                                    block
                                    onClick={this.addDefectiveSalesReturn}
                                    disabled={this.state.update ? true : false}
                                  >
                                    Add Return
                                  </Button>
                                </>
                              }
                            </> : <>
                              {
                                this.state.quantity == 0 ? <>
                                </> : <>
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="close-modal"
                                    block
                                    onClick={this.addDefectiveSalesReturn}
                                  // disabled={this.state.update && this.state.returnType == ? true : this.state.brand && this.state.amount ? false : true}
                                  >
                                    Add Return
                                  </Button>
                                </>
                              }
                            </>
                          }
                        </Col>
                      </Row>
                    </Form>

                    {(() => {
                      if (this.state.returnType == "1") {
                        if (this.state.update) {
                          return (

                            <AddedListReturn
                              onAddQnty={this.onAddQnty}
                              onRemoveQnty={this.onRemoveQnty}
                              deleteSale={this.deleteSale}
                              key={this.state.pd}
                              // data={this.state.showReturnListData}

                              data={this.state.salesReturnData}
                              deletesaleReturn={this.deletesaleReturn}

                            />
                          );
                        } else {
                          return (


                            <AddedListReturn
                              onAddQnty={this.onAddQnty}
                              onRemoveQnty={this.onRemoveQnty}
                              deleteSale={this.deleteSale}
                              key={this.state.pd}
                              data={this.state.showReturnListData}

                              // data={this.state.salesReturnData}
                              deletesaleReturn={this.deletesaleReturn}

                            />
                          );
                        }

                      } else if (this.state.returnType == "2") {
                        return (
                          <AddedListReturNewnDefective
                            data={this.state.showReturnListData}
                            deleteDefSale={this.deleteDefSale}
                            onAddQnty={this.onAddQntyDefective}
                            onRemoveQnty={this.onRemoveQntyDefective}
                            deleteSale={this.deletesaleReturnDefected}
                            deletesaleReturnDefected={this.deleteDefectiveSale}
                            editDefectiveReturn={this.editDefectiveReturn}
                            key={this.state.pd}
                            title="update"
                          />
                          // <AddListReturnDefected
                          //   data={this.state.showReturnListData}
                          //   onAddQnty={this.onAddQnty}
                          //   onRemoveQnty={this.onRemoveQnty}
                          //   deleteSale={this.deleteSale}
                          //   deletesaleReturnDefected={this.deletesaleReturnDefected}
                          //   key={this.state.pd}
                          // />
                        );
                      }
                    })()}
                    <Row gutter={[8, 0]} className="mt-3">
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        {(() => {
                          if (this.state.salesReturnData.length === 0) {
                            return (
                              <Button
                                type="primary"
                                className="save-modal text-white"
                                disabled
                                block
                              >
                                Create Sales Return <StopOutlined />
                              </Button>
                            );
                          } else {
                            if (this.state.update) {
                              return (
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  className="save-modal"
                                  block
                                  onClick={this.createSalesReturn}
                                >
                                  Update Sales Return
                                </Button>
                              );
                            } else {
                              return (
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  className="save-modal"
                                  block
                                  onClick={this.createSalesReturn}
                                >
                                  Create Sales Return
                                </Button>
                              );
                            }
                          }
                        })()}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {
                  this.state.returnBillType === "Normal"
                    ?
                    <div className="mt-4">
                      <div className="ml-auto">
                        <div
                          className="grand-totall"
                          style={{ padding: "18px 17px 4px" }}
                        >
                          <div className="title-wrap">
                            <h4 className="cart-bottom-title section-bg-gary-cart">
                              Sales Return Total
                            </h4>
                          </div>
                          <h5>
                            Total Products{" "}
                            <span>
                              {this.state.showReturnListData?.length || ""}
                            </span>
                          </h5>
                          {/* <h5>
                            Total Packets
                            <span>
                              {NumberOfPackets || 0}
                            </span>
                          </h5>
                          <h5>
                            Total Boxes
                            <span>
                              {NumberOfBoxes || 0}
                            </span>
                          </h5>
                          <h5>
                            Total Sets
                            <span>
                              {
                                this.state.showReturnListData?.reduce((sum, item) => sum + (Number(item.quantity || 0) / Number(item.MOQ)), 0)
                                // ||
                                // this.state.salesReturnData?.reduce((sum, item) => sum + (Number(item.quantity || 0) / Number(item.MOQ)), 0)
                              }
                            </span>
                          </h5> */}
                          <h5>
                            Total Quantity{" "}
                            <span>
                              {this.state.showReturnListData?.reduce((sum, item) => sum + Number(item.quantity || 0), 0)
                              }
                            </span>
                          </h5>
                          <h5>
                            Total Amount
                            <span>
                              {amount || 0}
                            </span>
                          </h5>
                          <h5 className="grand-totall-title">
                            Net Amount{" "}
                            <span>
                              {
                                Math.round(finalAmount)
                              }
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                    :
                    <></>
                }
              </div>
            </div>
          </>)}
        </div>
      </div>
    );
  }
}
UpdateSalesReturn.propTypes = {
  getCustomers: PropTypes.func.isRequired,
  getSalesBill: PropTypes.func.isRequired,
  getSalesUsingBarcode: PropTypes.func.isRequired,
  saleReturn: PropTypes.func.isRequired,
  deleteSale: PropTypes.func.isRequired,
  deletesaleReturn: PropTypes.func.isRequired,
  deletesaleReturnDefected: PropTypes.func.isRequired,
  updateSaleReturnDefected: PropTypes.func.isRequired,
  getBrandBySearch: PropTypes.func.isRequired,
  updateSaleReturn: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  customers: state.customerData.customers,
  salesBillsUser: state.saleData.salesBillsUser,
  salesBillsBarcode: state.saleData.salesBillsBarcode,
  saleReturnDefected: state.saleData.salesBillsBarcode,
  progressBarStatus: state.progressBarReducer.showProgressBar,
  brands: state.brandsData.brands,
  brandBysearch: state.brandsData.brandBysearch,
  salesReturnOrders: state.saleData.salesReturnOrders
});

export default connect(mapStateToProps, {
  getCustomers,
  getSalesBill,
  getSalesUsingBarcode,
  saleReturn,
  updateSaleReturnDefected,
  deletesaleReturn,
  deletesaleReturnDefected,
  getBrandBySearch,
  updateSaleReturn
})(UpdateSalesReturn);