import React from "react";
import { Link, useHistory } from "react-router-dom";
import HeaderOne from "../../wrappers/header/HeaderOne";
import {
    ArrowLeftOutlined,
    EnvironmentOutlined,
    FacebookOutlined,
    InstagramOutlined,
    LinkedinOutlined,
    MailOutlined,
    PhoneOutlined,
    WhatsAppOutlined,
    YoutubeOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, Row } from "antd";
import FooterOne from "../../wrappers/footer/FooterOne";

const ContactUs = () => {
    const history = useHistory();

    const handleJoinDaga = () => {
        // Handle form submission
    };

    return (
        <div className="contact-us"
        >
            <HeaderOne />
            <div className="breadcrumb-area mb-0 shadow-sm"
            >
                <div className="p-0">
                    <div className="breadcrumb-content mx-2 d-flex align-items-center">
                        <Link to="">
                            <ArrowLeftOutlined className="text-white" />
                        </Link>
                        <div
                            className="text-center w-100 breadCrumb-Text"
                            style={{
                                fontWeight: 500,
                            }}
                        >
                            Contact Us
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-4 pt-4 px-3"
                style={{
                    backgroundColor: '#F5F5F5'
                }}
            >
                <div className="joinDaga">
                    <div className="text-center mb-4">
                        <h3 style={{ marginBottom: "1rem" }}>Join the Daga Impex</h3>
                    </div>
                    <Row gutter={24} justify="center">
                        <Col xs={24} sm={24} md={12} lg={8}>
                            <Form layout="vertical" onFinish={handleJoinDaga}>
                                <Form.Item label="Mail Id">
                                    <Input placeholder="Enter Your Mail Id" />
                                </Form.Item>
                                <Form.Item label="Mobile Number">
                                    <Input placeholder="Enter Your Contact Number" />
                                </Form.Item>
                                <Form.Item className="mb-0">
                                    <Button
                                        className="text-white"
                                        style={{
                                            marginBottom: "16px",
                                            backgroundColor: "#0060aa",
                                            width: "100%", // Ensure button spans the full width
                                        }}
                                        htmlType="submit"
                                    >
                                        Join
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>

                </div>

                <div className="addSocial mt-5">
                    <h3 className="socialHeading text-center mb-4">Follow Us</h3>
                    <div className="socialIcons d-flex justify-content-center flex-wrap">
                        {[
                            { icon: <InstagramOutlined />, color: "#ff0c37" },
                            { icon: <YoutubeOutlined />, color: "red" },
                            { icon: <LinkedinOutlined />, color: "#0072b1" },
                            { icon: <WhatsAppOutlined />, color: "green" },
                            { icon: <FacebookOutlined />, color: "#3b5998" },
                        ].map((item, index) => (
                            <div
                                key={index}
                                className="icon-wrapper mx-3"
                                style={{
                                    fontSize: "30px",
                                    color: item.color,
                                    padding: "10px",
                                    borderRadius: "50%",
                                    backgroundColor: "#f8f8f8",
                                }}
                            >
                                {item.icon}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="contactDaga mt-5">
                    <div className="text-center mt-5 mb-4">
                        <h3>Contact Details</h3>
                    </div>
                    {[
                        {
                            icon: <EnvironmentOutlined style={{ fontSize: "30px" }} />,
                            text: "214, Tilak Path, Rajwada, Indore, Madhya Pradesh 452007",
                        },
                        {
                            icon: <MailOutlined style={{ fontSize: "30px" }} />,
                            text: "dagaimpex12@gmail.com",
                        },
                        {
                            icon: <PhoneOutlined style={{ fontSize: "30px" }} />,
                            text: "09826370000, 08908870000",
                        },
                    ].map((item, index) => (
                        <Row gutter={24} align="middle" justify="center" className="mb-3" key={index}>
                            <Col xs={4} sm={3} md={2} className="text-center">
                                {item.icon}
                            </Col>
                            <Col xs={20} sm={21} md={10}>
                                <div>{item.text}</div>
                            </Col>
                        </Row>
                    ))}
                </div>

                <div className="map my-5">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3683.1638232127214!2d75.8577!3d22.7196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd57ec4cf9af%3A0x8c6b8d3f9d8ef4aa!2sDaga%20Impex%2C%20214%20Tilak%20Path%2C%20Rajwada%2C%20Indore%2C%20Madhya%20Pradesh%20452007!5e0!3m2!1sen!2sus!4v1673523728471!5m2!1sen!2sus"
                        width="100%"
                        height="400"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>

            <div className="mt-4">
                <FooterOne />
            </div>
        </div>
    );
};

export default ContactUs;
