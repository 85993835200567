import React, { useEffect, useState } from "react";
import { Row, Col, Input, Button, Card, Tooltip, Modal, message } from "antd";
import { SearchOutlined, CloseOutlined, ScanOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getdesignAndBrandComboByBarcode } from "../../../redux/actions/purchaseAction";
import BarcodeReader from "react-barcode-reader";
import MainBarcodeScanner3 from "./BarcodeScanner3/MainBarcodeScanner3";
import barcodeSound from "../../../assets/audio/Barcode-scanner-beep-sound.mp3";
import gifLoader from "../../../assets/images/giphy.gif";
import DesignandBrandWiseSearchMobile from "./DesignandBrandWiseSearchMobile";
const InventorySalesApp = () => {
  const [serachValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isBarcodeScanner, setIsBarcodeScanner] = useState(false);
  const [audio, setAudio] = useState("");
  const designAndBrandCombo = useSelector(
    (state) => state.purchaseData.brandanddesigncombobyBarcode || []
  );
  const dispatch = useDispatch();
  const onChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleSearchButtonClick = async () => {
    if (serachValue.length == 9) {
      setLoading(true);
      await dispatch(
        getdesignAndBrandComboByBarcode({ barcode: serachValue })
      ).finally(() => setLoading(false));
    } else {
      message.warn("Wrong Barcode!");
    }
  };
  const handleReestButtonClick = () => {
    setSearchValue("");
    dispatch(getdesignAndBrandComboByBarcode("reset"));
  };

  const handleScan = async (data) => {
    if (data.length === 9) {
      setSearchValue(data);
      setLoading(true);
      await dispatch(
        getdesignAndBrandComboByBarcode({ barcode: data })
      ).finally(() => setLoading(false));
    } else {
      message.warn("Wrong Barcode!");
    }
  };

  // Play Sound
  const playSound = () => {
    if (audio) {
      audio.play();
      setTimeout(() => {
        audio.pause();
        audio.currentTime = 0;
      }, 1000); // play for 1 second
    }
  };

  const onNewScanResult = async (decodedText, decodedResult) => {
    if (decodedText && decodedText.length === 9) {
      playSound();
      setSearchValue(decodedText);
      setLoading(true);
      await dispatch(
        getdesignAndBrandComboByBarcode({ barcode: decodedText })
      ).finally(() => {
        setLoading(false);
        setIsBarcodeScanner(false);
      });
    } else {
      playSound();
      message.error("Wrong Barcode!");
    }
  };

  const handleBarcodeModalStatus = () => {
    setIsBarcodeScanner(false);
  };

  useEffect(() => {
    const audioElement = new Audio(barcodeSound);
    setAudio(audioElement);
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h4
                className="page-header"
                style={{ fontWeight: 600, fontSize: "22px", margin: "0px" }}
              >
                <span style={{ color: "#52c41a" }}> Inventory</span>
              </h4>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} align="end">
              <Button
                onClick={() => setIsBarcodeScanner(true)}
                size="large"
                style={{ border: "0", paddingRight: "0px" }}
              >
                <ScanOutlined style={{ fontSize: "35px", marginTop: "0px" }} />
              </Button>
              <Modal
                open={isBarcodeScanner}
                width={500}
                title="Scan Barcode"
                onCancel={handleBarcodeModalStatus}
                style={{ top: 20 }}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ hidden: true }}
                destroyOnClose={true}
              >
                <div className="barcode-scanner">
                  <MainBarcodeScanner3
                    fps={10}
                    qrbox={250}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
                  />
                </div>
              </Modal>
            </Col>
          </Row>
          <Row gutter={16} className="mt-4">
            <Col xs={18} sm={18} md={18} lg={18} xl={18}>
              <BarcodeReader
                //   onError={handleError}
                onScan={handleScan}
              />
              <Input
                placeholder={"Enter Barcode"}
                value={serachValue}
                onPressEnter={handleSearchButtonClick}
                onChange={onChange}
                maxLength={9}
              />
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
              <Button
                type="primary"
                className="serach-button"
                icon={<SearchOutlined />}
                onClick={handleSearchButtonClick}
              />
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2} className="ml-2">
              <Button
                type="primary"
                className="serach-button"
                icon={<CloseOutlined />}
                onClick={handleReestButtonClick}
              />
            </Col>
          </Row>
          <Row gutter={16} className="mt-4">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} align="center">
              <DesignandBrandWiseSearchMobile />
            </Col>
          </Row>
          {loading ? (
            <>
              {" "}
              <div className="d-flex justify-content-center align-items-center">
                <img src={gifLoader} width={"300px"} height={"300px"} />
              </div>{" "}
            </>
          ) : (
            <>
              <Row gutter={16} className="mt-4">
                {designAndBrandCombo?.map((item) => (
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={6}
                    className="mb-4"
                    key={item.barcode}
                  >
                    <Card className="sales-card shadow">
                      <div className="card-content">
                        <div className="left-section">
                          <img
                            src={item.images[0]}
                            alt="Product"
                            className="product-image"
                          />
                          <div className="barcode">{item.barcode}</div>
                        </div>
                        <div className="right-section">
                          <div className="sub-left">
                            <Tooltip title={`D No.: ${item.designNumber}`}>
                              <div className="info-row">
                                <span>D No.</span>
                                <span>{item.designNumber}</span>
                              </div>
                            </Tooltip>
                            <Tooltip title={`Size: ${item.size}`}>
                              <div className="info-row">
                                <span>Size</span>
                                <span>{item.size}</span>
                              </div>
                            </Tooltip>
                            <Tooltip title={`Color: ${item.color}`}>
                              <div className="info-row">
                                <span>Color</span>
                                <span>{item.color}</span>
                              </div>
                            </Tooltip>
                            <Tooltip title={`MOQ: ${item.MOQ}`}>
                              <div className="info-row">
                                <span>Boxes</span>
                                <span>{item.quantity / item.MOQ}</span>
                              </div>
                            </Tooltip>
                            <Tooltip title={`MOQ: ${item.MOQ}`}>
                              <div
                                className="info-row"
                                style={{ color: "grey" }}
                              >
                                <span>MOQ</span>
                                <span>{item.MOQ}</span>
                              </div>
                            </Tooltip>
                          </div>
                          <div className="sub-right">
                          <div>
                              <span className="info-right">
                                WSP : {item.wsp}
                              </span>
                            </div>
                            <div>
                              <span className="info-right">
                                Stock : {item.quantity}
                              </span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default InventorySalesApp;
