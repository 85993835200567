import React, { Component } from "react";
import {
  Table,
  Popconfirm,
  Tooltip,
  Pagination,
  Button,
  Space,
  Input,
  Row,
  Col,
  Select,
} from "antd";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import Search from "../../../../../util/Search";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getPurchaseBills,
  getPurchaseBillsBydate,
  getPurchasesById,
} from "../../../../../redux/actions/purchaseAction";
import Highlighter from "react-highlight-words";
import moment from "moment";
import ShowBillPurchases from "./ShowBillPurchases";
import DateWiseSearch from "../../../../../util/DateWiseSearch";
import TopBarProgress from "react-topbar-progress-indicator";
import { withRouter } from "react-router-dom";
const { Column } = Table;
const { Option } = Select;
const searchOptions = ["Barcode", "PurchaseId", "DealerName", "Alias"];
class PurchaseBillList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
      searchValue: "",
      searchAlias: "",
      totalCount: 0,
      checkDate: false,
    };
  }
  componentDidMount() {
    if (this.props.history.location.state) {
      let localStartDate = localStorage.getItem("startDate");
      let localEnddate = localStorage.getItem("endDate");
      if (localStartDate && localEnddate) {
        let today = moment(new Date()).format("YYYY-MM-DD");
        if (today == localEnddate) {
          let reportObj = {
            startDate: localStartDate,
            endDate: localEnddate + " " + moment().format("HH:mm:ss"),
          };
          this.props.getPurchaseBillsBydate(reportObj);
          localStorage.removeItem("startDate");
          localStorage.removeItem("endDate");
        } else {
          const dateMoment = moment(localEnddate, "YYYY-MM-DD");
          const endTime = dateMoment.endOf("day").format("HH:mm:ss");
          const endDateWithEndTime = `${localEnddate} ${endTime}`;
          let reportObj = {
            startDate: localStartDate,
            endDate: endDateWithEndTime,
          };
          this.props.getPurchaseBillsBydate(reportObj);
          localStorage.removeItem("startDate");
          localStorage.removeItem("endDate");
        }
      } else {
        let startDate = moment(new Date()).format("YYYY-MM-DD");
        let endDate = moment(new Date()).format("YYYY-MM-DD");
        let reportObj = {
          startDate: startDate,
          endDate: endDate + " " + moment().format("HH:mm:ss"),
        };
        this.props.getPurchaseBillsBydate(reportObj);
      }
    } else {
      localStorage.setItem(
        "startDate",
        moment(new Date()).format("YYYY-MM-DD")
      );
      localStorage.setItem("endDate", moment(new Date()).format("YYYY-MM-DD"));
      this.setState({
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
      });
      let startDate = moment(new Date()).format("YYYY-MM-DD");
      let endDate = moment(new Date()).format("YYYY-MM-DD");
      let reportObj = {
        startDate: startDate,
        endDate: endDate + " " + moment().format("HH:mm:ss"),
      };
      this.props.getPurchaseBillsBydate(reportObj);
    }

    // =============================
    // let startDate = moment(new Date()).format("YYYY-MM-DD")
    // let endDate = moment(new Date()).format("YYYY-MM-DD")
    // let reportObj = {
    //   startDate: startDate,
    //   endDate: endDate + " " + moment().format("HH:mm:ss"),
    // };
    // // this.props.getPurchaseBills(1);
    // this.props.getPurchaseBillsBydate(reportObj)
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.searchData.data !== this.props.searchData.data) {
  //     this.setState({ totalCount: this.props.purchasesBillsBydate.count })
  //   }
  // }

  handleDropdownChange = (e) => {};

  updatePurchaseBill = async (record) => {
    await this.props.getPurchasesById(record.purchaseId);
    const dataToSend = {
      data: record.purchaseId,
    };
    const status = this.props?.history?.location?.pathname?.includes("New");
    if (status) {
      this.props.history.push({
        pathname: "/dagaImpex/upDatePurchase-New",
        state: record,
      });
    } else {
      this.props.history.push({
        pathname: "/dagaImpex/upDatePurchase",
        state: record,
      });
    }
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };
  onChange = (event) => {
    this.setState({ searchValue: event });
  };
  cancelState = () => {
    this.setState({ searchValue: "" });
  };
  updateDatestatus = () => {
    this.setState({ checkDate: true });
  };
  handleSearchButtonClick = (value) => {
    let obj = {
      searchValue: this.state.searchValue,
    };
    this.props.purchaseBySearchValue(obj);

    // this.props.userByNameOrUsername(obj)
  };

  listData = () => {
    const { purchaseBills, purchasesBillsBydate, searchData } = this.props;
    const value =
      purchasesBillsBydate && purchasesBillsBydate.length > 0
        ? purchasesBillsBydate[0]
        : [];

    // if (value.message == "Empty") {
    //   return ([])
    // } else if (this.state.searchValue) {
    //   console.log("check mohit inside if conditionnnn")
    //   console.log("check mohit inside if conditionnnn search data", searchData.data )
    //   return (searchData.data)
    // } else {
    //   return (purchasesBillsBydate.data)
    // }

    if (this.state.searchValue) {
      const sortedArray = searchData?.details?.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      return sortedArray;
    } else {
      return purchasesBillsBydate.details;
    }
  };

  render() {
    const { searchValue } = this.state;
    const { purchaseBills, searchData, purchasesBillsBydate } = this.props;
    const search = searchData || [];
    const Id = localStorage.getItem("roleName");
    return (
      <div>
        {this.props.progressBarStatus && <TopBarProgress />}
        <Row className="title-row">
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="page-header">Purchase Bill</div>
          </Col>
        </Row>
        <Row className="mt-4">
          {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}> */}
          <Search
            title="Purchase Bill"
            placeholder="Dealer Name, PurchaseId or Barcode"
            searchopt={searchOptions}
            changeFunction={this.onChange}
            cancelState={this.cancelState}
            location={this.props.location}
          />
          {/* </Col> */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <DateWiseSearch
              keyId={"Purchase Bill"}
              updateDatestatus={this.updateDatestatus}
              historyProps={this.props}
            />
          </Col>
        </Row>
        <div className="card  final-purchases-list">
          <div className="card-body">
            <Table
              dataSource={this.listData()}
              // dataSource={ searchValue ? search : purchasesBillsBydate.data ? purchasesBillsBydate.data : purchaseBills.data}
              //dataSource={this.props.purchaseBills && this.props.purchaseBills.data ? this.props.purchaseBills.data: []}
              bordered="true"
              size="middle"
              pagination={{
                pageSize: 100,
                total: searchData
                  ? searchData?.details?.length
                  : purchasesBillsBydate?.details?.length,
              }}
              scroll={{ y: 440 }}
              summary={() => (
                <Table.Summary fixed={"bottom"}>
                  <Table.Summary.Row>
                    <Table.Summary.Cell
                      className="grand-Total-summary"
                      index={1}
                      colSpan={2}
                    >
                      Grand Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1}>
                      {searchData && searchData.details
                        ? searchData.details.length
                        : purchasesBillsBydate && purchasesBillsBydate.details
                        ? purchasesBillsBydate.details.length
                        : ""}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={2}>
                      {}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1}>
                      {" "}
                      {searchData && searchData.sum && searchData.sum.totalBoxes
                        ? searchData.sum.totalBoxes
                        : purchasesBillsBydate &&
                          purchasesBillsBydate.sum &&
                          purchasesBillsBydate.sum.totalBoxes
                        ? purchasesBillsBydate.sum.totalBoxes
                        : ""}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1}>
                      {" "}
                      {searchData && searchData.sum && searchData.sum.quantity
                        ? searchData.sum.quantity
                        : purchasesBillsBydate &&
                          purchasesBillsBydate.sum &&
                          purchasesBillsBydate.sum.quantity
                        ? purchasesBillsBydate.sum.quantity
                        : ""}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1}>
                      {searchData &&
                      searchData.sum &&
                      searchData.sum.totalAmount
                        ? Number(searchData.sum.totalAmount).toFixed()
                        : purchasesBillsBydate &&
                          purchasesBillsBydate.sum &&
                          purchasesBillsBydate.sum.totalAmount
                        ? Number(purchasesBillsBydate.sum.totalAmount).toFixed()
                        : ""}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1}>
                      {}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1}>
                      {}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1}>
                      {searchData && searchData.sum && searchData.sum.netAmount
                        ? Number(searchData.sum.netAmount).toFixed()
                        : purchasesBillsBydate &&
                          purchasesBillsBydate.sum &&
                          purchasesBillsBydate.sum.netAmount
                        ? Number(purchasesBillsBydate.sum.netAmount).toFixed()
                        : ""}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Purchase Id"
                dataIndex="purchaseId"
                key="purchaseId"
                width={150}
                {...this.getColumnSearchProps("purchaseId")}
              />
              <Column
                title="Dealer Name"
                dataIndex="dealerName"
                key="dealerName"
                width={150}
                {...this.getColumnSearchProps("dealerName")}
              />
              <Column
                title="Location"
                dataIndex="location"
                key="location"
                width={150}
              />
              <Column
                title="Bill No"
                dataIndex="billNo"
                key="billNo"
                width={120}
                {...this.getColumnSearchProps("billNo")}
              />
              <Column
                title="C_Date"
                dataIndex="created_at"
                key="created_at"
                width={130}
                render={(value, item, index) =>
                  moment(item.created_at).format("YYYY-MM-DD")
                }
              />
              <Column
                title="B_Date"
                dataIndex="date"
                key="date"
                width={130}
                render={(value, item, index) =>
                  moment(item.date).format("YYYY-MM-DD")
                }
              />
              <Column
                title="U_Date"
                dataIndex="updatedAt"
                key="updatedAt"
                width={130}
                render={(value, item, index) =>
                  item?.updatedAt
                    ? moment(item?.updatedAt).format("YYYY-MM-DD")
                    : ""
                }
              />
              <Column
                title="Qnty"
                dataIndex="quantity"
                key="quantity"
                width={90}
              />
              <Column
                title="Total Amt"
                dataIndex="totalAmount"
                key="totalAmount"
                render={(value, item, index) =>
                  parseInt(item.totalAmount).toFixed()
                }
                width={120}
              />
              <Column
                title="Disc"
                dataIndex="discount"
                key="discount"
                width={90}
              />
              <Column title="Tax" dataIndex="tax" key="tax" width={90} />
              <Column
                title="Net Amt"
                dataIndex="netAmount"
                key="netAmount"
                render={(value, item, index) => item.netAmount.toFixed()}
                width={120}
              />
              <Column
                title="Action"
                key="action"
                fixed="right"
                width={120}
                render={(text, record) => {
                  if (
                    Id === "SALES_ADMIN" ||
                    Id === "ACCOUNTS" ||
                    Id === "DME"
                  ) {
                    return (
                      <Row gutter={[8, 0]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <a>
                            <ShowBillPurchases
                              purchaseId={record.purchaseId}
                              data={record}
                            />
                          </a>
                        </Col>
                      </Row>
                    );
                  } else {
                    return (
                      <Row gutter={[8, 0]}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <a>
                            <ShowBillPurchases
                              purchaseId={record.purchaseId}
                              data={record}
                            />
                          </a>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Popconfirm
                            title="Are you sure to Edit this bill?"
                            onConfirm={() => this.updatePurchaseBill(record)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <div className="btn">
                              <EditOutlined />
                            </div>
                          </Popconfirm>
                        </Col>
                      </Row>
                    );
                  }
                }}
              />
            </Table>{" "}
            {/* <div className=" mt-4">
              <div className="col-md-12 ml-auto">
                <div className="grand-totall">
                  <div className="title-wrap">
                    <h4 className="cart-bottom-title section-bg-gary-cart">
                      Purchase Bill Total
                    </h4>
                  </div>
                  <h5>
                    Total Bills{" "}
                    <span>
                      {searchData && searchData?.details ? searchData?.details?.length : purchasesBillsBydate && purchasesBillsBydate?.details ? purchasesBillsBydate?.details?.length : ""}
                    </span>
                  </h5>
                  <h5>
                    Total Quantity{" "}
                    <span>
                      {searchData && searchData?.sum ? searchData?.sum?.quantity : purchasesBillsBydate && purchasesBillsBydate?.sum?.quantity ?Number(purchasesBillsBydate?.sum?.quantity).toFixed() : ""}
                    </span>
                  </h5>
                  <h5>
                    Total Amount{" "}
                    <span>
                      {searchData && searchData?.sum ? searchData?.sum?.totalAmount : purchasesBillsBydate && purchasesBillsBydate?.sum?.totalAmount ? purchasesBillsBydate?.sum?.totalAmount : ""}
                    </span>
                  </h5>
                  <h4 className="grand-totall-title">
                    Net Amount{" "}
                    <span>
                      {searchData && searchData?.sum ? searchData?.sum?.netAmount : purchasesBillsBydate && purchasesBillsBydate?.sum?.netAmount ?Number(purchasesBillsBydate?.sum?.netAmount).toFixed() : ""}
                    </span>
                  </h4>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

PurchaseBillList.propTypes = {
  getPurchaseBills: PropTypes.func.isRequired,
  getPurchaseBillsBydate: PropTypes.func.isRequired,
  getPurchasesById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  purchaseBills: state.purchaseData.purchaseBills,
  searchData: state.purchaseData.purchaseSearchData,
  purchasesBillsBydate: state.purchaseData.purchasesBillsBydate,
  progressBarStatus: state.progressBarReducer.showProgressBar,
});

export default withRouter(
  connect(mapStateToProps, {
    getPurchaseBills,
    getPurchaseBillsBydate,
    getPurchasesById,
  })(PurchaseBillList)
);
