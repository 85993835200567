import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  message,
  Upload,
  Spin,
  Form,
  Input,
  DatePicker,
  Tooltip,
  Select,
} from "antd";
import { CloudUploadOutlined, UploadOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  addSingleGlobalEcomImage,
  deleteSingleGlobalImage,
  saveEcomBanner,
  getBannerList,
} from "../../../redux/actions/purchaseAction";

import { uploadLr } from "../../../redux/actions/saleAction";
import moment from "moment";
const { Dragger } = Upload;
const dateFormat = "YYYY-MM-DD";
const { Option } = Select;
const billTypeLR = {
  typeSales: "storeSales",
  typeEcom: "ecomSales",
};

const UpdateLR = ({ invoiceData, showDataOnList }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [finalloading, setfinalLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);
  const [bannerImage, setbannerImage] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [lrDate, setlrDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [invoiceId, setinvoiceId] = useState([]);
  const [salesBillId, setsalesBillId] = useState([]);

  const [lrNo, setlrNo] = useState("");
  const [biltyNo, setbiltyNo] = useState("");

  const dispatch = useDispatch();
  const showModal = () => {
    setVisible(true);
  };
  const closeModal = () => {
    setVisible(false);
    setLoading(false);
    setImageUrl(false);
    setbannerImage([]);
    setErrorMsg(null);
  };

  const uploadButton = (
    <Button icon={<UploadOutlined />}>Click to Upload</Button>
  );

  const handleChange = async ({ fileList }) => {
    const file = fileList[0].originFileObj;
    const reader = new FileReader();
    reader.onload = () => {
      const imageDataUrl = reader.result;
      const image = new Image();
      image.src = imageDataUrl;
      image.onload = async () => {
        setbannerImage(fileList);
        setLoading(true);
        let response = await dispatch(
          addSingleGlobalEcomImage(fileList[0].originFileObj)
        );
        if (response && response.status == 200) {
          setImageUrl(response.data.filePath);
          setLoading(false);
        } else {
          setLoading(false);
        }
      };
    };
    reader.readAsDataURL(file);
  };

  const handleRemove = async () => {
    setLoading(true);
    let response = await dispatch(
      deleteSingleGlobalImage({ bannerImages: imageUrl })
    );
    setbannerImage([]);
    setImageUrl("");
    setErrorMsg(null);
    if (response && response.status == 200) {
      message.success(response.data);
    }
    setLoading(false);
  };

  const props = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    accept: ".png, .jpg",
    listType: "picture-card",
    headers: {
      authorization: "authorization-text",
    },
    showUploadList: {
      showPreviewIcon: false,
    },
    onChange(info) {
      setLoading(true);
      if (
        info &&
        info.file &&
        info.file.status !== "uploading" &&
        info.fileList.length !== 0
      ) {
        handleChange(info);
      }
      if (info.fileList.length == 0) {
        handleRemove();
      }
    },
  };

  const handleSubmit = async () => {
    if (imageUrl) {
      setLoading(true);
      let requestObj = {
        lrNumber: lrNo,
        lrDate: lrDate,
        builtyNumber: biltyNo,
        invoiceId: invoiceId,
        pdfUrl: imageUrl,
        builType: billTypeLR.typeSales,
        salesBillsId: salesBillId,
      };
      await dispatch(uploadLr(requestObj))
        .then((respo) => {
          if (respo.status == 200) {
            message.success(respo?.data?.message);
            showDataOnList();
          }
        })
        .finally(() => {
          setLoading(false);
          setImageUrl("");
          setbannerImage([]);
          setVisible(false);
        });
    } else {
      message.warn("Please Upload Image!");
    }
  };

  const onChange = (e) => {
    if (e.target.name === "lrNo") {
      setlrNo(e.target.value);
    } else if (e.target.name === "biltyNo") {
      setbiltyNo(e.target.value);
    }
  };

  const onlrDateChange = (date, dateString) => {
    setlrDate(dateString);
  };

  const onInvoiceChange = (key, value) => {
    let invoiceId = value?.map((obj) => obj.value);
    let saleBillId = value?.map((obj) => obj.name);
    setinvoiceId(invoiceId);
    setsalesBillId(saleBillId);
  };

  useEffect(() => {}, []);

  return (
    <>
      <Button
        className="btn-admin btn-subCategory text-white"
        type="primary"
        onClick={() => showModal()}
        disabled={true}
      >
        <CloudUploadOutlined /> Update LR
      </Button>
      <Row>
        <Col span={24}>
          <div className="add-button">
            <Modal
              visible={visible}
              onCancel={closeModal}
              title="Update LR & Bilty No"
              style={{ top: 20 }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <>
                <Form
                  name="add-Style"
                  className="add-Style"
                  layout="vertical"
                  onFinish={handleSubmit}
                  initialValues={{
                    remember: true,
                  }}
                >
                  <Form.Item
                    name="lrNo"
                    label="LR No"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter LR No!",
                      },
                    ]}
                  >
                    {/* <Tooltip title="LR No"> */}
                    <Input
                      autoFocus
                      placeholder="LR No"
                      name="lrNo"
                      value={lrNo}
                      type="number"
                      onChange={onChange}
                    />
                    {/* </Tooltip> */}
                  </Form.Item>
                  <Form.Item
                    name="biltyNo"
                    label="Bilty No"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Bilty No !",
                      },
                    ]}
                  >
                    {/* <Tooltip title="Bilty No"> */}
                    <Input
                      autoFocus
                      placeholder="Bilty No"
                      name="biltyNo"
                      type="number"
                      value={biltyNo}
                      onChange={onChange}
                    />
                    {/* </Tooltip> */}
                  </Form.Item>
                  <Form.Item name="lrDate" label="LR Date">
                    {console.log("")}
                    <Tooltip title="LR Date">
                      <DatePicker
                        style={{ width: "100%" }}
                        onChange={onlrDateChange}
                        placeholder="Select LR Date"
                        value={moment(lrDate, dateFormat)}
                        format={dateFormat}
                        showClose={false}
                      />
                    </Tooltip>
                  </Form.Item>
                  {console.log("check mohit hello invoice id", invoiceId)}
                  <Form.Item
                    name="invoiceId"
                    label="Invoice Ids"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Invoice Id!",
                      },
                    ]}
                  >
                    <Select
                      name="invoiceId"
                      placeholder="Select a Invoice Id"
                      id="Invoice Id"
                      onChange={onInvoiceChange}
                      value={invoiceId}
                      mode="multiple"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      showSearch
                    >
                      {invoiceData?.map((invoceData) => (
                        <Option
                          value={invoceData.invoiceId}
                          name={invoceData._id}
                        >
                          {invoceData.invoiceId}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="uploadLr"
                    label="Upload LR"
                    //   rules={[
                    //     {
                    //       required: true,
                    //       message: "Please Upload LR!",
                    //     },
                    //   ]}
                  >
                    <p style={{ color: "red" }}>{errorMsg}</p>
                    <Upload {...props}>
                      {bannerImage.length == 0 && !loading
                        ? uploadButton
                        : null}
                    </Upload>
                  </Form.Item>

                  <Row gutter={12} className="mt-2">
                    <Col span={24} align="center">
                      {loading ? (
                        <Spin />
                      ) : (
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="ok-modal"
                          loading={finalloading}
                          // onClick={() => handleSubmit()}
                        >
                          Save
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </>
            </Modal>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UpdateLR;
