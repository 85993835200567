import React, { useState, useRef } from "react";
import { Modal, Button, Upload, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { CanvasPreview } from "./CanvasPreview";
import { UseDebounceEffect } from "./UseDebounceEffect";
import { useDispatch } from "react-redux";
import { addSingleGlobalEcomImage, updateProductImage } from "../../../../redux/actions/purchaseAction";
import { getBrandImages } from "../../../../redux/actions/brandAction";

export default function UpdateImageUpload({ data, oldImage }) {
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);

    // Single image state and related values.
    const [image, setImage] = useState(null);
    const [scale, setScale] = useState(1);
    const [crop, setCrop] = useState({ unit: "px", width: 275, height: 325, x: 50, y: 50 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const MAX_IMAGE_SIZE = 400;
    const FIXED_CROP = { width: 275, height: 325 };

    const onSelectFile = ({ file }) => {
        if (!file || !file.type.startsWith("image/")) {
            message.error("Invalid file format. Please upload an image.");
            return;
        }

        const reader = new FileReader();
        reader.onloadend = () => {
            const imageObj = new Image();
            imageObj.src = reader.result?.toString() || "";
            imageObj.onload = () => {
                let newScale = 1;
                let newWidth = imageObj.width;
                let newHeight = imageObj.height;

                // Resize the image if larger than MAX_IMAGE_SIZE.
                if (imageObj.width > MAX_IMAGE_SIZE || imageObj.height > MAX_IMAGE_SIZE) {
                    const scaleFactor = Math.max(imageObj.width / MAX_IMAGE_SIZE, imageObj.height / MAX_IMAGE_SIZE);
                    newWidth = imageObj.width / scaleFactor;
                    newHeight = imageObj.height / scaleFactor;
                }

                // Increase scale if the image is smaller than the crop dimensions.
                if (newWidth < FIXED_CROP.width || newHeight < FIXED_CROP.height) {
                    const scaleX = FIXED_CROP.width / newWidth;
                    const scaleY = FIXED_CROP.height / newHeight;
                    newScale = Math.max(scaleX, scaleY);
                }

                setScale(newScale);
                setImage(reader.result?.toString() || "");
                setCrop({ unit: "px", width: FIXED_CROP.width, height: FIXED_CROP.height, x: 50, y: 50 });
            };

            imageObj.onerror = () => {
                message.error("Error loading image.");
            };
        };

        reader.onerror = () => {
            message.error("Failed to read file.");
        };

        reader.readAsDataURL(file);
    };

    // Use a debounced effect to update the preview canvas when crop or scale changes.
    UseDebounceEffect(() => {
        if (imgRef.current && previewCanvasRef.current && completedCrop) {
            try {
                CanvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale);
            } catch (error) {
                console.error("Error in canvasPreview:", error);
            }
        }
    }, 100, [scale, completedCrop]);

    const handleModalCancel = () => {
        setIsModalVisible(false);
        // Reset all state values
        setImage(null);
        setCrop({ unit: "px", width: FIXED_CROP.width, height: FIXED_CROP.height, x: 50, y: 50 });
        setCompletedCrop(null);
        setScale(1);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    // Helper function to convert a blob URL to a File.
    async function blobUrlToFile(blobUrl, fileName, mimeType) {
        const response = await fetch(blobUrl);
        const blob = await response.blob();
        return new File([blob], fileName, { type: mimeType || blob.type });
    }

    const onDownloadCropClick = async () => {
        setLoading(true);

        if (!image || !completedCrop) {
            message.error("Please upload and crop an image.");
            setLoading(false);
            return;
        }

        const imageElement = imgRef.current;
        const previewCanvas = previewCanvasRef.current;

        if (!imageElement || !previewCanvas) {
            message.error("Something went wrong with the preview.");
            setLoading(false);
            return;
        }

        const tempCanvas = document.createElement("canvas");
        const ctx = tempCanvas.getContext("2d");
        if (!ctx) {
            message.error("Canvas is not supported.");
            setLoading(false);
            return;
        }

        tempCanvas.width = FIXED_CROP.width;
        tempCanvas.height = FIXED_CROP.height;

        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            FIXED_CROP.width,
            FIXED_CROP.height
        );

        const blob = await new Promise((resolve) => tempCanvas.toBlob(resolve, "image/png"));

        if (blob) {
            const blobUrl = URL.createObjectURL(blob);
            const croppedFile = await blobUrlToFile(blobUrl, "cropped-image.jpg", "image/jpeg");
            if (croppedFile) {
                dispatch(addSingleGlobalEcomImage(croppedFile))
                    .then((response) => {
                        const obj = {
                            imageName: oldImage,
                            newImageName: response.data?.filePath,
                            barcode: data?.barcode,
                        };
                        dispatch(updateProductImage(obj))
                            .then(() => {
                                setLoading(false);
                                handleModalCancel();
                                dispatch(getBrandImages({ barcode: data?.barcode }))
                            })
                    })
                    .catch((error) => {
                        console.error("Error uploading file:", error);
                        setLoading(false);
                    });
            } else {
                message.error("Failed to process cropped image.");
                setLoading(false);
            }
        } else {
            setLoading(false);
            message.error("Failed to crop image.");
        }
    };

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Update
            </Button>
            <Modal
                visible={isModalVisible}
                onCancel={handleModalCancel}
                title="Update Image"
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ hidden: true }}
                destroyOnClose
                width={900}
                maskClosable={false}
            >
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {/* Upload/Preview Section */}
                    <div style={{ padding: "10px", display: "flex", justifyContent: "center" }}>
                        {image ? (
                            <div style={{ border: "1px dashed #ddd", padding: "20px", textAlign: "center", margin: "0 10px" }}>
                                <img
                                    src={image}
                                    alt="Uploaded"
                                    style={{ width: "100%", maxHeight: "200px", objectFit: "contain" }}
                                />
                                <p style={{ color: "green" }}>Uploaded</p>
                                <Button
                                    onClick={() => {
                                        setImage(null);
                                        setCrop({ unit: "px", width: FIXED_CROP.width, height: FIXED_CROP.height, x: 50, y: 50 });
                                        setCompletedCrop(null);
                                        setScale(1);
                                    }}
                                    style={{
                                        backgroundColor: "red",
                                        color: "white",
                                        border: "none",
                                        padding: "5px 10px",
                                        cursor: "pointer",
                                        borderRadius: "5px",
                                        marginTop: "10px",
                                    }}
                                >
                                    Delete
                                </Button>
                            </div>
                        ) : (
                            <Upload showUploadList={false} customRequest={onSelectFile} accept="image/*">
                                <div style={{ border: "1px dashed #ddd", padding: "20px", textAlign: "center", margin: "0 10px" }}>
                                    <PlusOutlined />
                                    <div>Upload Image</div>
                                </div>
                            </Upload>
                        )}
                    </div>

                    {image && (
                        <div style={{ display: "flex", padding: "10px", justifyContent: "center" }}>
                            <div style={{ flex: 1, padding: "10px", textAlign: "center", borderRight: "2px solid #ddd" }}>
                                <div style={{ position: "relative", height: "450px" }}>
                                    <div style={{ marginBottom: "10px" }}>
                                        <label htmlFor="scale-input" style={{ marginRight: "10px" }}>Scale:</label>
                                        <input
                                            id="scale-input"
                                            type="number"
                                            step="0.1"
                                            value={scale}
                                            onChange={(e) => setScale(Number(e.target.value))}
                                        />
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <ReactCrop
                                            crop={crop}
                                            onChange={(newCrop) =>
                                                setCrop({ ...newCrop, width: FIXED_CROP.width, height: FIXED_CROP.height })
                                            }
                                            onComplete={(c) => setCompletedCrop(c)}
                                            minWidth={FIXED_CROP.width}
                                            minHeight={FIXED_CROP.height}
                                        >
                                            <img
                                                ref={imgRef}
                                                src={image}
                                                alt="Uploaded"
                                                style={{
                                                    transform: `scale(${scale})`,
                                                    minHeight: "325px",
                                                }}
                                            />
                                        </ReactCrop>
                                    </div>
                                </div>
                            </div>

                            <div style={{ flex: 1, padding: "10px", textAlign: "center" }}>
                                <div className="d-flex align-items-center justify-content-center" style={{ height: '450px' }}>
                                    <canvas
                                        ref={previewCanvasRef}
                                        style={{ border: "1px solid black", width: "275px", height: "325px", marginTop: "10px" }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "40px" }}>
                        <Button onClick={handleModalCancel} style={{ backgroundColor: "#FFA500", color: "white" }}>
                            Close
                        </Button>
                        <Button type="primary" onClick={onDownloadCropClick} loading={loading}>
                            Upload Image
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
