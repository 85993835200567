import React, { Component } from "react";
import { Table, Tooltip, Pagination, Button, Space, Input, Modal, Row, Col } from "antd";
import {
  EyeOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getdefectedProductBillsaleReturnId } from "../../../../../redux/actions/saleAction";
import Highlighter from "react-highlight-words";
import gifLoader from "../../../../../assets/images/giphy.gif"


const { Column } = Table;

class ShowBilldefectedProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      visible: false,


    };
  }

  showModal = () => {
    this.props.getdefectedProductBillsaleReturnId(this.props.saleReturnId, 1);
    this.setState({
      visible: true,
    });
  };


  handleModalCancel = () => {
    this.setState({ visible: false });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  render() {
    const { defectedProductBillSaleReturnId } = this.props
    return (
      <div>
        <button
          className="btn"
          type="button"
          data-original-title=""
          onClick={this.showModal}
        >
          <Tooltip title="View Products">
            <EyeOutlined />
          </Tooltip>
        </button>
        <Modal
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleModalCancel}
          title="View Products"
          style={{ top: 20 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
          width={1500}
        >
          {
            defectedProductBillSaleReturnId && defectedProductBillSaleReturnId.data && defectedProductBillSaleReturnId.data.length > 0 ? <>
                   <Table
            dataSource={this.props.defectedProductBillSaleReturnId && this.props.defectedProductBillSaleReturnId ? this.props.defectedProductBillSaleReturnId.data : []}
            bordered="true"
            size="middle"
            pagination={{
              pageSize: 10,
              total: this.props.defectedProductBillSaleReturnId && this.props.defectedProductBillSaleReturnId.count ? this.props.defectedProductBillSaleReturnId.count : "",
              onChange: (page) => {
                this.props.getdefectedProductBillsaleReturnId(this.props.saleReturnId, page);

              }
            }}
            scroll={{ y: 240 }}
          >{console.log("test====", this.props.defectedProductBillSaleReturnId)}
            <Column
              title="SRN"
              key="index"
              render={(value, item, index) => index + 1}
              width={60}
            />

            <Column
              title="Brand"
              dataIndex="brand"
              key="brand"
              width={150}
              {...this.getColumnSearchProps("invoiceId")}
            />

            <Column
              title="Quantity"
              dataIndex="quantity"
              key="quantity"
              width={60}
            />
            <Column
              title="Total Amount"
              dataIndex="amount"
              key="amount"
              width={60}
            />

          </Table>
          <div className=" mt-4">
            <div className="col-md-12 ml-auto">
              <div className="grand-totall" style={{padding:"18px 17px 4px"}}>
                <div className="title-wrap">
                  <h4 className="cart-bottom-title section-bg-gary-cart">
                    Defected Total
                  </h4>
                </div>
                <h5>
                  Total Bills{" "}
                  <span>
                    {defectedProductBillSaleReturnId && defectedProductBillSaleReturnId.data ? defectedProductBillSaleReturnId.data.length : 0}
                  </span>
                </h5>
                {/* <h5>
                  Total Boxes{" "}
                  <span>
                    {(() => {
                      let allBoxes = 0;
                      if (defectedProductBillSaleReturnId && defectedProductBillSaleReturnId.data && defectedProductBillSaleReturnId.data.length > 0) {
                        defectedProductBillSaleReturnId.data.map(obj => {
                          allBoxes += obj.quantity / obj.MOQ;
                        });
                      }
                      return allBoxes;
                    })()}
                  </span>
                </h5> */}
                <h5>
                  Total Quantity{" "}
                  <span>
                    {this.props.data.totalQuantity}
                  </span>
                </h5>
                {/* <h4 className="grand-totall-title">
                  Total Amount{" "}
                  <span>
                    {this.props.data.totalAmount}
                  </span>
                </h4> */}

                <h5 className="grand-totall-title">
                      Total Amount{" "}
                      <span>{this.props.data.totalAmount}</span>
                    </h5>

              </div>
            </div>
          </div>
            </> : <>
            <div className="d-flex justify-content-center align-items-center">
            <img src={gifLoader} width={"300px"} height={"300px"}/>
            </div>
            </>
          }
        </Modal>
      </div>
    );
  }
}

ShowBilldefectedProduct.propTypes = {
  getdefectedProductBillsaleReturnId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {

    defectedProductBillSaleReturnId: state.saleData.defectedProductBillSaleReturnId,
  }
};

export default connect(mapStateToProps, { getdefectedProductBillsaleReturnId })(ShowBilldefectedProduct);
