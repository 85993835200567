import React, { useState } from "react";
import { Modal, Button, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { deleteProductImage, getBrandImages } from "../../../../redux/actions/brandAction";
import dummyImage from "../../../../assets/images/placeholder.jpg";
import UpdateImageUpload from "./UpdateImageUpload";
import AddSingleImage from "./AddSingleImage";
import { PlusOutlined } from "@ant-design/icons";
import { deletImageByBarcode } from "../../../../redux/actions/purchaseAction";

export default function AddImageUploadModel2({ data }) {
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const productImages = useSelector((state) => state.brandsData.brandImages);
    const [loading, setLoading] = useState(false);

    let imagesToDisplay = [];
    if (productImages && productImages.length > 0) {
        const validImages = productImages.map((img) =>
            img.trim() === "" ? dummyImage : img
        );
        imagesToDisplay = validImages.slice(0, 4);
        while (imagesToDisplay.length < 4) {
            imagesToDisplay.push(dummyImage);
        }
    } else {
        imagesToDisplay = new Array(4).fill(dummyImage);
    }

    const handleModalCancel = () => {
        setIsModalVisible(false);
    };

    const showModal = () => {
        dispatch(getBrandImages({ barcode: data?.barcode }));
        setIsModalVisible(true);
    };


    const handleDelete = (url) => {
        // e.preventDefault();
        dispatch(deleteProductImage({ imageName: url, barcode: data?.barcode }))
            .then(() => {
                dispatch(getBrandImages({ barcode: data?.barcode }));
            })
            .catch((error) => {
                console.error("Error deleting image:", error);
            });
    };


    const handleDeleteAll = () => {
        if (productImages && productImages.length) {
            const filteredImages = productImages.filter((url) => url.trim() !== "");
            dispatch(deletImageByBarcode({ imageName: filteredImages, barcode: data?.barcode }))
                .then(() => {
                    dispatch(getBrandImages({ barcode: data?.barcode }));
                })
                .catch((error) => {
                    console.error("Error deleting image:", error);
                });
        }
    };



    return (
        <>
            <Button type="primary" onClick={showModal}>
                <PlusOutlined />
            </Button>
            <Modal
                visible={isModalVisible}
                onCancel={handleModalCancel}
                title="Add Images"
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ hidden: true }}
                destroyOnClose
                maskClosable={false}
                width={650}
                bodyStyle={{ maxHeight: "70vh", overflowY: "auto", padding: "20px" }} // Scrollable modal
            >
                <div>
                    {imagesToDisplay.length > 0 ? (
                        imagesToDisplay.map((item, index) => (
                            <Row
                                key={index}
                                gutter={24}
                                align="middle"
                                style={{ marginBottom: "10px" }}
                            >
                                <Col span={12}>
                                    <img
                                        src={item}
                                        alt=""
                                        style={{
                                            width: "275px",
                                            height: "325px",
                                            display: "block",
                                            objectFit: "cover",
                                            borderRadius: "8px",
                                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                                        }}
                                    />
                                </Col>
                                <Col span={12}>
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ width: "100%", height: "325px" }}
                                    >
                                        {item === dummyImage ? (
                                            <AddSingleImage data={data} />
                                        ) : (
                                            <>
                                                <Button className="mx-2 btn-danger" onClick={() => handleDelete(item)}>Delete</Button>
                                                {/* <Button className="mx-2 btn-warning">Update</Button> */}
                                                <UpdateImageUpload data={data} oldImage={item} />
                                            </>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        ))
                    ) : (
                        <p>No images available</p>
                    )}
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "40px" }}>
                    <Button onClick={handleModalCancel} style={{ backgroundColor: "#FFA500", color: "white" }}>
                        Close
                    </Button>
                    {
                        productImages && productImages.length
                            ?
                            <Button type="primary" onClick={handleDeleteAll} loading={loading}>
                                Delete All Images
                            </Button>
                            : null
                    }
                </div>
            </Modal>
        </>
    );
}
