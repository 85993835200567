import React, { useState } from "react";
import { Row, Modal, Form, Button, Input, Col, Select, message } from "antd";
import { getDatabydesignandBrandforInventory, getDatabyColororSizeforInventory } from "../../../redux/actions/purchaseAction";
import { useDispatch, useSelector } from "react-redux";
import { getBrandBySearch } from "../../../redux/actions/brandAction";
import { getColors } from "../../../redux/actions/colorAction";
import { getSizes } from "../../../redux/actions/sizeAction";
const { Option } = Select;
const serachType = [
  { name: "Design No & Brand", value: 0 },
  { name: "Color & Size", value: 1 },
];
const DesignandBrandWiseSearchMobile = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [designNo, setDesignNo] = useState(""); // Initialize with an empty string
  const [brandName, setBrandName] = useState(""); // Initialize with an empty string
  const [color, setColor] = useState(""); // Initialize with an empty string
  const [size, setSize] = useState(""); // Initialize with an empty string
  const [SearchType, setSearchType] = useState(3); // Initialize with an empty string

  const dispatch = useDispatch();
  const brandData = useSelector((state) => state.brandsData.brandBysearch);
  const sizesData = useSelector((state) => state.sizeData.sizes);
  const colorsData = useSelector((state) => state.colorData.colors);
  const showModal = () => {
    setVisible(true);
  };
  const handleModalCancel = () => {
    setVisible(false);
  };
  const onSubmit = () => {
    if (SearchType == 1 && !color && !size) {
      message.error("Please select color or size");
      return;
    }
    setLoading(true);
    if (SearchType == 0) {
      let obj = {
        designNumber: designNo,
        brand: brandName,
      };
      dispatch(getDatabydesignandBrandforInventory(obj)).finally(() => {
        setLoading(false);
        setVisible(false);
      });
    }
    if (SearchType == 1) {
      let obj = {
        color: [color],
        size: [size],
      };
      dispatch(getDatabyColororSizeforInventory(obj)).finally(() => {
        setLoading(false);
        setVisible(false);
      });
    }
  };
  const onChange = (name, value) => {
    if (name === "brand") {
      setBrandName(value);
    }
    if (name === "designNo") {
      setDesignNo(value);
    }
    if (name === "serachType") {
      setSearchType(value);
    }
    if (name === "color") {
      setColor(value);
    }
    if (name === "Size") {
      setSize(value);
    }
  };

  const getBrandData = () => {
    dispatch(getBrandBySearch());
  };

  const brandOptions = brandData?.data?.map((brand) => {
    return (
      <Option name={brand.name} value={brand.name}>
        {brand.name}
      </Option>
    );
  });

  const sizeOptions = sizesData.map((size) => {
    return (
      <Option name={size.name} value={size.name} key={size.name}>
        {size.name}
      </Option>
    );
  });
  const colorOptions = colorsData.map((color) => {
    return (
      <Option name={color.name} value={color.name}>
        {color.name}
      </Option>
    );
  });
  return (
    <>
      <Button
        className="btn-admin btn-subCategory"
        type="primary"
        onClick={() => showModal()}
      >
        Details Search
      </Button>
      <Row>
        <Col span={24} align="center">
          <div className="add-button">
            <Modal
              visible={visible}
              onCancel={() => handleModalCancel()}
              title="Details Search"
              style={{ top: 20 }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <Form
                name="design and Brand"
                className="add-brand"
                onFinish={onSubmit}
                initialValues={{
                  remember: true,
                }}
              >
                <Form.Item
                  name="serachType"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Search Type!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Seach Type"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={(e) => onChange("serachType", e)}
                  >
                    {serachType.map((obj) => {
                      return (
                        <Option name={obj.name} value={obj.value}>
                          {obj.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                {SearchType == 0 && (
                  <>
                    <Form.Item
                      name="brand"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Brand Name !",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select Brand"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={(e) => onChange("brand", e)}
                        onClick={() => getBrandData("brand")}
                        onInputKeyDown={() => getBrandData("brand")}
                      >
                        {brandOptions}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="designNo"
                      rules={[
                        {
                          required: true,
                          message: "Please Design No !",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Design No"
                        name="designNo"
                        value={designNo}
                        onChange={(e) => onChange("designNo", e.target.value)}
                      />
                    </Form.Item>
                  </>
                )}

                {SearchType == 1 && (
                  <>
                    <Form.Item
                      name="color"
                      rules={[
                        {
                          required: false,
                          message: "Please Select Color !",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select Color"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={(e) => onChange("color", e)}
                        onClick={() => dispatch(getColors())}
                      >
                        {colorOptions}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="Size"
                      rules={[
                        {
                          required: false,
                          message: "Please Select Size !",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select Size"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={(e) => onChange("Size", e)}
                        onClick={() => dispatch(getSizes())}
                      >
                        {sizeOptions}
                      </Select>
                    </Form.Item>
                  </>
                )}

                <Form.Item className="float-right">
                  <Button
                    className="close-modal mr-3"
                    onClick={() => handleModalCancel()}
                  >
                    Close
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="ok-modal"
                    loading={loading}
                  >
                    Search
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DesignandBrandWiseSearchMobile;
