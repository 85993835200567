import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Drawer, Row, Input } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BoyImage from "../../../assets/images/boy.png";
import Arrow from "../../../assets/images/Group 137.png";
import "./CategoriesPage.css";
import SubCategroiesDrawer from './SubCategroiesDrawer';

function CategoriesDrawer({ categories }) {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState(""); // State for search input

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    // Filter categories based on the search term
    const filteredCategories = categories?.filter(category =>
        category.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <li className="menu-item-has-children">
                <a onClick={showDrawer}>{"All Categories"}</a>
            </li>
            <Drawer
                title={
                    <div className='breadCrumb-Text text-white text-center' style={{ fontSize: '18px', fontWeight: 'bold' }}>
                        All Categories
                    </div>
                }
                placement="right"
                onClose={closeDrawer}
                visible={drawerVisible}
                width="100dvw"
                className='styleDrawerHeader'
                closeIcon={<ArrowLeftOutlined style={{ fontSize: '20px', color: '#fff' }} />}
            >
                <Row gutter={24}>
                    <Col span={1} align="center">
                    </Col>
                    <Col span={22} align="center">
                        <Input
                            placeholder="Search categories..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{ marginBottom: "20px" }}
                        />
                    </Col>
                    <Col span={1} align="center">
                    </Col>
                </Row>

                {/* <Row gutter={24}>
                <Col span={8} key={category._id} align="center" className="my-3"> */}
                <div className="category-page">
                    <div className="category-grid">
                        {filteredCategories?.map((category) => (
                            <SubCategroiesDrawer setDrawerVisibleParent={setDrawerVisible} categoryName={category?.name} styles={category?.styles} id={category?._id} />
                        ))}
                    </div>
                </div>
                {/* </Col>
            </Row> */}
            </Drawer>
        </>
    );
}

export default CategoriesDrawer;
