/* /////////////////////////////////////// */
/* 
Date              created by               Modifications
  
01-08-2023        Mohit                    Update Purchase              

*/
/* /////////////////////////////////////// */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Row,
  Col,
  message,
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  Modal,
  Tooltip,
  Spin,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getDealers } from "../../../../../../redux/actions/dealerAction";
import moment from "moment";
// import ShowPurchaseDetails from "./ShowPurchaseDetails";
import {
  addPurchase,
  getPurchasesById,
  getUpdatePurchaseBill,
} from "../../../../../../redux/actions/purchaseAction";
import { others } from "../../../../../../util/constants";
import { withRouter } from "react-router-dom";
import ShowupdatePurchase from "./ShowupdatePurchase";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getCustomers } from "../../../../../../redux/actions/customerAction";

const dateFormat = "DD/MM/YYYY";
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
let purchase = {};

class UpdatePurchaseBill extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      remark:this.props?.history?.location?.state?.remark || "",
      spinLoad: false,
      finalArray: [],
      newArray: this.props.purchasesById,
      // remark : 
      gstNo:
        this.props &&
          this.props.purchasesById &&
          this.props.purchasesById.length > 0
          ? this.props.purchasesById[0].gst
          : undefined,
      data: {
        loading: false,
        values: {},
        dealerName:
          this.props &&
            this.props.purchasesById &&
            this.props.purchasesById.length > 0
            ? this.props.purchasesById[0].dealerName
            : "",
        dealerId:
          this.props &&
            this.props.purchasesById &&
            this.props.purchasesById.length > 0
            ? this.props.purchasesById[0].dealerId
            : "",
        billNo:
          this.props &&
            this.props.purchasesById &&
            this.props.purchasesById.length > 0
            ? this.props.purchasesById[0].billNo
            : "",
        billDate:
          this.props &&
            this.props.purchasesById &&
            this.props.purchasesById.length > 0
            ? moment(this.props.purchasesById[0].billDate, "DD/MM/YYYY").format(
              "YYYY-MM-DD"
            )
            : moment(new Date()),
        date:
          this.props &&
            this.props.purchasesById &&
            this.props.purchasesById.length > 0
            ? moment(this.props.purchasesById[0].billDate, "DD/MM/YYYY").format(
              "DD/MM/YYYY"
            )
            : moment(new Date()),
        gst: "",
        markup: "",
        brandName: "",
        brandId: "",
        categoryName: "",
        categoryId: "",
        groupName: "",
        groupId: "",
        styleName: "",
        styleId: "",
        seasonName: "",
        seasonId: "",
        sectionName: "",
        sectionId: "",
        locationName: "airport road",
        locationId: "6410742794ee79805682e477",
        MOQ: "",
        others: others,
        HSNCode: "",
        designNumber: "",
        sizeName: "",
        sizeId: "",
        colorName: "",
        colorId: "",
        purchasePrice: "",
        wsp: "",
        quantity: "",
        totalBox: "",
        discount: 0,
        tax: 5,
        barcode: "",
        totalQuantity: "",
        netAmount: 0,
        finalDiscount: 0,
        totalAmount: 0,
        errors: {},
        myPurchaseData: [],
        discountedAmount: 0,
        taxedAmount: 0,
        finalAmount: 0,
        floorName: "",
        floorId: "",
        ageGroupId: "",
        ageGroupName: "",
        purchasePersonId:
          this.props &&
            this.props.purchasesById &&
            this.props.purchasesById.length > 0
            ? this.props.purchasesById[0].purchasePersonId
            : "",
        purchasePersonName:
          this.props &&
            this.props.purchasesById &&
            this.props.purchasesById.length > 0
            ? this.props.purchasesById[0].purchasePersonName
            : "",
      },
      visible: false,
      isUpdatedBill: false,
    };
    this.handleKeypress = this.handleKeypress.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  updatePurchasebill = async () => {
    let findBlankPackagingValue = this.state.newArray?.some(
      (obj) => !obj.packagingType || !obj.packagingValue
    );
    if (findBlankPackagingValue) {
      message.warn(
        "Please update the packaging type and value of the highlighted products."
      );
      return;
    }
    this.setState({ spinLoad: true });
    function removeKeysFromArrayObjects(arr, keysToRemove) {
      return arr.map((obj) => {
        keysToRemove.forEach((key) => delete obj[key]);
        return obj;
      });
    }
    const keysToRemove = ["dealerId", "dealerName", "billNo"];
    const updatedArrayOfObjects = removeKeysFromArrayObjects(
      this.state.newArray ? this.state.newArray : this.props.purchasesById,
      keysToRemove
    );
    let userId = localStorage.getItem("userId");
    let requestObj = {
      dealerName: this.state.data.dealerName,
      dealerId: this.state.data.dealerId,
      billDate: this.state.data.billDate,
      purchases: updatedArrayOfObjects,
      billNo: this.state.data.billNo,
      purchaseId: this.props.purchasesById[0].purchaseId,
      gstNo: this.state.gstNo,
      userId: userId,
      purchasePersonId: this.state.data.purchasePersonId,
      purchasePersonName: this.state.data.purchasePersonName,
      roleId: this.props.roleId,
      date: this.state.data.date,
      remark : this.state.remark
    };

    console.log("check my obj test hello", requestObj)
    this.props
      .getUpdatePurchaseBill(requestObj, "new")
      .then((response) => {
        if (response.status === 200) {
          message.success(response.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong");
      })
      .finally(() => {
        setTimeout(() => {
          this.setState({ spinLoad: false });
          setTimeout(() => {
            this.props.history.push("/dagaImpex/purchaseBill");
          }, 500);
          // window.location.reload(0)
        }, 1000);
      });
  };

  getData = (name) => {
    if (name == "dealer") this.props.getDealers();
    if (name == "purchaser") this.props.getCustomers("PURCHASER");
  };

  onChange = (event) => {
    if(event.target.name === "remark"){
      this.setState({remark:event.target.value})
    }
    let data = this.state.data;
    data[event.target.name] = event.target.value;
    this.setState({ data });
  };

  updateObj = (data) => {
    let newArray1 = this.state.newArray.map((item) => {
      if (item._id === data._id) {
        return data;
      } else {
        return item;
      }
    });
    this.setState({
      newArray: newArray1,
    });

    // this.setState((prevState) => {
    //     const newArray = this.props.purchasesById.map((item) => {
    //         if (item._id === data._id) {
    //             return data; // Replace the object with 'data' if the _id matches
    //         } else {
    //             return item; // Otherwise, keep the original object
    //         }
    //     });
    //     return {
    //         array: newArray,
    //     };
    // });
  };

  handleBlur(e) {
    if (e.currentTarget.value === "0") e.currentTarget.value = "1";
  }
  preventScroll = (e) => {
    if (document.activeElement === e.target) {
      e.preventDefault();
    }
  };
  handleKeypress(e) {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      } else if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  }

  componentDidMount = () => {
    const dataReceived = this.props.location;
    // this.props.purchasesById
    this.formRef.current.setFieldsValue({
      dealerName: this.state.data.dealerName,
      purchasePersonName: this.state.data.purchasePersonName,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const currentRemark = this.props.history?.location?.state?.remark || "";
    const prevRemark = prevProps.history?.location?.state?.remark || "";
  
    if (currentRemark !== prevRemark) {
      this.setState({ remark: currentRemark });
    }

    if (prevProps.errors !== this.props.errors) {
      message.error(this.props.errors.message);
    }
    if (prevState.data.dealerName !== this.state.data.dealerName) {
      this.formRef.current.setFieldsValue({
        dealerName: this.state.data.dealerName,
        purchasePersonName: this.state.data.purchasePersonName,
      });
    } else {
      this.formRef.current.setFieldsValue({
        dealerName: this.state.data.dealerName,
        purchasePersonName: this.state.data.purchasePersonName,
      });
    }
  }

  handleWheel = (event) => {
    event.preventDefault();
  };

  handleKeyDown = (event) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  };

  onDateChange = (date, dateString) => {
    const { data } = this.state;
    data.billDate = moment(dateString, "YYYY-MM-DD").format("YYYY-MM-DD");
    data.date = moment(dateString, "YYYY-MM-DD").format("DD/MM/YYYY");
    this.setState({ data });
  };
  handleChangeDealer = (key, value) => {
    let data = this.state.data;
    data["dealerId"] = value.value;
    data["dealerName"] = value.name[0];
    this.setState({ data });
    this.setState({ gstNo: value.gstValue });
  };
  handleChangePurchaser = (key, value) => {
    let data = this.state.data;
    data["purchasePersonId"] = value.value;
    data["purchasePersonName"] = value.name;
    this.setState({ data });
    // this.setState({
    //   purchasePersonId: value.value,
    //   purchasePersonName: value.name
    // });
  };

  disabledDate = (current) => {
    // Can not select days after today
    return current && current > moment().endOf("day");
  };

  render() {
    var currentDate = moment(this.state.data.billDate);

    const { roleId } = this.props;
    const dateObject = this.state.data.billDate;
    let dealerOptions = this.props.dealers.map((dealer) => {
      return (
        <Option
          name={[dealer.name, dealer.gst]}
          value={dealer._id}
          gstValue={dealer.gst}
        >
          {dealer.name}
        </Option>
      );
    });

    let purchaseOptions = this.props.purchaserList.map((purchase) => {
      return (
        <Option name={purchase.name} value={purchase._id}>
          {purchase.name}
        </Option>
      );
    });

    const { loading } = this.state.data;
    const initialValues = {
      users: [
        { size: undefined, colors: [undefined] },
      ],
    };

  console.log("checkkllkl 9669",  this.props.history, )


    var disabled = false;
    if (this.state.data.donedone) {
      disabled = true;
    }

    const amount = this.state.newArray?.reduce((sum, item) => {
      const amount = Number(item.quantity || 0) * Number(item.purchasePrice || 0);
      const discountedAmount = Number(amount * Number(item.discount)) / 100;
      const finalAmount = (amount - discountedAmount)
      return sum + finalAmount;
    }, 0) || this.props.purchasesById.reduce((sum, item) => {
      const amount = (item.quantity || 0) * (item.purchasePrice || 0);
      const discountedAmount = (amount * item.discount) / 100;
      const finalAmount = (amount - discountedAmount)
      return sum + finalAmount;
    }, 0)

    const discountAmount = (amount * this.props.location.state?.data?.discount) / 100;
    const discountedAmount = (amount - discountAmount)
    const TaxAmount = (discountedAmount * this.props.location.state?.data?.tax) / 100
    const finalAmount = discountedAmount + TaxAmount

    const NumberOfPackets = this.props.purchasesById?.reduce((total, item) => {
      let calculatedQuantity = 0;

      if (item.packagingType === "Loose" && item.packagingValue === "Collective") {
        calculatedQuantity = item.quantity / item.MOQ;
      } else if (item.packagingType === "Loose" && item.packagingValue === "Individual") {
        calculatedQuantity = item.quantity;
      }

      return total + calculatedQuantity;
    }, 0);

    const NumberOfBoxes = this.props.purchasesById?.reduce((total, item) => {
      let calculatedQuantity = 0;

      if (item.packagingType === "Box" && item.packagingValue === "Collective") {
        calculatedQuantity = item.quantity / item.MOQ;
      } else if (item.packagingType === "Box" && item.packagingValue === "Individual") {
        calculatedQuantity = item.quantity;
      }

      return total + calculatedQuantity;
    }, 0);

    return (
      <div className="add-purchase ">
        <div className="container-fluid">
          <Row className="title-row">
            <Col xs={22} sm={22} md={22} lg={22} xl={22}>
              <div className="page-header">Update Purchase</div>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
              <Button
                type="primary"
                className="save-modal text-white"
                onClick={() =>
                  this.props.history.push("/dagaImpex/purchaseBill", true)
                }
              >
                <ArrowLeftOutlined /> Back
              </Button>
            </Col>
          </Row>
          <div className="card mt-4">
            <div className="card-body">
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form
                    ref={this.formRef}
                    name="add-Purchase"
                    className="add-Purchase"
                    onFinish={this.showDataOnList}
                    initialValues={initialValues}
                  >
                    <Row gutter={[8, 0]}>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Tooltip title="Select Dealer">
                          <Form.Item
                            name="dealerName"
                            rules={[
                              {
                                required: true,
                                message: "Please Select Dealer Name !",
                              },
                            ]}
                          >
                            <Select
                              disabled={disabled}
                              showSearch
                              placeholder="Select Dealer"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                              onChange={this.handleChangeDealer}
                              onClick={() => this.getData("dealer")}
                              onInputKeyDown={() => this.getData("dealer")}
                            >
                              {dealerOptions}
                            </Select>
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Tooltip title="Select Purchaser Name">
                          <Form.Item
                            name="purchasePersonName"
                            rules={[
                              {
                                required: true,
                                message: "Please Select Purchaser Name !",
                              },
                            ]}
                          >
                            <Select
                              disabled={disabled}
                              showSearch
                              placeholder="Select Purchaser Name"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                              onChange={this.handleChangePurchaser}
                              onClick={() => this.getData("purchaser")}
                              onInputKeyDown={() => this.getData("purchaser")}
                            >
                              {purchaseOptions}
                            </Select>
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Tooltip title="Enter Bill Number">
                          <Form.Item
                            // name="billNo"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Bill Number !",
                              },
                            ]}
                          >
                            <Input
                              disabled={disabled}
                              placeholder="Enter Bill Number"
                              name="billNo"
                              value={this.state.data.billNo}
                              onChange={this.onChange}
                              required
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Tooltip title="Select Bill Date">
                          <Form.Item name="billDate">
                            <DatePicker
                              disabled={disabled}
                              onChange={this.onDateChange}
                              placeholder="Select Bill Date"
                              defaultValue={moment(dateObject)}
                              disabledDate={this.disabledDate}
                            //   format={dateFormat}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                    </Row>
                    <Row>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Tooltip title="Enter Remark">
                          <Form.Item
                            // name="billNo"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Remark!",
                              },
                            ]}
                          >
                            <Input
                              disabled={disabled}
                              placeholder="Enter Remark"
                              name="remark"
                              value={this.state.remark}
                              onChange={this.onChange}
                              required
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <ShowupdatePurchase
                    myPurchaseData={this.props.purchasesById}
                    updateObj={this.updateObj}
                    gstNo={this.state.gstNo}
                    roleId={this.props.roleId}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item>
                    <Button
                      type="primary"
                      className="ok-modal mt-3"
                      loading={this.state.spinLoad}
                      // disabled={this.state.spinLoad}
                      onClick={() => this.updatePurchasebill()}
                      block
                    >
                      Update
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

              <div className="mt-4">
                <div className="ml-auto">
                  <div
                    className="grand-totall"
                    style={{ padding: "18px 17px 4px" }}
                  >
                    <div className="title-wrap">
                      <h4 className="cart-bottom-title section-bg-gary-cart">
                        Purchase Total
                      </h4>
                    </div>
                    <h5>
                      Total Products{" "}
                      <span>
                        {this.props.purchasesById?.length || ""}
                      </span>
                    </h5>
                    <h5>
                      Total Packets
                      <span>
                        {/* { this.state.newArray?.reduce((sum, item) => sum + Number(item.totalBox || 0), 0) || this.props.purchasesById?.reduce((sum, item) => sum + (item.totalBox || 0), 0)} */}
                        {NumberOfPackets || 0}
                      </span>
                    </h5>
                    <h5>
                      Total Boxes
                      <span>
                        {/* {this.state.newArray?.reduce((sum, item) => sum + Number(item.totalBox || 0), 0) || this.props.purchasesById?.reduce((sum, item) => sum + (item.totalBox || 0), 0)} */}
                        {NumberOfBoxes || 0}
                      </span>
                    </h5>
                    <h5>
                      Total Sets
                      <span>
                        {
                          this.state.newArray?.reduce((sum, item) => sum + (Number(item.quantity || 0) / Number(item.MOQ)), 0)
                          ||
                          this.props.purchasesById?.reduce((sum, item) => sum + (Number(item.quantity || 0) / Number(item.MOQ)), 0)
                        }
                      </span>
                    </h5>
                    <h5>
                      Total Quantity{" "}
                      <span>
                        {this.state.newArray?.reduce((sum, item) => sum + Number(item.quantity || 0), 0) || this.props.purchasesById?.reduce((sum, item) => sum + (item.quantity || 0), 0)}
                      </span>
                    </h5>
                    <h5>
                      Total Amount
                      <span>
                        {amount || 0}
                      </span>
                    </h5>
                    <h5 className="grand-totall-title">
                      Net Amount{" "}
                      <span>
                        {
                          Math.round(finalAmount)
                        }
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
UpdatePurchaseBill.propTypes = {
  getDealers: PropTypes.func.isRequired,
  addPurchase: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  getPurchasesById: PropTypes.object.isRequired,
  getUpdatePurchaseBill: PropTypes.object.isRequired,
  getCustomers: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dealers: state.dealersData.dealers,
  sections: state.sectionData.sections,
  seasons: state.seasonData.seasons,
  locations: state.locationData.locations,
  groups: state.groupData.groups,
  colors: state.colorData.colors,
  categories: state.categoryData.categories,
  brands: state.brandsData.brands,
  styles: state.styleData.styles,
  sizes: state.sizeData.sizes,
  floors: state.floorData.floors,
  ageGroups: state.ageGroupData.ageGroups,
  purchases: state.purchaseData.purchases,
  purchasesById: state.purchaseData.purchaseById,
  purchaserList: state.customerData.salesPerson,
});

export default withRouter(
  connect(mapStateToProps, {
    getDealers,
    addPurchase,
    getPurchasesById,
    getUpdatePurchaseBill,
    getCustomers,
  })(UpdatePurchaseBill)
);
