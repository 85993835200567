/* /////////////////////////////////////// */
/* 
Date              created by               Modifications
  
15-08-2023        Mohit                    Purchase Update
12-09-2023        Mohit                    onChange Function Update for change the wsp on markup change   

*/
/* /////////////////////////////////////// */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Row,
  Col,
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  Modal,
  Tooltip,
  message,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import { getDealers } from "../../../../redux/actions/dealerAction";
import { getActiveInactiveBrands } from "../../../../redux/actions/brandAction";
import { getCategories } from "../../../../redux/actions/categoryAction";
import { getGroups } from "../../../../redux/actions/groupAction";
import { getStyles } from "../../../../redux/actions/styleAction";
import { getSeasons } from "../../../../redux/actions/seasonAction";
import { getSections } from "../../../../redux/actions/sectionAction";
import { getLocations } from "../../../../redux/actions/locationAction";
import { getSizes } from "../../../../redux/actions/sizeAction";
import { getColors } from "../../../../redux/actions/colorAction";
import { getAgeGroups } from "../../../../redux/actions/ageGroupAction";
import { getFloors } from "../../../../redux/actions/floorAction";
import { getTexture } from "../../../../redux/actions/textureAction";
import { updatePurchase } from "../../../../redux/actions/purchaseAction";
import { getLookupData } from "../../../../redux/actions/purchaseAction";
import { getPackagingMethods } from "../../../../redux/actions/packageMethodAction";
import moment from "moment";
import { getPackagingMethod } from "../../../../redux/actions/packageMethodAction";
const dateFormat = "DD/MM/YYYY";
const { Option } = Select;

class UpdatePurchase extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      data: {
        gst: "",
        markup: "",
        brand: "",
        brandId: "",
        category: "",
        categoryId: "",
        group: "",
        groupId: "",
        style: "",
        styleId: "",
        season: "",
        seasonId: "",
        section: "",
        sectionId: "",
        location: "",
        locationId: "",
        MOQ: "",
        others: 0,
        HSNCode: "",
        designNumber: "",
        size: "",
        color: "",
        purchasePrice: "",
        wsp: "",
        quantity: "",
        discount: 0,
        barcode: "",
        floorName: "",
        floorId: "",
        ageGroupId: "",
        ageGroupName: "",
        ecommProductVisible: "",
        generated: "",
        purchaseId: "",
        sales: "",
        salesDiscount: "",
        _id: null,
        __v: "",
        _id: "",
        created_at: "",
        textureName: "",
        textureId: "",
        salesTax: "",
        packagingType: "",
        packagingValue: "",
        noOfPackets: 0,
        noOfBoxes: 0,
        packagingType: "",
        packagingValue: "",
        // boxSet : Number
      },
      packagingMethodType: "",
      loading: false,
      visible: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleKeypress = this.handleKeypress.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  onChange(event, key, temp) {
    // var wsp = Number(this.state.data.others);
    var totalBox = 0;
    console.log(event.target.name);
    if (event.target.name == "MOQ") {
      let name = "MOQ";
      let data = this.state.data;
      // =========
      let nameTotalBox = "totalBox";
      totalBox = Number(this.state.data.quantity) / Number(event.target.value);
      data[nameTotalBox] = totalBox;

      data[name] = event.target.value;
      this.setState({ data });
      // =============
      return;
    }
    if (event.target.name == "markup") {
      let name = "markup";
      let data = this.state.data;
      let finalwsp = (this.state.data.purchasePrice * event.target.value) / 100;
      let wsp =
        Number(this.state.data.purchasePrice) +
        finalwsp +
        Number(this.state.data.others);
      data["wsp"] = wsp;
      this.setState({ data });
    }
    if (
      this.state.data.markup != undefined &&
      event.target.name == "purchasePrice"
    ) {
      let name = "purchasePrice";
      let wsp = Number(this.state.data.others);
      console.log(Number(event.target.value));
      let v = event.target.value;
      wsp =
        Number(wsp) +
        Number(v) +
        (Number(event.target.value) * Number(this.state.data.markup)) / 100;

      let data = this.state.data;
      data[name] = event.target.value;
      data["wsp"] = Math.round(wsp);
      this.setState({ data });

      return true;
    }

    if (this.state.data.MOQ != undefined && event.target.name == "quantity") {
      totalBox = Number(event.target.value) / Number(this.state.data.MOQ);
      console.log(totalBox);
      let name = "totalBox";
      console.log(name);

      let data = this.state.data;
      data[event.target.name] = event.target.value;
      data[name] = totalBox;

      if (this.state.data.packagingType === "Box") {
        let totalPackageSet =
          Number(event.target.value) / Number(this.state.data.MOQ);

        if (this.state.data.packagingValue === "Collective") {
          data["noOfBoxes"] = Math.round(totalPackageSet);
          data["noOfPackets"] = 0;
        } else {
          totalPackageSet = Math.round(Number(event.target.value));
          data["noOfBoxes"] = Math.round(totalPackageSet);
          data["noOfPackets"] = 0;
        }
      } else if (this.state.data.packagingType === "Loose") {
        let totalPackageSet =
          Number(event.target.value) / Number(this.state.data["MOQ" + key]);

        if (this.state.data.packagingValue === "Collective") {
          data["noOfPackets"] = Math.round(totalPackageSet);
          data["noOfBoxes"] = 0;
        } else {
          totalPackageSet = Math.round(Number(event.target.value));
          data["noOfPackets"] = Math.round(totalPackageSet);
          data["noOfBoxes"] = 0;
        }
      }
      this.setState({ data });

      return true;
    }

    let data = this.state.data;

    data[event.target.name] = event.target.value;
    this.setState({ data });
  }
  handleBlur(e) {
    if (e.currentTarget.value === "0") e.currentTarget.value = "1";
  }
  handleKeypress(e) {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      } else if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  }
  onDateChange = (date, dateString) => {
    let data = this.state.data;
    console.log("dateString", dateString);
    data["billDate"] = dateString;
    this.setState({ data });
  };
  handleChangeDealer = (key, value) => {
    let data = this.state.data;
    data["dealerId"] = value.value;
    data["dealerName"] = value.name[0];
    data["gst"] = value.name[1];
    this.setState({ data });
  };
  handleChangeBrand = (key, value) => {
    console.log("##key, value", key, value);
    let data = this.state.data;
    data["brandId"] = value.value;
    data["brand"] = value.name;
    this.setState({ data });
  };
  handleChangeCategory = (key, value) => {
    let data = this.state.data;
    data["categoryId"] = value.value;
    data["category"] = value.name;
    data["styleId"] = "";
    data["style"] = "";
    this.formRef.current.setFieldsValue({
      style: "",
    });
    this.setState({ data });
  };
  handleChangeGroup = (key, value) => {
    let data = this.state.data;
    data["groupId"] = value.value;
    data["group"] = value.name;
    this.setState({ data });
  };
  handleChangeStyle = (key, value) => {
    let data = this.state.data;
    data["styleId"] = value.value;
    data["style"] = value.name;
    this.setState({ data });
  };
  handleChangeSeason = (key, value) => {
    let data = this.state.data;
    data["seasonId"] = value.value;
    data["season"] = value.name;
    this.setState({ data });
  };
  handleChangeSection = (key, value) => {
    let data = this.state.data;
    data["sectionId"] = value.value;
    data["section"] = value.name;
    this.setState({ data });
  };
  handleChangeLocation = (key, value) => {
    let data = this.state.data;
    data["locationId"] = value.value;
    data["location"] = value.name;
    this.setState({ data });
  };
  handleChangeSize = (key, value) => {
    let data = this.state.data;
    data["size"] = value.name;
    this.setState({ data });
  };
  handleChangeColor = (key, value) => {
    let data = this.state.data;
    data["color"] = value.name;
    this.setState({ data });
  };
  handleChangeFloor = (key, value) => {
    let data = this.state.data;
    data["floorId"] = value.value;
    data["floorName"] = value.name;
    this.setState({ data });
  };
  handleChangeTexture = (key, value) => {
    let finalTextures = [];
    let finalTextureIds = [];

    key.forEach((item) => {
      const isId = item.length === 24;

      if (isId) {
        const textureName = this.getTextureNameById(item);
        if (textureName) {
          if (!finalTextureIds.includes(item)) {
            finalTextures.push({ name: textureName, id: item });
            finalTextureIds.push(item);
          }
        } else {
          const Id = this.getTextureIdByName(item);
          if (!finalTextureIds.includes(Id)) {
            finalTextures.push({ name: item, id: Id });
            finalTextureIds.push(Id);
          }
        }
      } else {
        const correspondingId = this.getTextureIdByName(item);
        if (correspondingId) {
          if (!finalTextureIds.includes(correspondingId)) {
            finalTextures.push({ name: item, id: correspondingId });
            finalTextureIds.push(correspondingId);
          }
        }
      }
    });

    let data = this.state.data;
    data["textureName"] = finalTextures;
    data["textureId"] = finalTextureIds;

    this.setState({ data });
  };

  getTextureNameById = (id) => {
    const texture = this.props?.textures?.find((option) => option._id === id);
    return texture ? texture.name : null;
  };

  getTextureIdByName = (name) => {
    const texture = this.props?.textures?.find(
      (option) => option.name.toLowerCase() === name.toLowerCase()
    );
    return texture ? texture._id : null;
  };
  handleChangeAgeGroup = (key, value) => {
    let data = this.state.data;
    data["ageGroupId"] = value.value;
    data["ageGroupName"] = value.name;
    this.setState({ data });
  };

  handleChangePacakagingType = (key, value) => {
    let data = this.state.data;
    data["packagingType"] = value.value;
    let noOfBoxes = 0;
    let noOfPackets = 0;
    if (this.state.data.quantity && this.state.data.MOQ) {
      if (value.value === "Box") {
        let totalPackageSet =
          Number(this.state.data.quantity) / Number(this.state.data.MOQ);

        if (this.state.data.packagingValue === "Collective") {
          noOfBoxes = Math.round(totalPackageSet);
          noOfPackets = 0;
        } else {
          totalPackageSet = Math.round(Number(this.state.data.quantity));
          noOfBoxes = Math.round(totalPackageSet);
          noOfPackets = 0;
        }
      } else if (value.value === "Loose") {
        let totalPackageSet =
          Number(this.state.data.quantity) / Number(this.state.data.MOQ);
        if (value.value === "Collective") {
          noOfPackets = Math.round(totalPackageSet);
          noOfBoxes = 0;
        } else {
          totalPackageSet = Math.round(Number(this.state.data.quantity));
          noOfPackets = Math.round(totalPackageSet);
          noOfBoxes = 0;
        }
      }
      data["noOfBoxes"] = noOfBoxes;
      data["noOfPackets"] = noOfPackets;
    }
    this.setState({ data });
  };

  handleChangePacakagingValue = (key, value) => {
    let data = this.state.data;
    data["packagingValue"] = value.value;
    let noOfBoxes = 0;
    let noOfPackets = 0;
    if (this.state.data.quantity && this.state.data.MOQ) {
      if (this.state.data.packagingType === "Box") {
        let totalPackageSet =
          Number(this.state.data.quantity) / Number(this.state.data.MOQ);

        if (value.value === "Collective") {
          noOfBoxes = Math.round(totalPackageSet);
          noOfPackets = 0;
        } else {
          totalPackageSet = Math.round(Number(this.state.data.quantity));
          noOfBoxes = Math.round(totalPackageSet);
          noOfPackets = 0;
        }
      } else if (this.state.data.packagingType === "Loose") {
        let totalPackageSet =
          Number(this.state.data.quantity) / Number(this.state.data.MOQ);
        if (value.value === "Collective") {
          noOfPackets = Math.round(totalPackageSet);
          noOfBoxes = 0;
        } else {
          totalPackageSet = Math.round(Number(this.state.data.quantity));
          noOfPackets = Math.round(totalPackageSet);
          noOfBoxes = 0;
        }
      }
      data["noOfBoxes"] = noOfBoxes;
      data["noOfPackets"] = noOfPackets;
    }
    this.setState({ data });
  };

  getData = (name) => {
    if (name == "dealer") this.props.getDealers();
    if (name == "brand") this.props.getActiveInactiveBrands();
    if (name == "category") this.props.getCategories();
    if (name == "group") this.props.getGroups();
    if (name == "style") {
      if (this.state.data.categoryId != "") {
        this.props.getStyles(this.state.data.categoryId);
      } else {
        message.warning("Please Select Category");
      }
    }
    if (name == "season") this.props.getSeasons();
    if (name == "section") this.props.getSections();
    if (name == "location") this.props.getLocations();
    if (name == "size") this.props.getSizes();
    if (name == "color") this.props.getColors();
    if (name == "floor") this.props.getFloors();
    if (name == "texture") this.props.getTexture();
    if (name == "ageGroup") this.props.getAgeGroups();
    if (name == "packagingType") this.props.getPackagingMethods();
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleModalCancel = () => {
    if (!this.state.data.styleId || !this.state.data.style) {
      let data = this.state.data;
      data["styleId"] = this.props.updateData.styleId;
      data["style"] = this.props.updateData.style;
      this.setState({ data });
    }
    this.setState({ visible: false });
  };

  handleChageSalesTax =  (key, value) => {
    let data = this.state.data;
    data["salesTax"] = value.value;
    this.setState({ data });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (
      prevProps.updateData._id !== this.state.data._id &&
      this.formRef.current
    ) {
      this.setState({
        packagingType: prevProps.updateData.packagingType,
        packagingValue: prevProps.updateData.packagingValue,
      });
      let data = this.state.data;
      const textureName = prevProps.updateData.textureName;
      const textureId = prevProps.updateData.textureId;
      if (Array.isArray(textureName)) {
        data["textureName"] = textureName;
        data["textureId"] = textureId;
      } else {
        data["textureName"] = [
          {
            name: textureName,
            id: textureId,
          },
        ];
        data["textureId"] = [textureId];
      }
      data["barcode"] = prevProps.updateData.barcode;
      data["_id"] = prevProps.updateData._id;
      data["gst"] = prevProps.updateData.gst;
      data["markup"] = prevProps.updateData.markup;
      data["brand"] = prevProps.updateData.brand;
      data["brandId"] = prevProps.updateData.brandId;
      data["category"] = prevProps.updateData.category;
      data["categoryId"] = prevProps.updateData.categoryId;
      data["group"] = prevProps.updateData.group;
      data["groupId"] = prevProps.updateData.groupId;
      data["style"] = prevProps.updateData.style;
      data["styleId"] = prevProps.updateData.styleId;
      data["season"] = prevProps.updateData.season;
      data["seasonId"] = prevProps.updateData.seasonId;
      data["section"] = prevProps.updateData.section;
      data["sectionId"] = prevProps.updateData.sectionId;
      data["location"] = prevProps.updateData.location;
      data["locationId"] = prevProps.updateData.locationId;
      data["others"] = prevProps.updateData.others;
      data["MOQ"] = prevProps.updateData.MOQ;
      data["HSNCode"] = prevProps.updateData.HSNCode;
      data["designNumber"] = prevProps.updateData.designNumber;
      data["size"] = prevProps.updateData.size;
      // data["sizeId"] = prevProps.updateData.sizeId;
      data["color"] = prevProps.updateData.color;
      // data["colorId"] = prevProps.updateData.colorId;
      data["purchasePrice"] = prevProps.updateData.purchasePrice;
      data["wsp"] = prevProps.updateData.wsp;
      data["quantity"] = prevProps.updateData.quantity;
      data["totalBox"] = prevProps.updateData.totalBox;
      data["discount"] = prevProps.updateData.discount;
      data["floorName"] = prevProps.updateData.floorName;
      data["floorId"] = prevProps.updateData.floorId;
      data["ageGroupName"] = prevProps.updateData.ageGroupName;
      data["ageGroupId"] = prevProps.updateData.ageGroupId;
      data["ecommProductVisible"] = prevProps.updateData.ecommProductVisible;
      data["generated"] = prevProps.updateData.generated;
      data["purchaseId"] = prevProps.updateData.purchaseId;
      data["sales"] = prevProps.updateData.sales;
      data["salesDiscount"] = prevProps.updateData.salesDiscount;
      data["__v"] = prevProps.updateData.__v;
      data["textureName"] = prevProps.updateData.textureName;
      data["textureId"] = prevProps.updateData.textureId;
      data["created_at"] = prevProps.updateData.created_at;
      data["salesTax"] = prevProps.updateData.salesTax;
      data["packagingType"] = prevProps.updateData.packagingType;
      data["packagingValue"] = prevProps.updateData.packagingValue;
      data["noOfBoxes"] = prevProps.updateData.noOfBoxes;
      data["noOfPackets"] = prevProps.updateData.noOfPackets;
      // data["boxSet"] = prevProps.updateData.boxSet;
      let size = prevProps.updateData.size;
      let color = prevProps.updateData.color;
      let users = [{ size, colors: [{ color }] }];

      const textureNameForm = this.props.updateData?.textureName;
      const texture = [];
      if (Array.isArray(textureNameForm)) {
        // If it's an array, map over it to get the names
        texture.push(...textureNameForm.map((texture) => texture?.name));
      } else if (typeof textureNameForm === "string") {
        // If it's a string, wrap it in an array
        texture.push(textureNameForm);
      }

      this.formRef.current.setFieldsValue({
        brand: prevProps.updateData.brand,
        category: prevProps.updateData.category,
        style: prevProps.updateData.style,
        group: prevProps.updateData.group,
        season: prevProps.updateData.season,
        section: prevProps.updateData.section,
        location: prevProps.updateData.location,
        floor: prevProps.updateData.floorName,
        texture: texture,
        salesTax : prevProps.updateData.salesTax,
        ageGroup: prevProps.updateData.ageGroupName,
        packagingType: prevProps.updateData.packagingType,
        packagingValue: prevProps.updateData.packagingValue,
        noOfBoxes: prevProps.updateData.noOfBoxes,
        noOfPackets: prevProps.updateData.noOfPackets,
        users,
      });
      this.setState({
        data,
      });
      return true;
    }
    return true;
  };


validateInputField = (value, name) => {
    if (!value || value.trim() === '') {
      message.error(`${name} cannot be empty or only spaces!`);
      return true; 
    }
    if (/^[\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/.test(value)) {
      message.error(`${name} cannot be only special characters`);
      return true;
    }
    if (/^\s|\s$/.test(value)) {
      message.error(`${name} cannot have spaces before or after`);
      return true;
    }
    if (/\s{2,}/.test(value)) {
      message.error(`${name} cannot have double spaces`);
      return true;
    }
    
    // If all validations pass, return false
    return false;
  }


  async onSubmit() {
        // Validate Value in Design No
        let chackDesignNoValidate = this.validateInputField(this.state.data.designNumber, "Design No");
        if(chackDesignNoValidate){
          return
        }
    // ----------------------
    // let updatedPurchase = {};
    // updatedPurchase["_id"] = this.props.updateData._id;
    // updatedPurchase["purchaseId"] = this.props.updateData.purchaseId;
    // if (this.state.data.brandId !== this.props.updateData.brandId) {
    //   updatedPurchase["brandId"] = this.state.data.brandId;
    //   updatedPurchase["brand"] = this.state.data.brand;
    // }
    // if (this.state.data.locationId !== this.props.updateData.locationId) {
    //   updatedPurchase["locationId"] = this.state.data.locationId;
    //   updatedPurchase["location"] = this.state.data.location;
    // }
    // if (this.state.data.sectionId !== this.props.updateData.sectionId) {
    //   updatedPurchase["sectionId"] = this.state.data.sectionId;
    //   updatedPurchase["section"] = this.state.data.section;
    // }
    // if (this.state.data.seasonId !== this.props.updateData.seasonId) {
    //   updatedPurchase["seasonId"] = this.state.data.seasonId;
    //   updatedPurchase["season"] = this.state.data.season;
    // }
    // if (this.state.data.floorId !== this.props.updateData.floorId) {
    //   updatedPurchase["floorId"] = this.state.data.floorId;
    //   updatedPurchase["floorName"] = this.state.data.floorName;
    // }
    // if (this.state.data.ageGroupId !== this.props.updateData.ageGroupId) {
    //   updatedPurchase["ageGroupId"] = this.state.data.ageGroupId;
    //   updatedPurchase["ageGroupName"] = this.state.data.ageGroupName;
    // }
    // if (this.state.data.categoryId !== this.props.updateData.categoryId) {
    //   updatedPurchase["categoryId"] = this.state.data.categoryId;
    //   updatedPurchase["category"] = this.state.data.category;
    // }
    // if (this.state.data.groupId !== this.props.updateData.groupId) {
    //   updatedPurchase["groupId"] = this.state.data.groupId;
    //   updatedPurchase["group"] = this.state.data.groupName;
    // }
    // if (this.state.data.styleId !== this.props.updateData.styleId) {
    //   updatedPurchase["styleId"] = this.state.data.styleId;
    //   updatedPurchase["style"] = this.state.data.style;
    // }
    // if (this.state.data.HSNCode !== this.props.updateData.HSNCode) {
    //   updatedPurchase["HSNCode"] = this.state.data.HSNCode;
    // }
    // if (this.state.data.MOQ !== this.props.updateData.MOQ) {
    //   updatedPurchase["MOQ"] = this.state.data.MOQ;
    // }
    // if (this.state.data.billDate !== this.props.updateData.billDate) {
    //   updatedPurchase["billDate"] = this.state.data.billDate;
    // }
    // if (this.state.data.color !== this.props.updateData.color) {
    //   updatedPurchase["color"] = this.state.data.color;
    // }
    // if (this.state.data.size !== this.props.updateData.size) {
    //   updatedPurchase["size"] = this.state.data.size;
    // }
    // if (this.state.data.designNumber !== this.props.updateData.designNumber) {
    //   updatedPurchase["designNumber"] = this.state.data.designNumber;
    // }
    // if (this.state.data.discount !== this.props.updateData.discount) {
    //   updatedPurchase["discount"] = this.state.data.discount;
    // }
    // if (this.state.data.markup !== this.props.updateData.markup) {
    //   updatedPurchase["markup"] = this.state.data.markup;
    // }
    // if (this.state.data.others !== this.props.updateData.others) {
    //   updatedPurchase["others"] = this.state.data.others;
    // }
    // if (this.state.data.purchasePrice !== this.props.updateData.purchasePrice) {
    //   updatedPurchase["purchasePrice"] = this.state.data.purchasePrice;
    // }
    // if (this.state.data.quantity !== this.props.updateData.quantity) {
    //   updatedPurchase["quantity"] = this.state.data.quantity;
    // }
    // if (this.state.data.totalBox !== this.props.updateData.totalBox) {
    //   updatedPurchase["totalBox"] = this.state.data.totalBox;
    // }
    // if (this.state.data.wsp !== this.props.updateData.wsp) {
    //   updatedPurchase["wsp"] = this.state.data.wsp;
    // }
    if (
      this.state.data.quantity &&
      this.state.data.quantity > 0 &&
      Number(this.state.data.quantity) % Number(this.state.data.MOQ) === 0
    ) {
      this.props.updateObj(this.state.data);
      this.setState({ visible: false });
    } else {
      message.error("Quantity Must Be In Multiple Of MOQ");
    }
    // this.props.updateObj(this.state.data)
    // this.setState({ visible: false });

    // await this.props.updatePurchase(updatedPurchase);
    // setTimeout(() => {
    //   this.setState({ loading: false, visible: false });
    // }, 2000);
  }

  render() {
    const { visible, loading } = this.state;
    const { roleId } = this.props;

    let dealerOptions = this.props.dealers.map((dealer) => {
      return (
        <Option name={[dealer.name, dealer.gst]} value={dealer._id}>
          {dealer.name}
        </Option>
      );
    });
    let brandOptions = this.props?.brands?.data?.map((brand) => {
      return (
        <Option name={brand.name} value={brand._id}>
          {brand.name}
        </Option>
      );
    });
    let categoryOptions = this.props.categories.map((category) => {
      return (
        <Option name={category.name} value={category._id}>
          {category.name}
        </Option>
      );
    });
    let groupOptions = this.props.groups.map((group) => {
      return (
        <Option name={group.name} value={group._id}>
          {group.name}
        </Option>
      );
    });
    let styleOptions = this.props.styles.map((style) => {
      return (
        <Option name={style.name} value={style._id}>
          {style.name}
        </Option>
      );
    });
    let seasonOptions = this.props.seasons.map((season) => {
      return (
        <Option name={season.name} value={season._id}>
          {season.name}
        </Option>
      );
    });
    let sectionOptions = this.props.sections.map((section) => {
      return (
        <Option name={section.name} value={section._id}>
          {section.name}
        </Option>
      );
    });
    let locationOptions = this.props.locations.map((location) => {
      return (
        <Option name={location.name} value={location._id}>
          {location.name}
        </Option>
      );
    });
    let sizeOptions = this.props.sizes.map((size) => {
      return (
        <Option name={size.name} value={size._id} key={size._id}>
          {size.name}
        </Option>
      );
    });
    let colorOptions = this.props.colors.map((color) => {
      return (
        <Option name={color.name} value={color._id}>
          {color.name}
        </Option>
      );
    });

    let floorOptions = this.props.floors.map((floor) => {
      return (
        <Option name={floor.name} value={floor._id}>
          {floor.name}
        </Option>
      );
    });

    let textureOptions = this.props.textures?.map((texture) => {
      return (
        <Option name={texture.name} value={texture._id}>
          {texture.name}
        </Option>
      );
    });

    let ageGroupOptions = this.props.ageGroups.map((ageGroup) => {
      return (
        <Option name={ageGroup.name} value={ageGroup._id}>
          {ageGroup.name}
        </Option>
      );
    });

    let packagingTypeOptions = this.props.packagingMethods
      .filter(
        (type, index, array) =>
          array.findIndex((t) => t.packagingType === type.packagingType) ===
          index
      )
      .map((type) => (
        <Option key={type._id} value={type.packagingType}>
          {type.packagingType}
        </Option>
      ));

    let packagingValueOptions = this.props.packagingMethods
      .filter(
        (type, index, array) =>
          array.findIndex((t) => t.packagingValue === type.packagingValue) ===
          index
      )
      .map((type) => (
        <Option key={type._id} value={type.packagingValue}>
          {type.packagingValue}
        </Option>
      ));

    const initialValues = {
      users: [
        { size: undefined, colors: [undefined] }, // undefined will render the placeholder
      ],
    };

    var disabled = false;
    if (this.state.data.donedone) {
      disabled = true;
    }
    return (
      <>
        <button
          className="btn"
          type="button"
          data-original-title=""
          onClick={this.showModal}
        >
          <Tooltip title="Update Purchase">
            <EditOutlined />
          </Tooltip>
        </button>
        <Modal
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleModalCancel}
          title="Update Purchase"
          style={{ top: 20 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          width={1300}
        >
          <Form
            ref={this.formRef}
            name="add-Purchase"
            className="add-Purchase"
            onFinish={this.onSubmit}
            initialValues={initialValues}
          >
            {console.log("this.state.data", this.state.data)}
            <Row gutter={[8, 0]}>
              {/* <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="Select Dealer">
                  <Form.Item>
                    <Input
                      placeholder="Dealer"
                      name="dealerName"
                      value={this.state.data.dealerName}
                      disabled
                    />
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="Enter Bill Number">
                  <Form.Item>
                    <Input
                      placeholder="Enter Bill Number"
                      name="billNo"
                      value={this.state.data.billNo}
                      onChange={this.onChange}
                      disabled
                    />
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="Select Bill Date">
                  <Form.Item name="billDate">
                    {console.log(
                      "moment()",
                      moment(new Date()).format("DD/MM/YYYY")
                    )}
                    <DatePicker
                      style={{ width: "100%" }}
                      disabled={disabled}
                      onChange={this.onDateChange}
                      placeholder="Select Bill Date"
                      defaultValue={moment()}
                      format={dateFormat}
                    />
                  </Form.Item>
                </Tooltip>
              </Col> */}
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="GST">
                  <Form.Item>
                    <Input
                      id="gst"
                      placeholder="GST Number"
                      name="gst"
                      value={
                        this.props.gstNo
                          ? this.props.gstNo
                          : this.state.data.gst
                      }
                      onChange={this.onChange}
                      disabled
                    />
                  </Form.Item>
                </Tooltip>
              </Col>
            </Row>
            <Row gutter={[8, 0]}>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="Enter Design Number">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Design Number !",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Design Number"
                      id="designNumber"
                      name="designNumber"
                      value={this.state.data.designNumber}
                      onChange={this.onChange}
                      required
                    />
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="Select Brand">
                  <Form.Item
                    name="brand"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please Select Brand Name !",
                    //   },
                    // ]}
                  >
                    <Select
                      // name="brand"
                      // id="brand"
                      showSearch
                      disabled={disabled}
                      placeholder="Select Brand"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      onChange={this.handleChangeBrand}
                      onClick={() => this.getData("brand")}
                      onSelect={this.showLookup}
                      onInputKeyDown={() => this.getData("brand")}
                    >
                      {brandOptions}
                    </Select>
                  </Form.Item>
                </Tooltip>
              </Col>

              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="Enter Markup">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Markup !",
                      },
                    ]}
                  >
                    <Input
                      disabled={disabled}
                      placeholder="Enter Markup"
                      name="markup"
                      id="markup"
                      value={this.state.data.markup}
                      onChange={this.onChange}
                      required
                    />
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="Select Category">
                  <Form.Item
                    name="category"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Category Name !",
                      },
                    ]}
                  >
                    <Select
                      disabled={disabled}
                      showSearch
                      placeholder="Select Category"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      onChange={this.handleChangeCategory}
                      onClick={() => this.getData("category")}
                      onInputKeyDown={() => this.getData("category")}
                    >
                      {categoryOptions}
                    </Select>
                  </Form.Item>
                </Tooltip>
              </Col>
            </Row>
            <Row gutter={[8, 0]}>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="Select Style">
                  <Form.Item
                    name="style"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Style Name !",
                      },
                    ]}
                  >
                    <Select
                      disabled={disabled}
                      // name="style"
                      // id="style"
                      showSearch
                      placeholder="Select Style"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      onChange={this.handleChangeStyle}
                      onClick={() => this.getData("style")}
                      onInputKeyDown={() => this.getData("style")}
                    >
                      {styleOptions}
                    </Select>
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="Select Group">
                  <Form.Item
                    name="group"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Group Name !",
                      },
                    ]}
                  >
                    <Select
                      disabled={disabled}
                      showSearch
                      placeholder="Select Group"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      onChange={this.handleChangeGroup}
                      onClick={() => this.getData("group")}
                      onInputKeyDown={() => this.getData("group")}
                    >
                      {groupOptions}
                    </Select>
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="Select Season">
                  <Form.Item
                    name="season"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Season Name !",
                      },
                    ]}
                  >
                    <Select
                      disabled={disabled}
                      showSearch
                      placeholder="Select Season"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      onChange={this.handleChangeSeason}
                      onClick={() => this.getData("season")}
                      onInputKeyDown={() => this.getData("season")}
                    >
                      {seasonOptions}
                    </Select>
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="Select Section">
                  <Form.Item
                    name="section"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Section Name !",
                      },
                    ]}
                  >
                    <Select
                      disabled={disabled}
                      showSearch
                      placeholder="Select Section"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      onChange={this.handleChangeSection}
                      onClick={() => this.getData("section")}
                      onInputKeyDown={() => this.getData("section")}
                    >
                      {sectionOptions}
                    </Select>
                  </Form.Item>
                </Tooltip>
              </Col>
            </Row>
            {console.log("check changes update")}
            <Row gutter={[8, 0]}>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="Enter HSNCode">
                  <Form.Item>
                    <Input
                      disabled={disabled}
                      placeholder="Enter HSN Code"
                      name="HSNCode"
                      value={this.state.data.HSNCode}
                      onChange={this.onChange}
                      required
                      type="number"
                      // onKeyDown={this.handleKeypress}
                      onBlur={this.handleBlur}
                      min="0"
                      step="1"
                    />
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="Enter Others">
                  <Form.Item
                  // name="others"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Enter Others !",
                  //   },
                  // ]}
                  >
                    <Input
                      disabled={disabled}
                      type="number"
                      placeholder="Enter Others"
                      name="others"
                      // defaultValue={2}
                      value={this.state.data.others}
                      onChange={this.onChange}
                      min="0"
                    />
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="Select Locaton">
                  <Form.Item
                    name="location"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Location Name !",
                      },
                    ]}
                  >
                    <Select
                      disabled={disabled}
                      showSearch
                      placeholder="Select Location"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      onChange={this.handleChangeLocation}
                      onClick={() => this.getData("location")}
                      onInputKeyDown={() => this.getData("location")}
                    >
                      {locationOptions}
                    </Select>
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="Enter Discount">
                  <Form.Item>
                    <Input
                      addonBefore="%"
                      disabled={disabled}
                      type="number"
                      // suffix="%"
                      placeholder="Enter Discount (%)"
                      name="discount"
                      value={this.state.data.discount}
                      onChange={this.onChange}
                      required
                    />
                  </Form.Item>
                </Tooltip>
              </Col>
            </Row>
            <Row gutter={[8, 0]}>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="Select Floor">
                  <Form.Item
                    name="floor"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Floor Name !",
                      },
                    ]}
                  >
                    <Select
                      disabled={disabled}
                      showSearch
                      placeholder="Select Floor"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      onChange={this.handleChangeFloor}
                      onClick={() => this.getData("floor")}
                      onInputKeyDown={() => this.getData("floor")}
                    >
                      {floorOptions}
                    </Select>
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                {console.log("check textttttt", this.state)}
                <Tooltip title="Select Texture">
                  <Form.Item
                    name="texture"
                    // rules={[
                    //   {
                    //     // required: true,
                    //     // message: "Please Select Texture Name !",
                    //   },
                    // ]}
                  >
                    <Select
                      mode="multiple"
                      disabled={disabled}
                      showSearch
                      placeholder="Select Texture"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      onChange={this.handleChangeTexture}
                      onClick={() => this.getData("texture")}
                      onInputKeyDown={() => this.getData("texture")}
                    >
                      {textureOptions}
                    </Select>
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="Select Age Group">
                  <Form.Item
                    name="ageGroup"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Age Group Name !",
                      },
                    ]}
                  >
                    <Select
                      disabled={disabled}
                      showSearch
                      placeholder="Select Age Group"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      onChange={this.handleChangeAgeGroup}
                      onClick={() => this.getData("ageGroup")}
                      onInputKeyDown={() => this.getData("ageGroup")}
                    >
                      {ageGroupOptions}
                    </Select>
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="Select Packaging Type">
                  <Form.Item
                    name="packagingType"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Packaging Type !",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Packaging Type"
                      onChange={this.handleChangePacakagingType}
                      onClick={() => this.getData("packagingType")}
                      onInputKeyDown={() => {
                        this.getData("packagingType");
                      }}
                    >
                      {packagingTypeOptions}
                    </Select>
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="Select Packaging Value">
                  <Form.Item
                    name="packagingValue"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Packaging Value !",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Packaging Value"
                      onChange={this.handleChangePacakagingValue}
                      onClick={() => this.getData("packagingType")}
                      onInputKeyDown={() => {
                        this.getData("packagingType");
                      }}
                    >
                      {packagingValueOptions}
                    </Select>
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Tooltip title="Enter Sales Tax">
                  <Form.Item
                    // label="Sale Tax"
                    // name="salesTax"
                    rules={[
                      {
                        required: true,
                        message: "Please select Sale Tax!",
                      },
                    ]}
                  >
                    <Select
                      name="salesTax"
                      placeholder="Select Sale Tax"
                      value={this.state.data.salesTax}
                      onChange={this.handleChageSalesTax}
                      disabled={disabled}
                    >
                      <Option value={5}>5%</Option>
                      <Option value={12}>12%</Option>
                      <Option value={18}>18%</Option>
                    </Select>
                  </Form.Item>
                  {/* <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Sales Tax !",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Sales Tax"
                      type="number"
                      id="salesTax"
                      name="salesTax"
                      min={1}
                      value={this.state.data.salesTax}
                      onChange={this.onChange}
                      required
                    />
                  </Form.Item> */}
                </Tooltip>
              </Col>
            </Row>
            {console.log("check moht data", this.state.data.packagingTypeName)}

            <Form.List name="users" id="users">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <span name="done_dana_done">
                      <Row gutter={[8, 0]} key={field.key}>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Tooltip title="Select Size">
                            <Form.Item
                              {...field}
                              fieldKey={[field.fieldKey, "size"]}
                              name={[field.name, "size"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Size !",
                                },
                              ]}
                            >
                              <Select
                                // id="467396"
                                name="size"
                                showSearch
                                placeholder="Select Size"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeSize}
                                onClick={() => this.getData("size")}
                                onInputKeyDown={() => this.getData("size")}
                              >
                                {sizeOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={11} sm={11} md={6} lg={6} xl={6}>
                          <Tooltip title="Enter Purchase Price">
                            <Form.Item
                              // {...field}
                              // fieldKey={[field.fieldKey, "purchasePrice"]}
                              // name={[field.name, "purchasePrice"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Purchase Price !",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Price"
                                name="purchasePrice"
                                value={this.state.data["purchasePrice"]}
                                onChange={this.onChange}
                                type="number"
                                min="0"
                                step={0.1}
                                required
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Tooltip title="Enter MOQ">
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter MOQ !",
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                disabled={
                                  roleId == "629346b53e2dcbf2f6d53244"
                                    ? false
                                    : true
                                }
                                placeholder="Enter MOQ"
                                name="MOQ"
                                value={this.state.data["MOQ"]}
                                onChange={this.onChange}
                                // onKeyDown={this.handleKeypress}
                                onBlur={this.handleBlur}
                                min="1"
                                step="1"
                                required
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Tooltip title="WSP">
                            <Form.Item>
                              <Input
                                placeholder="WSP"
                                name={"wsp"}
                                id={"wsp"}
                                value={this.state.data["wsp"]}
                                onChange={this.onChange}
                                disabled
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>
                      <Form.List name={[field.name, "colors"]}>
                        {(colors, { add, remove }) => {
                          return (
                            <span>
                              {colors.map(
                                (color) => (
                                  console.log("keyyyy lagoo", color.key),
                                  (
                                    <Row
                                      gutter={[8, 0]}
                                      key={color.key}
                                      style={{
                                        marginLeft: "35px",
                                      }}
                                    >
                                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        {" "}
                                        <Tooltip title="Select Color">
                                          <Form.Item
                                            {...color}
                                            fieldKey={[color.fieldKey, "color"]}
                                            name={[color.name, "color"]}
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Please Select Color !",
                                              },
                                            ]}
                                          >
                                            <Select
                                              id={color.key}
                                              showSearch
                                              placeholder="Select Color"
                                              optionFilterProp="children"
                                              name={"defaultColor" + field.key}
                                              filterOption={(input, option) =>
                                                option.children
                                                  .toLowerCase()
                                                  .indexOf(
                                                    input.toLowerCase()
                                                  ) >= 0
                                              }
                                              filterSort={(optionA, optionB) =>
                                                optionA.children
                                                  .toLowerCase()
                                                  .localeCompare(
                                                    optionB.children.toLowerCase()
                                                  )
                                              }
                                              onChange={this.handleChangeColor}
                                              onClick={() =>
                                                this.getData("color")
                                              }
                                              onInputKeyDown={() =>
                                                this.getData("color")
                                              }
                                            >
                                              {colorOptions}
                                            </Select>
                                          </Form.Item>
                                        </Tooltip>
                                      </Col>
                                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        {" "}
                                        <Tooltip title="Enter Quantity">
                                          <Form.Item
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Please Enter Quantity !",
                                              },
                                            ]}
                                          >
                                            <Input
                                              placeholder="Quantity"
                                              name={"quantity"}
                                              id="quantity"
                                              value={
                                                this.state.data["quantity"]
                                              }
                                              // onChange={(e) =>
                                              //   this.onChange(
                                              //     e,
                                              //     field.key,
                                              //     "" +
                                              //       field.key +
                                              //       " " +
                                              //       color.key
                                              //   )
                                              // }
                                              onChange={this.onChange}
                                              type="number"
                                              min="0"
                                              step="1"
                                            />
                                          </Form.Item>
                                        </Tooltip>
                                      </Col>
                                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Tooltip title="Sets">
                                          <Form.Item>
                                            <Input
                                              type="number"
                                              placeholder="Sets"
                                              name={"totalBox"}
                                              id={"totalBox"}
                                              value={
                                                this.state.data["totalBox"]
                                              }
                                              onChange={this.onChange}
                                              disabled
                                            />
                                          </Form.Item>
                                        </Tooltip>
                                      </Col>
                                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Tooltip title="No Of Boxes">
                                          <Form.Item>
                                            <Input
                                              type="number"
                                              placeholder="No Of Boxes"
                                              name={"noOfBoxes"}
                                              id={"noOfBoxes"}
                                              value={
                                                this.state.data["noOfBoxes"]
                                              }
                                              onChange={this.onChange}
                                              disabled
                                            />
                                          </Form.Item>
                                        </Tooltip>
                                      </Col>
                                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Tooltip title="No Of Packets">
                                          <Form.Item>
                                            <Input
                                              type="number"
                                              placeholder="No Of Packets"
                                              name={"noOfPackets"}
                                              id={"noOfPackets"}
                                              value={
                                                this.state.data["noOfPackets"]
                                              }
                                              onChange={this.onChange}
                                              disabled
                                            />
                                          </Form.Item>
                                        </Tooltip>
                                      </Col>
                                      {/* <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                        {" "}
                                        <Tooltip title="Enter Box Set">
                                          <Form.Item
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Please Enter Box Set!",
                                              },
                                            ]}
                                          >
                                            <Input
                                              placeholder="Box Set"
                                              name={"boxSet"}
                                              id="boxSet"
                                              value={
                                                this.state.data["boxSet"]
                                              }
                                              // onChange={(e) =>
                                              //   this.onChange(
                                              //     e,
                                              //     field.key,
                                              //     "" +
                                              //       field.key +
                                              //       " " +
                                              //       color.key
                                              //   )
                                              // }
                                              onChange={this.onChange}
                                              required  
                                              type="number"
                                              min="0"
                                              step="1"
                                            />
                                          </Form.Item>
                                        </Tooltip>
                                      </Col> */}
                                    </Row>
                                  )
                                )
                              )}
                            </span>
                          );
                        }}
                      </Form.List>
                    </span>
                  ))}
                </>
              )}
            </Form.List>

            <Form.Item className="float-right">
              <Button
                className="close-modal mr-3"
                onClick={this.handleModalCancel}
              >
                Close
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="ok-modal"
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}
UpdatePurchase.propTypes = {
  getDealers: PropTypes.func.isRequired,
  getActiveInactiveBrands: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  getGroups: PropTypes.func.isRequired,
  getStyles: PropTypes.func.isRequired,
  getSeasons: PropTypes.func.isRequired,
  getSections: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,
  getFloors: PropTypes.func.isRequired,
  getTexture: PropTypes.func.isRequired,
  getAgeGroups: PropTypes.func.isRequired,
  updatePurchase: PropTypes.func.isRequired,
  getLookupData: PropTypes.func.isRequired,
  getPackagingMethod: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dealers: state.dealersData.dealers,
  sections: state.sectionData.sections,
  seasons: state.seasonData.seasons,
  locations: state.locationData.locations,
  groups: state.groupData.groups,
  colors: state.colorData.colors,
  categories: state.categoryData.categories,
  brands: state.brandsData.brands,
  styles: state.styleData.styles,
  sizes: state.sizeData.sizes,
  floors: state.floorData.floors,
  textures: state.textureData.textures.data,
  ageGroups: state.ageGroupData.ageGroups,
  purchases: state.purchaseData.purchases,
  packagingMethods: state.packageMethodReducer.packageMethodList,
});

export default connect(mapStateToProps, {
  getDealers,
  getActiveInactiveBrands,
  getCategories,
  getGroups,
  getStyles,
  getSeasons,
  getSections,
  getLocations,
  getSizes,
  getColors,
  getAgeGroups,
  getFloors,
  getTexture,
  updatePurchase,
  getLookupData,
  getPackagingMethod,
  getPackagingMethods,
})(UpdatePurchase);
