import React, { Component } from "react";
import {
  Table,
  Popconfirm,
  Tooltip,
  Pagination,
  Button,
  Space,
  Input,
  Row,
  Col,
} from "antd";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getPurchaseReturnBillList,
  getPurchaseReturnDataPurchaseId,
  getPurchaseReturnBillListBydate,
} from "../../../../../redux/actions/purchaseAction";
import Highlighter from "react-highlight-words";
import moment from "moment";
import ShowReturnBillPurchse from "./ShowReturnBillPurchse";
import Search from "../../../../../util/Search";
import DateWiseSearch from "../../../../../util/DateWiseSearch";
import TopBarProgress from "react-topbar-progress-indicator";
const { Column } = Table;
const searchOptions = ["Barcode", "PurchaseId", "DealerName", "Alias"];
class PurchaseReturnBillList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
      searchValue: "",
    };
  }
  componentDidMount() {
    if (this.props.history.location.state) {
      let localStartDate = localStorage.getItem("startDate");
      let localEnddate = localStorage.getItem("endDate");
      if (localStartDate && localEnddate) {
        let today = moment(new Date()).format("YYYY-MM-DD");
        if (today == localEnddate) {
          let reportObj = {
            startDate: localStartDate,
            endDate: localEnddate + " " + moment().format("HH:mm:ss"),
          };
          this.props.getPurchaseReturnBillListBydate(reportObj);
          localStorage.removeItem("startDate");
          localStorage.removeItem("endDate");
        } else {
          const dateMoment = moment(localEnddate, "YYYY-MM-DD");
          const endTime = dateMoment.endOf("day").format("HH:mm:ss");
          const endDateWithEndTime = `${localEnddate} ${endTime}`;
          let reportObj = {
            startDate: localStartDate,
            endDate: endDateWithEndTime,
          };
          this.props.getPurchaseReturnBillListBydate(reportObj);
          localStorage.removeItem("startDate");
          localStorage.removeItem("endDate");
        }
      } else {
        let startDate = moment(new Date()).format("YYYY-MM-DD");
        let endDate = moment(new Date()).format("YYYY-MM-DD");
        let reportObj = {
          startDate: startDate,
          endDate: endDate + " " + moment().format("HH:mm:ss"),
        };
        this.props.getPurchaseReturnBillListBydate(reportObj);
      }
    } else {
      localStorage.setItem(
        "startDate",
        moment(new Date()).format("YYYY-MM-DD")
      );
      localStorage.setItem("endDate", moment(new Date()).format("YYYY-MM-DD"));
      this.setState({
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
      });
      let startDate = moment(new Date()).format("YYYY-MM-DD");
      let endDate = moment(new Date()).format("YYYY-MM-DD");
      let reportObj = {
        startDate: startDate,
        endDate: endDate + " " + moment().format("HH:mm:ss"),
      };
      this.props.getPurchaseReturnBillListBydate(reportObj);
    }

    // let startDate = moment(new Date()).format("YYYY-MM-DD")
    // let endDate = moment(new Date()).format("YYYY-MM-DD")
    // let reportObj = {
    //   startDate: startDate,
    //   endDate: endDate + " " + moment().format("HH:mm:ss"),
    // };
    // this.props.getPurchaseReturnBillListBydate(reportObj)
    // this.props.getPurchaseReturnBillList(1);
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };
  editPurchaseReturn = async (text) => {
    await this.props.getPurchaseReturnDataPurchaseId(text.purchaseReturnBillId);
    this.props.history.push({
      pathname: "/dagaImpex/updatePurchaseReturn",
      state: {
        id: text._id,
        purchaseReturnObj: [text, this.props.purchaseReturnOrders],
      },
    });
  };

  onChange = (event) => {
    this.setState({ searchValue: event });
  };
  cancelState = () => {
    this.setState({ searchValue: "" });
  };

  listData = () => {
    const { purchaseBills, purchasereturnBillByDate, searchData } = this.props;
    const value =
      purchasereturnBillByDate && purchasereturnBillByDate.length > 0
        ? purchasereturnBillByDate[0]
        : [];
    // if(value.message == "Empty" ){
    //   return([])
    // }else if(this.state.searchValue){
    //   return(searchData.data)
    // }else{
    //   return(purchasereturnBillByDate.data)
    // }

    if (this.state.searchValue) {
      const sortedArray = searchData?.data?.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      return sortedArray;
    } else {
      return purchasereturnBillByDate.data;
    }
  };
  render() {
    const { searchValue } = this.state;
    const { purchaseBills, searchData, purchasereturnBillByDate } = this.props;
    const search = searchData || [];
    const Id = localStorage.getItem("roleName");

    return (
      <div>
        {this.props.progressBarStatus && <TopBarProgress />}
        {/* ==============Search Bar and Date Filter================= */}
        <Row className="title-row">
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="page-header">Purchase Return Bill</div>
          </Col>
        </Row>
        <Row className="mt-4">
          {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}> */}
          <Search
            title="Purchase Return Bill"
            placeholder="Sale Return Id, Barcode or CustomerName"
            searchopt={searchOptions}
            cancelState={this.cancelState}
            changeFunction={this.onChange}
            location={this.props?.history.location}
          />
          {/* </Col> */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <DateWiseSearch
              keyId={"Purchase Return Bill"}
              historyProps={this.props}
            />
          </Col>
        </Row>
        {/* ======================================== */}

        <div className="card final-purchases-list">
          <div className="card-body">
            {console.log("databefore", this.props.purchaseBills)}
            <Table
              dataSource={this.listData()}
              // dataSource={searchValue ? search : purchasereturnBillByDate.data? purchasereturnBillByDate.data : purchaseBills.data}
              //dataSource={this.props.purchaseBills && this.props.purchaseBills.data ? this.props.purchaseBills.data:[]}
              pagination={{
                pageSize: 25,
                total: searchData
                  ? searchData?.data?.length
                  : purchasereturnBillByDate?.data?.length,
              }}
              bordered="true"
              size="middle"
              scroll={{ y: 240 }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Purchase Return Id"
                dataIndex="purchaseReturnBillId"
                key="purchaseReturnBillId"
                width={150}
                {...this.getColumnSearchProps("purchaseReturnBillId")}
              />
              <Column
                title="Dealer Name"
                dataIndex="dealerName"
                key="dealerName"
                width={200}
                {...this.getColumnSearchProps("dealerName")}
              />
              <Column
                title="C_Date"
                dataIndex="created_at"
                key="created_at"
                width={130}
                render={(value, item, index) =>
                  moment(item.created_at).format("YYYY-MM-DD")
                }
              />
              <Column
                title="Total Qnty"
                dataIndex="totalQuantity"
                key="totalQuantity"
                width={90}
              />
              <Column
                title="Total Boxes"
                dataIndex="totalBoxes"
                key="totalBoxes"
                width={90}
              />
              <Column
                title="Total Price"
                dataIndex="totalPrice"
                key="totalPrice"
                width={90}
              />

              <Column
                title="Action"
                key="action"
                width={120}
                render={(text, record) => {
                  if (Id === "DME") {
                    return (
                      <Row gutter={[8, 0]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} align="center">
                          <a>
                            <ShowReturnBillPurchse
                              purchaseId={record.purchaseReturnBillId}
                              data={record}
                            />
                          </a>
                        </Col>
                      </Row>
                    );
                  } else {
                    return (
                      <Row gutter={[8, 0]}>
                        <Popconfirm
                          title="Are you sure to Edit this bill?"
                          onConfirm={() => this.editPurchaseReturn(text)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <div className="btn">
                            <Tooltip title=" Edit Bill">
                              <EditOutlined />
                            </Tooltip>
                          </div>
                        </Popconfirm>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <a>
                            <ShowReturnBillPurchse
                              purchaseId={record.purchaseReturnBillId}
                              data={record}
                            />
                          </a>
                        </Col>
                      </Row>
                    );
                  }
                }}
              />
            </Table>{" "}
            <div className=" mt-4">
              <div className="col-md-12 ml-auto">
                <div className="grand-totall">
                  <div className="title-wrap">
                    <h4 className="cart-bottom-title section-bg-gary-cart pt-4">
                      Purchase Return Bill Total
                    </h4>
                  </div>
                  <h5>
                    Total Bills{" "}
                    <span>
                      {searchData.count
                        ? searchData.count
                        : purchasereturnBillByDate &&
                          purchasereturnBillByDate.count
                          ? purchasereturnBillByDate.count
                          : ""}
                    </span>
                  </h5>
                  <h5>
                    Total Boxes{" "}
                    <span>
                      {searchData.allBoxes
                        ? searchData.allBoxes
                        : purchasereturnBillByDate &&
                          purchasereturnBillByDate.allBoxes
                          ? purchasereturnBillByDate.allBoxes
                          : ""}
                    </span>
                  </h5>
                  <h5>
                    Total Quantity{" "}
                    <span>
                      {searchData.allQuantity
                        ? searchData.allQuantity
                        : purchasereturnBillByDate &&
                          purchasereturnBillByDate.allQuantity
                          ? purchasereturnBillByDate.allQuantity
                          : ""}
                    </span>
                  </h5>
                  {/* <h4 className="grand-totall-title">
                    Total Amount{" "}
                    <span>
                      {searchData.allAmount
                        ? searchData.allAmount
                        : purchasereturnBillByDate &&
                          purchasereturnBillByDate.allAmount
                          ? parseInt(purchasereturnBillByDate.allAmount).toFixed()
                          : ""}
                    </span>
                  </h4> */}

                  <h5 className="grand-totall-title">
                    Total Amount{" "}
                    <span>
                      {searchData.allAmount
                        ? searchData.allAmount
                        : purchasereturnBillByDate &&
                          purchasereturnBillByDate.allAmount
                          ? parseInt(purchasereturnBillByDate.allAmount).toFixed()
                          : ""}
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="float-right mt-3">
              {/* <Pagination
                defaultCurrent
                defaultPageSize
                total="2"
                //   total={totalPages}
                onChange={this.onChangePage}
                showLessItems="true"
              /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PurchaseReturnBillList.propTypes = {
  getPurchaseReturnBillList: PropTypes.func.isRequired,
  getPurchaseReturnDataPurchaseId: PropTypes.func.isRequired,
  getPurchaseReturnBillListBydate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  purchaseBills: state.purchaseData.purchaseBillList,
  purchaseReturnOrders: state.purchaseData.purchaseReturnOrders,
  searchData: state.purchaseData.purchaseSearchData,
  purchasereturnBillByDate: state.purchaseData.purchasereturnBillByDate,
  progressBarStatus: state.progressBarReducer.showProgressBar,
});

export default connect(mapStateToProps, {
  getPurchaseReturnBillList,
  getPurchaseReturnDataPurchaseId,
  getPurchaseReturnBillListBydate,
})(PurchaseReturnBillList);
