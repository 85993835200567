import React, { useState } from "react";
import { Link } from "react-router-dom";
import BackButton from "./Button";
import { Row, Col, Input } from "antd"
import './style.css'
import { CloseOutlined, } from '@ant-design/icons';


function ReportDashBoard() {

    const getRoleName = localStorage.getItem("roleName")
    const reportNav = [
        ...(getRoleName === "ADMIN" || getRoleName === "PURCHASE_ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "DME" ? [{
            id: 1,
            name: 'Credit and Debit Report',
            path: '/dagaImpex/CreditandDebitReport',
            icon: <i className="fas fa-rupee-sign"></i>
        }] : []),
        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "SALES_ADMIN" || getRoleName === "MIS" || getRoleName === "DME"? [{
            id: 2,
            name: 'Sales Customer Report',
            path: '/dagaImpex/salesCustomerReport',
            icon: <i className="fas fa-user-clock"></i>
        }]
            : []),
        ...(getRoleName === "ADMIN" || getRoleName === "PURCHASE_ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "MIS" || getRoleName === "DME"? [{
            id: 3,
            name: 'Purchase Bill Report',
            path: '/dagaImpex/purchaseBillReport',
            icon: <i className="fas fa-cart-plus"></i>
        }] : []
        ),
        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "MIS" || getRoleName === "DME"? [{
            id: 4,
            name: 'Purchase Report',
            path: '/dagaImpex/purchaseReport',
            icon: <i className="fas fa-money"></i>
        }] : []
        ),
        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "SALES_ADMIN" || getRoleName === "MIS" || getRoleName === "DME" || getRoleName === "CRM"? [{
            id: 5,
            name: 'Sales Summary Report',
            path: '/dagaImpex/salesSummaryReport',
            icon: <i className="fas fa-rupee-sign"></i>
        }] : []
        ),

        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "PURCHASE_ADMIN" || getRoleName === "MIS" || getRoleName === "DME"? [{
            id: 6,
            name: 'Dealer Purchases Report',
            path: '/dagaImpex/dealerPurchaseReport',
            icon: <i className="fas fa-user-tag"></i>
        }] : []
        ),
        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "MIS" || getRoleName === "DME" ? [{
            id: 7,
            name: 'Stock Ageing Report',
            path: '/dagaImpex/stockAgeingReport',
            icon: <i className=" fas fa-dolly-flatbed"></i>
        }] : []
        ),
        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "MIS" || getRoleName === "DME" ? [{
            id: 8,
            name: 'Top Customer Report',
            path: '/dagaImpex/topCustomerReport',
            icon: <i className=" fas fa-award"></i>
        }] : []
        ),
        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "MIS" || getRoleName === "DME" || getRoleName === "CRM" ? [{
            id: 9,
            name: 'Sales Report',
            path: '/dagaImpex/salesReport',
            icon: <i className=" fas fa-bookmark"></i>
        }] : []
        ),
        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "MIS" || getRoleName === "DME" || getRoleName === "CRM" ? [{
            id: 9,
            name: 'Sales Report-New',
            path: '/dagaImpex/salesReport-New',
            icon: <i className=" fas fa-bookmark"></i>
        }] : []
        ),
        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "MIS" || getRoleName === "DME" ? [{
            id: 10,
            name: 'Stock Report',
            path: '/dagaImpex/stockReport',
            icon: <i className=" fas fa-bookmark"></i>
        }] : []
        ),
        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "MIS" || getRoleName === "DME" ? [{
            id: 11,
            name: 'Inactive Customer Report',
            path: '/dagaImpex/inactiveCustomerReport',
            icon: <i className="far fa-file-alt"></i>
        }] : []
        ),
        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "MIS" || getRoleName === "DME" ? [{
            id: 12,
            name: 'Agent Report',
            path: '/dagaImpex/agentWiseReport',
            icon: <i className="fas fa-user-clock"></i>
        }] : []
        ),
        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "PURCHASE_ADMIN" || getRoleName === "SALES_ADMIN" || getRoleName === "MIS" || getRoleName === "DME" ? [{
            id: 13,
            name: 'Barcode Report',
            path: '/dagaImpex/barcodeReport',
            icon: <i className="far fa-file-alt"></i>
        }] : []
        ),
        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "MIS" || getRoleName === "DME" ? [{
            id: 14,
            name: 'Best Selling Item Report',
            path: '/dagaImpex/bestSellingItemReport',
            icon: <i className="fas fa-trophy"></i>
        }] : []
        ),
        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "MIS" || getRoleName === "DME" ? [{
            id: 15,
            name: 'Sales Person Report',
            path: '/dagaImpex/salesPersonReport',
            icon: <i className="fas fa-user-tie"></i>
        }] : []
        ),
        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "MIS" || getRoleName === "DME" ? [{
            id: 16,
            name: 'Sales Person Comparision Report',
            path: '/dagaImpex/salesPersonComparisionReport',
            icon: <i className="fas fa-user-tie"></i>
        }] : []
        ),
        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "SALES_ADMIN" || getRoleName === "MIS" || getRoleName === "DME"? [{
            id: 17,
            name: 'Sales Return Report',
            path: '/dagaImpex/salesReturnReport',
            icon: <i className="fas fa-store-alt-slash"></i>
        }] : []
        ),

        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "PURCHASE_ADMIN" || getRoleName === "MIS" || getRoleName === "DME"? [{
            id: 18,
            name: 'Purchase Return Report',
            path: '/dagaImpex/purchaseReturnReport',
            icon: <i className="fas fa-store-alt-slash"></i>
        }] : []
        ),
        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "MIS" || getRoleName === "DME"? [{
            id: 19,
            name: 'Fast Moving Items Report',
            path: '/dagaImpex/fastMovingItemsReport',
            icon: <i className="fas fa-shipping-fast"></i>
        }] : []
        ),
        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "MIS" || getRoleName === "DME"? [{
            id: 20,
            name: 'Non Moving Items Report',
            path: '/dagaImpex/nonMovingItemsReport',
            icon: <i className="fas fa-boxes"></i>
        }] : []
        ),
        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "MIS" || getRoleName === "DME"? [{
            id: 21,
            name: 'Customer Report',
            path: '/dagaImpex/customerReport',
            icon: <i className="fas fa-user-tie"></i>
        }] : []
        ),
        ...(getRoleName === "ADMIN" || getRoleName === "DME"? [{
            id: 22,
            name: 'Customer Dummy  Report',
            path: '/dagaImpex/customerDummyReport',
            icon: <i className="fas fa-user-tie"></i>
        }] : []
        ),
        ...(getRoleName === "ADMIN" || getRoleName === "ACCOUNTS" || getRoleName === "MIS" || getRoleName === "DME" ? [{
            id: 23,
            name: 'Best Selling Brand Report',
            path: '/dagaImpex/bestSellingBrandReport',
            icon: <i className="fas fa-trophy"></i>
        }] : []
        ),
        ...(getRoleName === "ADMIN" || getRoleName === "DME"? [{
            id: 24,
            name: 'Available Stock Report',
            path: '/dagaImpex/availableStock',
            icon: <i className="fas fa-trophy"></i>
        }] : []
        ),

        ...(getRoleName === "ADMIN" || getRoleName === "DME"? [{
            id: 24,
            name: 'Opening Stock Report',
            path: '/dagaImpex/opening-stock-report',
            icon: <i className="fas fa-user-tie"></i>
        }] : []
        ),
    ]
    const [value, setValue] = useState("")
    const [reportData, setReportData] = useState(reportNav)

    const resetFun = () => {
        setValue("")
        setReportData(reportNav)
    }
    const onValueChange = (e) => {
        setValue(e.target.value)
        handleFilter(e.target.value)
    }
    const handleFilter = (inputValue) => {
        const newValue = reportNav.filter((item) => {
            return item.name.toLowerCase().includes(inputValue.toLowerCase());
        });
        setReportData(newValue)
    };
    return (
        <div className="subCategory-dashboard">
            <Row className="title-row mb-6">
                <Col span={16}>
                    <div className="page-header">Report Dashboard</div>
                </Col>
                <Col span={8}>
                    <Input
                        placeholder={"Report Name"}
                        value={value}
                        onChange={(e) => onValueChange(e)}
                        suffix={
                            <CloseOutlined
                                onClick={() => resetFun()}
                                style={{
                                    color: 'rgba(0,0,0,.45)', cursor: "pointer"
                                }}
                            />
                        }
                    // onPressEnter={this.handleSearchButtonClick}
                    />
                </Col>
            </Row>
            <div >
                <div className="row mt-4">
                    {reportData.sort((a, b) => a.name.localeCompare(b.name)).map((item) => (
                        <Link to={item.path} className="col-xl-3 col-lg-4 col-md-6" key={item.id}>
                            <div className="reportCard l-bg-cherry">
                                <div className="reportCard-statistic-3 p-4">
                                    {/* <div className="reportCard-icon reportCard-icon-large">{item.icon}</div> */}
                                    <div className="row align-items-center mb-2 d-flex">
                                        <div className="">
                                            <h4 className="d-flex align-items-center mb-0 text-white mt-2">
                                                {item.name}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ReportDashBoard;