import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import HeaderOne from "../../wrappers/header/HeaderOne";
import ArrowLeft from "../../assets/images/Group 116.png";
import { useDispatch, useSelector } from "react-redux";
import FooterOne from "../../wrappers/footer/FooterOne";
import logo from "../../assets/images/Daga_Impex_Logo.png"
import { ArrowLeftOutlined } from "@ant-design/icons";

const AboutUs = () => {
    const history = useHistory();

    return (
        <div className="">
            <HeaderOne />
            <div className="breadcrumb-area shadow-sm mb-0">
                <div className="p-0">
                    <div className="breadcrumb-content mx-2 d-flex align-items-center">
                        <Link to="">
                            <ArrowLeftOutlined className="text-white" />
                        </Link>
                        <div className="text-center w-100 breadCrumb-Text"
                            style={{
                                fontWeight: 500
                            }}
                        >
                            About Us
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="pt-3  pb-5 aboutUsPageText"
                style={{
                    backgroundColor: '#F5F5F5'
                }}
            >
                <div className="mb-4 container mt-4">
                    <div className="text-center mb-3">
                        <img src={logo} width="120px" alt="" srcset="" />
                    </div>
                    <div>
                        <p className="text-justify p-2 aboutUsText">
                            Your trusted partner in wholesale fashion based in Indore.
                            We specialize in providing a diverse range of high-quality clothing,
                            offering everything from trendy kids' wear to elegant winter collections.
                            With years of expertise in the wholesale clothing industry,
                            we are committed to delivering top-notch products that cater to
                            the unique needs of retailers across India.
                            At Daga Impex, we believe in offering more than just clothing —we offer style, comfort, and
                            reliability. Our mission is to make fashion accessible to everyone by offering
                            a wide selection of affordable yet premium garments for every season and occasion. Whether you’re
                            looking for casual wear, festive outfits, or seasonal essentials, we have a
                            collection tailored to suit every need.
                        </p>
                    </div>
                    <div className="w-100 text-center mt-4"

                    >
                        <h3
                            style={{
                                fontWeight: 600
                            }}
                        >
                            {" "}
                            Why Choose Us ?
                        </h3>
                    </div>
                    <div>
                        <p className="text-justify p-2 aboutUsText mb-4">
                            Wide Range of Products: From stylish kids' wear to cozy winter clothing, we ensure that
                            our collection meets the latest trends and customer demands.
                            Quality Assurance: Our products are made with the highest quality materials,
                            ensuring durability and comfort.
                            Affordable Wholesale Pricing: As a wholesaler, we offer competitive pricing to help
                            you grow your business.
                            Customer-Centric Approach: We believe in building long-term relationships with our clients by
                            offering personalized service and reliable delivery. Join us in bringing the latest fashion
                            trends to your store with Daga Impex—where quality meets affordability!
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <FooterOne />
            </div>
        </div>
    );
};

export default AboutUs;
