import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getDiscountPrice } from "../../../helpers/product";

const MenuCart = ({ cartData, currency, deleteFromCart, setIsOpen2 }) => {
  let cartTotalPrice = 0;
  const { addToast } = useToasts();
  return (
    <div className={`shopping-cart-content ${setIsOpen2 ? "active" : ""}`}>

      {cartData && cartData.length > 0 ? (
        <Fragment>
          <ul>
            {cartData.map((single, key) => {
              const discountedPrice = getDiscountPrice(
                single.wsp,
                single.salesDiscount
              );
              const finalProductPrice = (
                single.wsp * currency.currencyRate
              ).toFixed(2);
              const finalDiscountedPrice = (
                discountedPrice * currency.currencyRate
              ).toFixed(2);

              discountedPrice != null
                ? (cartTotalPrice += finalDiscountedPrice * single.quantity)
                : (cartTotalPrice += finalProductPrice * single.quantity);

              return (
                <li className="single-shopping-cart" key={key}>
                  <div className="shopping-cart-img">
                    <Link to={{
                      pathname: process.env.PUBLIC_URL + "/product/" + single._id, state: {
                        designNo: single.designNumber || single.name,
                        brand: single.brand
                      }
                    }}>
                      <img
                        alt=""
                        src={process?.env?.PUBLIC_URL + single.image[0]}
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="shopping-cart-title w-100 ml-2" >
                    <h4>
                      <Link
                        to={{
                          pathname: process.env.PUBLIC_URL + "/product/" + single.id, state: {
                            designNo: single.designNumber || single.name,
                            brand: single.brand
                          }
                        }}
                      >
                        {" "}
                        {single.name}{" "}
                      </Link>
                    </h4>
                    <div className="d-flex justify-content-between">
                      <h6>Qty: {single.quantity}</h6>
                      <span className="mt-2">
                        {discountedPrice !== null
                          ? currency.currencySymbol + Number(finalDiscountedPrice).toFixed()
                          : currency.currencySymbol + Number(finalProductPrice).toFixed()}
                      </span>
                    </div>
                    {single.selectedProductColor &&
                      single.selectedProductSize ? (
                      <div className="cart-item-variation">
                        
                        <div  className="d-flex justify-content-between">
                          <span>Color: {single.selectedProductColor}</span>
                          <span>Size: {single.selectedProductSize}</span>
                        </div>
                        
                        <div  className="d-flex justify-content-between">
                          <span>D.No: {single.designNumber || single.name}</span>
                          <span>Brand: {single.brand}</span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="shopping-cart-delete">
                    <button onClick={() => deleteFromCart(single, addToast)}>
                      <i className="fa fa-times-circle" />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="shopping-cart-total">
            <h4>
              Total :{" "}
              <span className="shop-total">
                {currency.currencySymbol + cartTotalPrice.toFixed()}
              </span>
            </h4>
          </div>
          <div className="shopping-cart-btn btn-hover text-center">
            <Link className="default-btn" to={process?.env?.PUBLIC_URL + "/cart"}>
              view cart
            </Link>
            <Link
              className="default-btn"
              to={process?.env?.PUBLIC_URL + "/checkout"}
            >
              checkout
            </Link>
          </div>
        </Fragment>
      ) : (
        <p className="text-center">No items added to cart</p>
      )}
    </div>
  );
};

MenuCart.propTypes = {
  cartData: PropTypes.array,
  currency: PropTypes.object,
  deleteFromCart: PropTypes.func,
};

export default MenuCart;