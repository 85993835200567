import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, Row, Input, Button } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { getTextureById } from '../../../../redux/actions/textureAction'
import AddTexture from './AddTexture'
import TextureList from './TextureList'

// "66e1831536b4f75be86f26c5"

function Texture() {
    const dispatch = useDispatch()
    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSubmit = () => {
        console.log("Input Value:", inputValue);
        const textureId = '66e1831536b4f75be86f26c5';
        dispatch(getTextureById(textureId));
    };

    return (
        <>
            <Row gutter={24}>
                <Col sm={24} md={12} lg={20}>
                    <h3>
                        <Link to="/dagaImpex/add-details" className="mr-4">
                            <ArrowLeftOutlined />
                        </Link>
                        Texture</h3>
                </Col>
                {/* <Col sm={24} md={12} lg={6}>
                    <Input
                        placeholder="Enter Texture Id"
                        value={inputValue} 
                        onChange={handleInputChange} 
                        style={{ width: 200, marginRight: 10 }}
                    />
                    <Button type="primary" onClick={handleSubmit}>
                        Submit
                    </Button>

                </Col> */}
                <Col sm={24} md={12} lg={4}>
                    <AddTexture />
                </Col>
            </Row>

            <TextureList />
        </>
    )
}

export default Texture