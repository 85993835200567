import React, { Component } from "react";
import { Row, Col, Breadcrumb, AutoComplete } from "antd";
import { ArrowLeftOutlined, HomeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import CategoryList from "./CategoryList";
import AddCategory from "./AddCategory";

export default class Category extends Component {
  render() {
    return (
      <div className="subCategory-dashboard">
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="page-header">
              <Link to="/dagaImpex/add-details" className="mr-4">
                  <ArrowLeftOutlined />
                </Link>
                Category</div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <AddCategory />
            </Col>
          </Row>

          <CategoryList />
        </div>
      </div>
    );
  }
}
