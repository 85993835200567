import React, { useState } from "react";
import { message, Upload, Modal, Tooltip, Button, Form, Tag } from "antd";
import { EditOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  addProductImage,
  addSingleGlobalEcomImage,
  updateProductImage,
} from "../../../redux/actions/purchaseAction";
import { useDispatch } from "react-redux";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const UpdateSingleImage = ({ barcode, cancel, url }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [urlPath, setUrlPath] = useState("");
  const [spinStatus, setSpinStatus] = useState(false);
  const [error, setError] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");

  const dispatch = useDispatch();

  const submitSingleImage = async () => {
    setSpinStatus(true);
    try {
      let response = await dispatch(
        addSingleGlobalEcomImage(files[0].originFileObj)
      );
      setUrlPath(response.data.filePath);
      setSpinStatus(false);
      setError(""); // Clear any previous errors
    } catch (error) {
      setSpinStatus(false);
      setError(error.message);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const obj = {
        imageName: url,
        newImageName: urlPath,
        barcode: barcode,
      };
      let response = await dispatch(updateProductImage(obj));
      if (response.status === 200) {
        message.success("Image updated successfully");
        setLoading(false);
        setVisible(false);
        setFiles([]);
      }
    } catch (error) {
      console.log("error", error);
      message.error("Something went wrong, try again!");
      setLoading(false);
      setVisible(false);
      setFiles([]);
    }
    if (cancel) {
      cancel();
    }
  };

  // const beforeUpload = (file) => {
  //     const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  //     if (!isJpgOrPng) {
  //         message.error("You can only upload JPG/PNG file!");
  //     }
  //     const isLt2M = file.size / 1024 / 1024 < 2;
  //     if (!isLt2M) {
  //         message.error("Image must smaller than 2MB!");
  //     }
  //     return isJpgOrPng && isLt2M;
  // };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false; // Prevent upload
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      return false; // Prevent upload
    }

    // Create a new FileReader to read the image file
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        // Check the dimensions of the image
        if (img.width !== 1200 || img.height !== 1600) {
          message.error("Image must be 1200px by 1600px!");
          setFiles([]);
          return false; // Prevent upload
        }
        // If all checks pass, you can proceed with the upload
        // You may want to call a function to handle the upload here
      };
    };
    reader.readAsDataURL(file);

    // Return false to prevent the upload until the image dimensions are checked
    return false;
  };

  const handleChange = ({ fileList }) => {
    setFiles(fileList);
    submitSingleImage();
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleModalCancel = () => {
    setVisible(false);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload Images</div>
    </div>
  );

  return (
    <>
      <div className="btn" onClick={showModal}>
        <Tooltip title="Update Image">
          <EditOutlined className="brand-image-show-icon" />
          <p>Update</p>
        </Tooltip>
      </div>
      <Modal
        visible={visible}
        onCancel={handleModalCancel}
        title="Update Image"
        style={{ top: 50 }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose
      >
        <Form
          name="add-Images"
          className="add-Images"
          onFinish={onSubmit}
          initialValues={{
            remember: true,
          }}
        >
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            accept=".png, .jpg"
            listType="picture-card"
            fileList={files}
            onPreview={handlePreview}
            onChange={handleChange}
            beforeUpload={beforeUpload}
          >
            {files.length === 1 ? null : uploadButton}
          </Upload>

          {spinStatus ? (
            <LoadingOutlined
              className="brand-image-show-icon mt-2 mb-4"
              style={{ fontSize: 24 }}
              spin
            />
          ) : urlPath ? (
            <Tag color="success" className="mt-2 mb-4">
              Successfully Uploaded!
            </Tag>
          ) : (
            <Tag color="error" className="mt-2 mb-4">
              {error ? "Upload Failed" : "Not Uploaded"}
            </Tag>
          )}

          <Form.Item className="float-right">
            <Button className="close-modal mr-3" onClick={handleModalCancel}>
              Close
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="ok-modal"
              loading={loading}
              disabled={!urlPath}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateSingleImage;
