import { useEffect } from "react";

export function UseDebounceEffect(fn, waitTime, deps = []) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn();
    }, waitTime);

    return () => clearTimeout(t);
  }, deps);
}
