/* /////////////////////////////////////// */
/* 
Date              created by               Modifications
  
19-07-2023        Mohit                    Barcode css modified original code comitted

*/
/* /////////////////////////////////////// */

import React, { Component } from "react";
import {
  Table,
  Tooltip,
  Button,
  Space,
  Input,
  Modal,
  Row,
  Col,
  Tag,
  Switch,
} from "antd";
import {
  EyeOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getUniquePurchases,
  addVisiblityOnEcom,
  addEcomSale,
  getBrandAndDesignComboSales,
  handleChangeEcomIndividual,
} from "../../../redux/actions/purchaseAction";
import Highlighter from "react-highlight-words";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import AddImage from "./AddImage";
import AddEcomSale from "./AddEcomSale";
import gifLoader from "../../../assets/images/giphy.gif";
import AddGlobalImage from "./AddGlobalImage";
import AddGlobalImageByColor from "./AddGlobalImageByColor";
import ImageUploadModal from "./ImageResize/ImageUploadModel";
import ImageUploadModelColor from "./ImageResize/ImageUploadModelColor";
import AddImageUploadModel2 from "./ImageResize/AddImageUploadModel2";
// import AddImages from "./AddImages";
// import UpdatePurchase from "../UpdatePurchase";
// import "../../../../../assets/css/barcode.css";
var Barcode = require("react-barcode");

const { Column } = Table;

class ShowProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      page: "",
      current: 1,
      search: "",
      visible: false,
      visibleB: false,
      barcodes: [],
      isModalVisible: false,
    };
  }

  componentDidMount = () => {
    if (this.props.obj.searchtype) {
      this.setState({
        visible: true,
      });
    }
  };

  onImageUploadStatusChange = async () => {
    const { obj } = this.props;
    let objBody = {
      searchValue: obj.designNumber ? obj.designNumber : obj.searchValue,
      type: obj.brand ? obj.brand : obj.type,
    };
    await this.props.getUniquePurchases(objBody);
  };

  showModal = () => {
    let obj = {
      searchValue: this.props.obj.designNumber,
      type: this.props.obj.brand,
    };
    this.props.getUniquePurchases(obj);
    this.setState({
      visible: true,
    });
  };

  updateOffers = () => {
    const obj = this.props.obj;
    let objRequest = {
      searchValue: obj.designNumber || obj.searchValue,
      type: obj.brand || obj.type,
    };
    this.props.getUniquePurchases(objRequest);
  };

  showModalBarcode = () => {
    this.setState({
      visibleB: true,
      barcodes: this.props.purchases,
    });
  };
  handleModalCancel = () => {
    if (this.props.modalCancel) {
      this.props.modalCancel();
    }
    this.props.getBrandAndDesignComboSales(1);
    this.setState({ visible: false });
  };
  handleModalCancelBarcode = () => {
    this.setState({ visibleB: false });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  onSaleChange = async (arrr) => {
    this.setState({ loading: true });
    const { obj } = this.props;
    let objBody = {
      searchValue: obj.designNumber ? obj.designNumber : obj.searchValue,
      type: obj.brand ? obj.brand : obj.type,
    };
    if (this.props.type == "salesOffer") {
      if (arrr[0].sales == "ON") {
        let objarray = arrr.map((obj) => ({
          _id: obj._id,
          barcode: obj.barcode,
          status: "OFF",
        }));
        await this.props.addEcomSale({ toggleData: objarray });
        await this.props.getUniquePurchases(objBody);
      } else {
        let objarray = arrr.map((obj) => ({
          _id: obj._id,
          barcode: obj.barcode,
          status: "ON",
        }));
        await this.props.addEcomSale({ toggleData: objarray });
        await this.props.getUniquePurchases(objBody);
      }
    } else {
      if (arrr[0].ecommProductVisible == "ON") {
        let objarray = arrr?.map((obj) => ({
          _id: obj._id,
          barcode: obj.barcode,
          status: "OFF",
        }));
        await this.props.addVisiblityOnEcom({ toggleData: objarray });
        await this.props.getUniquePurchases(objBody);
      } else {
        let objarray = arrr?.map((obj) => ({
          _id: obj._id,
          barcode: obj.barcode,
          status: "ON",
        }));
        await this.props.addVisiblityOnEcom({ toggleData: objarray });
        await this.props.getUniquePurchases(objBody);
      }
    }
    this.setState({ loading: false });
  };

  // Handle Ecom on of for individual Products
  handleChangeEcomIndividual = async (e, item) => {
    const { obj } = this.props;
    let listBody = {
      searchValue: obj.designNumber ? obj.designNumber : obj.searchValue,
      type: obj.brand ? obj.brand : obj.type,
    };

    this.setState({ loading: true });

    let objBody = {
      _id: item._id,
      barcode: item.barcode,
      status: item.ecommProductVisible === "ON" ? "OFF" : "ON",
    };

    try {
      const response = await this.props.handleChangeEcomIndividual(objBody);
      if (response.status === 200) {
        await this.props.getUniquePurchases(listBody);
      }
    } catch (error) {
      console.error("Error changing sale status:", error);
      // Optionally, you can set an error state here
    } finally {
      this.setState({ loading: false });
    }
  };


  render() {
    const { visible, visibleB } = this.state;
    let newData = this.props.brandAndDesignCombo.map((obj) => obj.data);
    const isTrue =
      newData &&
      newData.length > 0 &&
      newData?.[0].some((obj) => obj.sales === "ON");
    return (
      <div>
        <button
          className="btn"
          type="button"
          data-original-title=""
          onClick={this.showModal}
          style={{ display: this.props.obj.searchtype ? "none" : "block" }}
        >
          <Tooltip title="View Products">
            <EyeOutlined />
          </Tooltip>
        </button>
        <Modal
          visible={newData && newData.length > 0 ? visible : false}
          onOk={this.handleOk}
          onCancel={this.handleModalCancel}
          title="View Products"
          style={{ top: 20 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
          width={1500}
        >
          {newData && newData.length > 0 && this.state.loading == false ? (
            <>
              {this.props?.type == "salesOffer" ? (
                <Row className="pt-2 pb-4">
                  <Col>
                    <div className="mt-2">
                      Sale Status &nbsp; : &nbsp;{" "}
                      <Switch
                        size="large"
                        checked={isTrue}
                        onChange={() => this.onSaleChange(newData[0])}
                      />
                    </div>
                  </Col>
                  <Col className="ml-4">
                    <AddEcomSale
                      data={newData[0]}
                      modalCancel={this.handleModalCancel}
                      updateOffers={this.updateOffers}
                    />
                  </Col>
                </Row>
              ) : (
                <Row className="pt-2 pb-4">
                  <Col span={3}>
                    <div className="mt-2">
                      Ecom Status &nbsp; : &nbsp;{" "}
                      <Switch
                        size="large"
                        checked={
                          newData.length > 0
                            ? newData[0][0].ecommProductVisible == "OFF"
                              ? false
                              : true
                            : false
                        }
                        onChange={() => this.onSaleChange(newData[0])}
                      />
                    </div>
                  </Col>
                  <Col span={4}>
                    {/* <AddGlobalImage
                      barcodeData={newData}
                      onImageUploadStatusChange={this.onImageUploadStatusChange}
                    /> */}
                    <div className="d-flex align-items-center">

                      Add Image &nbsp; : &nbsp;{" "}

                      <ImageUploadModal
                        barcodeData={newData}
                      />
                    </div>
                  </Col>
                  <Col span={6}>
                    Add Image By Color &nbsp; : &nbsp;{" "}
                    {/* <AddGlobalImageByColor
                      barcodeData={newData}
                      colorData={this.props?.brandAndDesignCombo?.[0]?.color}
                      onImageUploadStatusChange={this.onImageUploadStatusChange}
                      /> */}
                    <ImageUploadModelColor
                      barcodeData={newData}
                      colorData={this.props?.brandAndDesignCombo?.[0]?.color}
                      onImageUploadStatusChange={this.onImageUploadStatusChange}
                    />
                  </Col>
                </Row>
              )}
              <Table
                dataSource={newData && newData.length > 0 ? newData[0] : []}
                bordered="true"
                size="middle"
                pagination={false}
                scroll={{ y: 240 }}
              >
                <Column
                  title="SRN"
                  key="index"
                  render={(value, item, index) => index + 1}
                  width={60}
                />

                <Column
                  title="Barcode"
                  dataIndex="barcode"
                  key="barcode"
                  width={100}
                  {...this.getColumnSearchProps("barcode")}
                />
                <Column
                  title="Design No."
                  dataIndex="designNumber"
                  key="designNumber"
                  width={140}
                  {...this.getColumnSearchProps("designNumber")}
                />
                <Column
                  title="Brand"
                  dataIndex="brand"
                  key="brand"
                  {...this.getColumnSearchProps("brand")}
                  width={120}
                />
                <Column
                  title="Size"
                  dataIndex="size"
                  key="size"
                  width={80}
                  {...this.getColumnSearchProps("size")}
                />
                <Column
                  title="Color"
                  dataIndex="color"
                  key="color"
                  width={100}
                />
                <Column title="MOQ" dataIndex="MOQ" key="MOQ" width={60} />
                <Column
                  title="Qnty"
                  dataIndex="quantity"
                  key="quantity"
                  width={60}
                />
                <Column
                  title="Price"
                  dataIndex="purchasePrice"
                  key="purchasePrice"
                  width={90}
                />
                <Column title="WSP" dataIndex="wsp" key="wsp" width={80} />
                {this.props?.type == "salesOffer" ? (
                  <>
                    <Column
                      title="Sale Disc."
                      dataIndex="salesDiscount"
                      key="salesDiscount"
                      width={90}
                      render={(value, item, index) => `${item?.salesDiscount}%`}
                    />
                    <Column
                      title="Disc. Amount"
                      key="discountAmount"
                      width={100}
                      render={(value, item, index) => {
                        let discountedAmount = 0;
                        if (item.sales === "ON") {
                          discountedAmount = (
                            Number(item?.wsp) *
                            (Number(item?.salesDiscount) / 100)
                          ).toFixed(2);
                          return discountedAmount;
                        } else {
                          return discountedAmount;
                        }
                      }}
                    />
                    <Column
                      title="Final Price"
                      key="finalPrice"
                      width={120}
                      render={(value, item, index) => {
                        let finalPrice = (
                          Number(item?.wsp) -
                          Number(item?.wsp) *
                          (Number(item?.salesDiscount) / 100)
                        ).toFixed(2);
                        return finalPrice;
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Column
                      title="Ecom On/Off"
                      key="finalPrice"
                      width={150}
                      render={(value, item, index) => {
                        return (
                          <Switch
                            size="large"
                            checked={item.ecommProductVisible == "OFF" ? false : true}
                            onChange={(e) =>
                              this.handleChangeEcomIndividual(e, item)
                            }
                          />
                        );
                      }}
                    />
                  </>
                )}
                {/* newData[0][0].sales == "OFF" ? false : true : false */}
                {/* <Column title="Image Status" dataIndex="ecommProductVisible" key="ecommProductVisible"
              render={(text, record) => {
                if (record.rowColor === "Green") {
                  return (
                    <Tag color="green" key={record.rowColor}>
                      {"Available"}
                    </Tag>
                  );
                } else if (record.rowColor === "Red") {
                  return (
                    <Tag color="red" key={record.rowColor}>
                      {"Not Available"}
                    </Tag>
                  );
                } else if (record.rowColor === "Yellow") {
                  return (
                    <Tag color="gold" key={record.rowColor}>
                      {"Some Available"}
                    </Tag>
                  );
                }

              }}
              width={120} /> */}

                <Column
                  title="Action"
                  key="action"
                  width={80}
                  render={(text, record) => (
                    <Row gutter={[8, 0]}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        {/* <AddImages data={text} /> */}
                        <AddImageUploadModel2
                          data={text}
                        />
                        {/* <AddImage
                          data={text}
                          onImageUploadStatusChange={
                            this.onImageUploadStatusChange
                          }
                        /> */}
                      </Col>
                    </Row>
                  )}
                />
              </Table>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center align-items-center">
                <img src={gifLoader} width={"300px"} height={"300px"} />
              </div>
            </>
          )}
        </Modal>
      </div>
    );
  }
}

ShowProducts.propTypes = {
  getUniquePurchases: PropTypes.func.isRequired,
  addVisiblityOnEcom: PropTypes.func.isRequired,
  addEcomSale: PropTypes.func.isRequired,
  getBrandAndDesignComboSales: PropTypes.func.isRequired,
  handleChangeEcomIndividual: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  purchases: state.purchaseData.purchaseById,
  brandAndDesignCombo: state.purchaseData.ecomSalesPurchases,
});

export default connect(mapStateToProps, {
  getUniquePurchases,
  addVisiblityOnEcom,
  addEcomSale,
  getBrandAndDesignComboSales,
  handleChangeEcomIndividual,
})(ShowProducts);
