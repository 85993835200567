import React, { useState, useRef } from "react";
import { Modal, Button, Upload, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { CanvasPreview } from "./CanvasPreview";
import { UseDebounceEffect } from "./UseDebounceEffect";
import { useDispatch } from "react-redux";
import {
  addMultipleGlobalEcomImage,
  addSingleGlobalEcomImage,
} from "../../../../redux/actions/purchaseAction";

export default function ImageUploadModal({ barcodeData }) {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [images, setImages] = useState(Array(4).fill(null));
  const previewCanvasRefs = useRef(Array(4).fill(null));
  const [scale, setScale] = useState(1);
  const [scales, setScales] = useState(Array(4).fill(1));
  const imgRefs = useRef(Array(4).fill(null));
  const hiddenAnchorRef = useRef(null);
  const [crops, setCrops] = useState(
    Array(4).fill({ unit: "px", width: 275, height: 325, x: 50, y: 50 })
  );
  const [completedCrops, setCompletedCrops] = useState(Array(4).fill(null));
  const [dummyImages, setDummyImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const MAX_IMAGE_SIZE = 400;
  const FIXED_CROP = { width: 275, height: 325 };

  const onSelectFile =
    (index) =>
    ({ file }) => {
      if (!file || !file.type.startsWith("image/")) {
        message.error("Invalid file format. Please upload an image.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const image = new Image();
        image.src = reader.result?.toString() || "";

        image.onload = () => {
          let newScale = 1;
          let newWidth = image.width;
          let newHeight = image.height;

          if (image.width > MAX_IMAGE_SIZE || image.height > MAX_IMAGE_SIZE) {
            const scaleFactor = Math.max(
              image.width / MAX_IMAGE_SIZE,
              image.height / MAX_IMAGE_SIZE
            );
            newWidth = image.width / scaleFactor;
            newHeight = image.height / scaleFactor;
          }

          if (newWidth < FIXED_CROP.width || newHeight < FIXED_CROP.height) {
            const scaleX = FIXED_CROP.width / newWidth;
            const scaleY = FIXED_CROP.height / newHeight;
            newScale = Math.max(scaleX, scaleY);
          }

          setScales((prev) => {
            const updatedScales = [...prev];
            updatedScales[index] = newScale;
            return updatedScales;
          });

          setImages((prev) => {
            const updatedImages = [...prev];
            updatedImages[index] = reader.result?.toString() || "";
            return updatedImages.map((img) => img || "");
          });

          setCrops((prev) => {
            const updatedCrops = [...prev];
            updatedCrops[index] = {
              unit: "px",
              width: 275,
              height: 325,
              x: 50,
              y: 50,
            };
            return updatedCrops;
          });
        };

        image.onerror = () => {
          message.error("Error loading image.");
        };
      };

      reader.onerror = () => {
        message.error("Failed to read file.");
      };

      reader.readAsDataURL(file);
    };

  UseDebounceEffect(
    () => {
      completedCrops.forEach((crop, index) => {
        if (
          imgRefs.current[index] &&
          previewCanvasRefs.current[index] &&
          crop
        ) {
          try {
            CanvasPreview(
              imgRefs.current[index],
              previewCanvasRefs.current[index],
              crop,
              scales[index]
            );
          } catch (error) {
            console.error("Error in canvasPreview:", error);
          }
        }
      });
    },
    100,
    [scales, completedCrops]
  );

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setImages(Array(4).fill(null));
    setCrops(
      Array(4).fill({ unit: "px", width: 275, height: 325, x: 50, y: 50 })
    );
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function blobUrlToFile(blobUrl, fileName, mimeType) {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return new File([blob], fileName, { type: mimeType || blob.type });
  }

  const onDownloadCropClick = async () => {
    setLoading(true);
    const barcodeArray = barcodeData[0].map((obj) => obj.barcode);
    const croppedImageUrls = Array(4).fill("");

    for (let index = 0; index < images.length; index++) {
      if (!images[index] || !completedCrops[index]) continue;

      const image = imgRefs.current[index];
      const previewCanvas = previewCanvasRefs.current[index];

      if (!image || !previewCanvas) continue;

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      const tempCanvas = document.createElement("canvas");
      const ctx = tempCanvas.getContext("2d");
      if (!ctx) continue;

      tempCanvas.width = FIXED_CROP.width;
      tempCanvas.height = FIXED_CROP.height;

      ctx.drawImage(
        previewCanvas,
        0,
        0,
        previewCanvas.width,
        previewCanvas.height,
        0,
        0,
        FIXED_CROP.width,
        FIXED_CROP.height
      );

      const blob = await new Promise((resolve) =>
        tempCanvas.toBlob(resolve, "image/png")
      );

      if (blob) {
        const blobUrl = URL.createObjectURL(blob);
        croppedImageUrls[index] = blobUrlToFile(
          blobUrl,
          "cropped-image.jpg",
          "image/jpeg"
        );
      }
    }

    setDummyImages(croppedImageUrls);

    let imageUrlArray = [];

    Promise.all(croppedImageUrls)
      .then((resolvedFiles) => {
        const validFiles = resolvedFiles.filter((file) => file instanceof File);

        const uploadPromises = validFiles.map((file) =>
          dispatch(addSingleGlobalEcomImage(file))
            .then((response) => {
              imageUrlArray.push(response.data?.filePath);
            })
            .catch((error) => console.error("Error uploading file:", error))
        );

        Promise.all(uploadPromises).then(() => {
          const paddingCount = 4 - imageUrlArray.length;
          for (let i = 0; i < paddingCount; i++) {
            imageUrlArray.push("");
          }
          const requestBody = {
            imagePaths: imageUrlArray,
            barcodes: barcodeArray,
          };

          try {
            dispatch(addMultipleGlobalEcomImage(requestBody)).then(() => {
              setLoading(false);
              handleModalCancel();
            });
          } catch (error) {
            console.error("Upload error:", error);
            setLoading(false);
          }
        });
      })
      .catch((error) => {
        console.error("Error processing images:", error);
        setLoading(false);
      });
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Upload
      </Button>
      <Modal
        visible={isModalVisible}
        onCancel={handleModalCancel}
        title="Add Images"
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose
        width={900}
        maskClosable={false}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {images.map((image, index) => (
              <div
                key={index}
                style={{
                  border: "1px dashed #ddd",
                  padding: "20px",
                  textAlign: "center",
                  margin: "0 10px",
                }}
              >
                <Upload
                  showUploadList={false}
                  customRequest={onSelectFile(index)}
                  accept="image/*"
                >
                  <PlusOutlined />
                  <div>Upload Image {index + 1}</div>
                </Upload>
                <p style={{ color: image ? "green" : "red" }}>
                  {image ? "Uploaded" : "Not Uploaded"}
                </p>
              </div>
            ))}
          </div>

          <div
            style={{
              display: "flex",
              padding: "10px",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                flex: 1,
                padding: "10px",
                textAlign: "center",
                borderRight: "2px solid #ddd",
              }}
            >
              {images.map((image, index) =>
                image ? (
                  <div
                    key={index}
                    style={{ position: "relative", height: "450px" }}
                    className="test"
                  >
                    <div className="d-flex align-items-center my-2">
                      <label htmlFor="scale-input" className="mb-0">
                        Scale:{" "}
                      </label>
                      <input
                        id={`scale-input-${index}`}
                        type="number"
                        step="0.1"
                        value={scales[index]}
                        disabled={!image}
                        onChange={(e) => {
                          const newScales = [...scales];
                          newScales[index] = Number(e.target.value);
                          setScales(newScales);
                        }}
                      />
                      <button
                        onClick={() => {
                          const updatedImages = [...images];
                          updatedImages[index] = null;
                          setImages(updatedImages);

                          const updatedCrops = [...crops];
                          updatedCrops[index] = {
                            unit: "px",
                            width: 275,
                            height: 325,
                            x: 50,
                            y: 50,
                          };
                          setCrops(updatedCrops);

                          const updatedScales = [...scales];
                          updatedScales[index] = 1;
                          setScales(updatedScales);
                        }}
                        className="ml-2"
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          border: "none",
                          padding: "5px 10px",
                          cursor: "pointer",
                          borderRadius: "5px",
                        }}
                      >
                        Delete
                      </button>
                    </div>

                    <div className="d-flex align-items-center justify-content-center">
                      <ReactCrop
                        key={index}
                        crop={crops[index]}
                        onChange={(newCrop) => {
                          setCrops((prev) => {
                            const updatedCrops = [...prev];
                            updatedCrops[index] = {
                              ...newCrop,
                              width: FIXED_CROP.width,
                              height: FIXED_CROP.height,
                            };
                            return updatedCrops;
                          });
                        }}
                        onComplete={(c) =>
                          setCompletedCrops((prev) => {
                            const updatedCrops = [...prev];
                            updatedCrops[index] = c;
                            return updatedCrops;
                          })
                        }
                        minWidth={275}
                        minHeight={325}
                      >
                        <img
                          ref={(el) => (imgRefs.current[index] = el)}
                          src={image}
                          alt={`Uploaded ${index}`}
                          style={{
                            transform: `scale(${scales[index]})`,
                            minHeight: "325px",
                          }}
                        />
                      </ReactCrop>
                    </div>
                  </div>
                ) : null
              )}
            </div>

            <div style={{ flex: 1, padding: "10px", textAlign: "center" }}>
              {images.map((image, index) =>
                image ? (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      height: "450px",
                    }}
                  >
                    <canvas
                      key={index}
                      ref={(el) => (previewCanvasRefs.current[index] = el)}
                      style={{
                        border: "1px solid black",
                        width: "275px",
                        height: "325px",
                        marginTop: "10px",
                      }}
                    />
                  </div>
                ) : null
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Button
              onClick={handleModalCancel}
              style={{ backgroundColor: "#FFA500", color: "white" }}
            >
              Close
            </Button>
            <Button
              type="primary"
              onClick={onDownloadCropClick}
              loading={loading}
            >
              Upload Image
            </Button>
          </div>
        </div>

      </Modal>
    </>
  );
}
