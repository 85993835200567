import React, { Component } from "react";
import { Row, Col } from "antd";
import FloorList from "./FloorList";
import AddFloor from "./AddFloor";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default class Floor extends Component {
  render() {
    return (
      <div className="subCategory-dashboard">
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="page-header">
                <Link to="/dagaImpex/add-details" className="mr-4">
                  <ArrowLeftOutlined />
                </Link>
                Floors</div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <AddFloor />
            </Col>
          </Row>

          <FloorList />
        </div>
      </div>
    );
  }
}
