import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Table,
  message,
  Card,
  Select,
} from "antd";
import BarcodeReader from "react-barcode-reader";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { stockDetailsByBrands } from "../../../redux/actions/purchaseAction";
import { getBrands } from "../../../redux/actions/brandAction";

const { Column } = Table;

const LookUpList = () => {
  const [barcode, setBarcode] = useState(null);
  const [scannedQuant, setScannedQuant] = useState(0);
  const [lastScannedBarcode, setLastScannedBarcode] = useState(null);
  const [brands, setBrands] = useState([]);
  const dispatch = useDispatch();
  const brandsData = useSelector((state) => state.brandsData.brands);
  const barcodeDetails = useSelector(
    (state) => state.purchaseData.stockDeatsilsByBrand.inventoryDetails || []
  );
  const [verified, setVerified] = useState([]);
  const [remaining, setRemaining] = useState([...barcodeDetails]);
  const [unverified, setUnverified] = useState([]);

  const handleChangeBrand = async (key, value) => {
    if (value.length > 3) {
      message.error("You can only select up to 3 brands.");
      return;
    }
    console.log("Line No: 30", value);
    let brandName = value.map((obj) => obj.value);
    setBrands([...brandName]);
    await dispatch(stockDetailsByBrands({ brands: [...brandName] }));
  };

  const handleBarcodeChange = (e) => {
    const inputBarcode = e.target.value;
    setBarcode(inputBarcode);
  };

  const handleSearch = async (value) => {
    if (value.length !== 9) {
      message.error("Invalid Barcode!");
    } else {
      handleBarcodeScan(value);
      // const res = await dispatch(stockDetailsByBarcode({ barcode: value }));
      // if (res && res.data) {
      //   const currentMOQ = res?.data[0]?.MOQ || 0;
      //   if (lastScannedBarcode === value) {
      //     setScannedQuant(scannedQuant + currentMOQ);
      //   } else {
      //     setScannedQuant(currentMOQ);
      //   }
      // }
      // setLastScannedBarcode(value);
    }
  };

  const handleReset = () => {
    // dispatch(stockDetailsByBarcode("reset"));
    // setScannedQuant(0);
    setBarcode(null);
    // setLastScannedBarcode(null);
  };

  const getCardStyle = (scanned, stock) => {
    if (scanned < stock) {
      return {
        background: "linear-gradient(135deg, #fff176 10%, #ffe082 100%)", // Light Yellow gradient
        color: "#000",
      };
    }
    if (scanned > stock) {
      return {
        background: "linear-gradient(135deg, #ffccbc 10%, #ef5350 100%)", // Light Red gradient
        color: "#000",
      };
    }
    return {
      background: "linear-gradient(135deg, #a5d6a7 10%, #4caf50 100%)", // Light Green gradient
      color: "#000",
    };
  };

  let brandOptions = brandsData?.map((brand) => {
    return {
      value: brand._id,
      label: brand.name,
    };
  });

  const handleResetBrands = () => {
    // dispatch(stockDetailsByBrands("reset"));
    // setBrands([]);
    window.location.reload();
  };

  // ==============

  const columns = [
    { title: "S.No", dataIndex: "serial", key: "serial" },
    { title: "Barcode", dataIndex: "barcode", key: "barcode" },
    { title: "Stock", dataIndex: "stock", key: "stock" },
  ];

  const unVerifiedcolumns = [
    { title: "S.No", dataIndex: "serial", key: "serial" },
    { title: "Barcode", dataIndex: "barcode", key: "barcode" },
    { title: "Count", dataIndex: "stock", key: "stock" },
  ];

  const handleBarcodeScan = (e) => {
    const scannedBarcode = e.trim();
    if (!scannedBarcode) return;

    const existingItem = barcodeDetails.find(
      (item) => item.barcode == scannedBarcode
    );

    if (existingItem) {
      const verifiedItemIndex = verified.findIndex(
        (item) => item.barcode == scannedBarcode
      );
      const remainingItemIndex = remaining.findIndex(
        (item) => item.barcode == scannedBarcode
      );

      if (verifiedItemIndex === -1) {
        if( existingItem.stock >= existingItem.MOQ){
          setVerified([
            ...verified,
            {
              serial: verified.length + 1,
              barcode: scannedBarcode,
              stock: existingItem.MOQ,
              initialStock: existingItem.stock,
            },
          ]);
          setRemaining([
            ...verified,
            {
              serial: verified.length + 1,
              barcode: scannedBarcode,
              stock: Number(existingItem.stock - existingItem.MOQ),
              initialStock: existingItem.stock,
            },
          ]);
        }else{
          message.error("Out Of Stock!")
        }
       
      } else {
        // let updatedVerified = [...verified];
        // updatedVerified[verifiedItemIndex].stock += existingItem.MOQ;
        // setVerified(updatedVerified);
        if (
          verified[verifiedItemIndex].stock <
          verified[verifiedItemIndex].initialStock
        ) {
          setVerified((prevVerified) =>
            prevVerified.map((item, index) =>
              index === verifiedItemIndex
                ? { ...item, stock: item.stock + existingItem.MOQ }
                : item
            )
          );
        } else {
          const unverifiedItemIndex = unverified.findIndex(
            (item) => item.barcode == scannedBarcode
          );
          if (unverifiedItemIndex === -1) {
            setUnverified((prevUnverified) => [
              ...prevUnverified,
              {
                serial: prevUnverified.length + 1,
                barcode: scannedBarcode,
                stock: 1,
              },
            ]);
          } else {
            setUnverified((prevUnverified) =>
              prevUnverified.map((item, index) =>
                index === unverifiedItemIndex
                  ? { ...item, stock: item.stock + 1 }
                  : item
              )
            );
          }
        }
      }

      if (remainingItemIndex !== -1) {
        let updatedRemaining = [...remaining];
        updatedRemaining[remainingItemIndex].stock -= existingItem.MOQ;
        if (updatedRemaining[remainingItemIndex].stock <= 0) {
          updatedRemaining.splice(remainingItemIndex, 1);
        } else {
          // updatedRemaining[remainingItemIndex].stock = existingItem.stock - updatedVerified.find(item => item.barcode == scannedBarcode)?.stock;
        }
        setRemaining(updatedRemaining);
      }
    } else {
      const unverifiedItemIndex = unverified.findIndex(
        (item) => item.barcode == scannedBarcode
      );
      if (unverifiedItemIndex === -1) {
        setUnverified((prevUnverified) => [
          ...prevUnverified,
          {
            serial: prevUnverified.length + 1,
            barcode: scannedBarcode,
            stock: 1,
          },
        ]);
      } else {
        setUnverified((prevUnverified) =>
          prevUnverified.map((item, index) =>
            index === unverifiedItemIndex
              ? { ...item, stock: item.stock + 1 }
              : item
          )
        );
      }
    }
    e = "";
  };

  // Sync Remaining Stock after Verified Updates
  // useEffect(() => {
  //   setRemaining(
  //     barcodeDetails?.map((item) => {
  //       const verifiedStock = verified.find((v) => v.barcode === item.barcode)?.stock || 0;
  //       return { ...item, stock: item.stock - verifiedStock };
  //     }).filter((item) => item.stock > 0)
  //   );
  // }, [verified, barcodeDetails]);

  return (
    <div>
      <Form>
        <Row gutter={16} className="mt-4">
          <Col xs={11} sm={11} md={11} lg={11} xl={11}>
            <Select
              className="w-100"
              mode="multiple"
              name="brand"
              id="brand"
              showSearch
              placeholder="Select Brand"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.value
                  .toLowerCase()
                  .localeCompare(optionB.value.toLowerCase())
              }
              maxTagCount={3}
              onChange={handleChangeBrand}
              onClick={() => dispatch(getBrands())}
              options={brandOptions}
            />
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1}>
            <Button
              type="primary"
              className="reset-button"
              icon={<CloseOutlined />}
              onClick={() => handleResetBrands()}
            />
          </Col>
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <Form.Item>
              <BarcodeReader
                onScan={(value) => {
                  handleSearch(value);
                }}
                onError={(err) => console.error("Barcode scan error:", err)}
              />
              <Input
                name="barcode"
                placeholder="Enter Barcode or Scan"
                value={barcode}
                onChange={handleBarcodeChange}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={1} lg={1} xl={1}>
            <Button
              type="primary"
              className="search-button"
              icon={<SearchOutlined />}
              onClick={() => handleSearch(barcode)}
              style={{ marginLeft: "5px" }}
            />
          </Col>
          <Col xs={24} sm={24} md={1} lg={1} xl={1}>
            <Button
              type="primary"
              className="reset-button"
              icon={<CloseOutlined />}
              onClick={handleReset}
            />
          </Col>
        </Row>
      </Form>

      <div className="card mt-4 mb-4 final-purchases-list">
        <div className="card-body">
          <Table
            dataSource={barcodeDetails || []}
            bordered
            size="middle"
            pagination={false}
            scroll={{ y: 200 }}
          >
            <Column
              title="Design Number"
              dataIndex="designNumber"
              key="designNumber"
            />
            <Column title="Brand" dataIndex="brand" key="brand" />
            <Column title="Barcode" dataIndex="barcode" key="barcode" />
            <Column title="Stock" dataIndex="stock" key="stock" />
            <Column title="Total Sold" dataIndex="totalSold" key="totalSold" />
          </Table>
        </div>
      </div>
      {console.log("Line No: 263", verified, remaining, unverified)}

      <Row gutter={16} className="mt-4">
        <Col span={8}>
          <div className="stockVerfy-verified">
            <h3 className="page-header">Verified</h3>
            <Table
              pagination={false}
              dataSource={verified}
              columns={columns}
              rowKey="serial"
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="stockVerfy-remaining">
            <h3 className="page-header" style={{ fontSize: "18px" }}>
              Remaining
            </h3>
            <Table
              dataSource={remaining.map((item, index) => ({
                serial: index + 1,
                ...item,
              }))}
              pagination={false}
              columns={columns}
              rowKey="barcode"
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="stockVerfy-unVerified">
            <h3 className="page-header" style={{ fontSize: "18px" }}>
              Unverified
            </h3>
            <Table
              pagination={false}
              dataSource={unverified}
              columns={unVerifiedcolumns}
              rowKey="barcode"
            />
          </div>
        </Col>
      </Row>

      {/* {barcodeDetails?.data?.length > 0 && (
        <Row gutter={16}>
          <Col sm={24} md={12}>
            <Card
              className="mt-4"
              title="Current Stock"
              bordered
              align="center"
              style={getCardStyle(scannedQuant, barcodeDetails?.data[0]?.stock)}
            >
              <div style={{ fontSize: "20px", textAlign: "center" }}>
                {barcodeDetails?.data[0]?.stock}
              </div>
            </Card>
          </Col>

          <Col sm={24} md={12}>
            <Card
              className="mt-4"
              title="Quantity As Per Scan"
              bordered
              align="center"
              style={getCardStyle(scannedQuant, barcodeDetails?.data[0]?.stock)}
            >
              <div style={{ fontSize: "20px", textAlign: "center" }}>
                {scannedQuant}
              </div>
            </Card>
          </Col>
        </Row>
      )} */}
    </div>
  );
};

export default LookUpList;

// ========================================

// import React, { useState } from "react";
// import { Row, Col, Form, Input, Button, Table, message, Card } from "antd";
// import BarcodeReader from "react-barcode-reader";
// import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
// import { useDispatch, useSelector } from "react-redux";
// import { stockDetailsByBarcode } from "../../../redux/actions/purchaseAction";

// const { Column } = Table;

// const LookUpList = () => {
//   const [barcode, setBarcode] = useState(null);
//   const [scannedQuant, setScannedQuant] = useState(0);
//   const [lastScannedBarcode, setLastScannedBarcode] = useState(null);
//   const dispatch = useDispatch();

//   const barcodeDetails = useSelector(
//     (state) => state.purchaseData.stockDetailsByBarcode
//   );

//   const handleBarcodeChange = (e) => {
//     const inputBarcode = e.target.value;
//     setBarcode(inputBarcode);
//   };

//   const handleSearch = async (value) => {
//     if (value.length !== 9) {
//       message.error("Invalid Barcode!");
//     } else {
//       const res = await dispatch(stockDetailsByBarcode({ barcode: value }));
//       if (res && res.data) {
//         const currentMOQ = res?.data[0]?.MOQ || 0;

//         if (lastScannedBarcode === value) {
//           setScannedQuant(scannedQuant + currentMOQ);
//         } else {
//           setScannedQuant(currentMOQ);
//         }
//       }
//       setLastScannedBarcode(value);
//     }
//   };

//   const handleReset = () => {
//     dispatch(stockDetailsByBarcode("reset"));
//     setScannedQuant(0);
//     setBarcode(null);
//     setLastScannedBarcode(null);
//   };

//   const getCardStyle = (scanned, stock) => {
//     if (scanned < stock) {
//       return {
//         background: "linear-gradient(135deg, #fff176 10%, #ffe082 100%)", // Light Yellow gradient
//         color: "#000",
//       };
//     }
//     if (scanned > stock) {
//       return {
//         background: "linear-gradient(135deg, #ffccbc 10%, #ef5350 100%)", // Light Red gradient
//         color: "#000",
//       };
//     }
//     return {
//       background: "linear-gradient(135deg, #a5d6a7 10%, #4caf50 100%)", // Light Green gradient
//       color: "#000",
//     };
//   };

//   return (
//     <div>
//       <Form>
//         <Row span={32} className="mt-4">
//           <Col xs={24} sm={24} md={7} lg={7} xl={7}>
//             <Form.Item>
//               <BarcodeReader
//                 onScan={(value) => {
//                   handleSearch(value);
//                 }}
//                 onError={(err) => console.error("Barcode scan error:", err)}
//               />
//               <Input
//                 name="barcode"
//                 placeholder="Enter Barcode or Scan"
//                 value={barcode}
//                 onChange={handleBarcodeChange}
//               />
//             </Form.Item>
//           </Col>
//           <Col xs={24} sm={24} md={1} lg={1} xl={1}>
//             <Button
//               type="primary"
//               className="search-button"
//               icon={<SearchOutlined />}
//               onClick={() => handleSearch(barcode)}
//               style={{ marginLeft: "5px" }}
//             />
//           </Col>
//           <Col xs={24} sm={24} md={1} lg={1} xl={1}>
//             <Button
//               type="primary"
//               className="reset-button"
//               icon={<CloseOutlined />}
//               onClick={handleReset}
//             />
//           </Col>
//         </Row>
//       </Form>

//       <div className="card mt-4 final-purchases-list">
//         <div className="card-body">
//           <Table
//             dataSource={
//               barcodeDetails?.data?.length > 0 ? barcodeDetails?.data : []
//             }
//             bordered
//             size="middle"
//             pagination={false}
//           >
//             <Column
//               title="Design Number"
//               dataIndex="designNumber"
//               key="designNumber"
//             />
//             <Column title="Brand" dataIndex="brand" key="brand" />
//             <Column title="Barcode" dataIndex="barcode" key="barcode" />
//             <Column title="Stock" dataIndex="stock" key="stock" />
//             <Column title="Total Sold" dataIndex="totalSold" key="totalSold" />
//           </Table>
//         </div>
//       </div>

//       {barcodeDetails?.data?.length > 0 && (
//         <Row gutter={16}>
//           <Col sm={24} md={12}>
//             <Card
//               className="mt-4"
//               title="Current Stock"
//               bordered
//               align="center"
//               style={getCardStyle(scannedQuant, barcodeDetails?.data[0]?.stock)}
//             >
//               <div style={{ fontSize: "20px", textAlign: "center" }}>
//                 {barcodeDetails?.data[0]?.stock}
//               </div>
//             </Card>
//           </Col>

//           <Col sm={24} md={12}>
//             <Card
//               className="mt-4"
//               title="Quantity As Per Scan"
//               bordered
//               align="center"
//               style={getCardStyle(scannedQuant, barcodeDetails?.data[0]?.stock)}
//             >
//               <div style={{ fontSize: "20px", textAlign: "center" }}>
//                 {scannedQuant}
//               </div>
//             </Card>
//           </Col>
//         </Row>
//       )}
//     </div>
//   );
// };

// export default LookUpList;
