import React, { Component } from "react";
import {
  Table,
  Popconfirm,
  Tooltip,
  Pagination,
  Button,
  Space,
  Input,
  Row,
  Col,
} from "antd";
import { SearchOutlined, EyeOutlined, EditOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getAllSalesReturnBill,
  getSalesUsingsaleReturnId,
  salesReturnBySearchValue,
  getAllSalesReturnBillBydate,
} from "../../../../../redux/actions/saleAction";
import Highlighter from "react-highlight-words";
import moment from "moment";
import ShowBillsaleReturn from "./showBillsaleReturn";
import { withRouter } from "react-router-dom";
import Search from "../../../../../util/Search";
import DateWiseSearch from "../../../../../util/DateWiseSearch";
import TopBarProgress from "react-topbar-progress-indicator";
import ViewSalesReturnBill from "./ViewSalesReturnBill";
import SalesReturnBillWB from "./SalesReturnBillWB";

const { Column } = Table;
const searchOptions = ["Barcode", "SaleReturnId", "CustomerName"];
class SaleReturnBillList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
      searchValue: "",
    };
  }
  componentDidMount() {
    if (this.props.history.location.state) {
      let localStartDate = localStorage.getItem("startDate");
      let localEnddate = localStorage.getItem("endDate");
      if (localStartDate && localEnddate) {
        let today = moment(new Date()).format("YYYY-MM-DD");
        if (today == localEnddate) {
          let reportObj = {
            startDate: localStartDate,
            endDate: localEnddate + " " + moment().format("HH:mm:ss"),
          };
          this.props.getAllSalesReturnBillBydate(reportObj);
          localStorage.removeItem("startDate");
          localStorage.removeItem("endDate");
        } else {
          const dateMoment = moment(localEnddate, "YYYY-MM-DD");
          const endTime = dateMoment.endOf("day").format("HH:mm:ss");
          const endDateWithEndTime = `${localEnddate} ${endTime}`;
          let reportObj = {
            startDate: localStartDate,
            endDate: endDateWithEndTime,
          };
          this.props.getAllSalesReturnBillBydate(reportObj);
          localStorage.removeItem("startDate");
          localStorage.removeItem("endDate");
        }
      } else {
        let startDate = moment(new Date()).format("YYYY-MM-DD");
        let endDate = moment(new Date()).format("YYYY-MM-DD");
        let reportObj = {
          startDate: startDate,
          endDate: endDate + " " + moment().format("HH:mm:ss"),
        };
        this.props.getAllSalesReturnBillBydate(reportObj);
      }
    } else {
      localStorage.setItem(
        "startDate",
        moment(new Date()).format("YYYY-MM-DD")
      );
      localStorage.setItem("endDate", moment(new Date()).format("YYYY-MM-DD"));
      this.setState({
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
      });
      let startDate = moment(new Date()).format("YYYY-MM-DD");
      let endDate = moment(new Date()).format("YYYY-MM-DD");
      let reportObj = {
        startDate: startDate,
        endDate: endDate + " " + moment().format("HH:mm:ss"),
      };
      this.props.getAllSalesReturnBillBydate(reportObj);
    }

    // ================
    // let startDate = moment(new Date()).format("YYYY-MM-DD")
    // let endDate = moment(new Date()).format("YYYY-MM-DD")
    // let reportObj = {
    //   startDate: startDate,
    //   endDate: endDate + " " + moment().format("HH:mm:ss"),
    // };
    // this.props.getAllSalesReturnBillBydate(reportObj)
    // this.props.getAllSalesReturnBill(1);
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };
  editSaleReturn = async (text, record) => {
    console.log("saleReturn Obj", this.props.salesReturnOrders);
    await this.props.getSalesUsingsaleReturnId(text.saleReturnId);
    console.log("this.props.history", this.props.history);
    this.props.history.push({
      pathname: "/dagaImpex/updateSalesReturn",
      state: {
        id: text._id,
        saleReturnObj: [text, this.props.salesReturnOrders, record],
        type: "Normal",
      },
      from: "/dagaImpex/saleReturnBill",
      // editSaleReturn: true,
    });
  };
  onChange = (event) => {
    this.setState({ searchValue: event });
  };
  cancelState = () => {
    this.setState({ searchValue: "" });
  };
  handleSearchButtonClick = (value) => {
    console.log("value", typeof this.state.searchValue);

    let obj = {
      searchValue: this.state.searchValue,
    };
    this.props.salesReturnBySearchValue(obj);
    console.log("obj--------- numberrr", obj);

    // this.props.userByNameOrUsername(obj)
  };

  convertToIST = (timestampUTC) => {
    const utcTimestamp = timestampUTC;
    const utcDate = new Date(utcTimestamp);
    const istDate = new Date(utcDate.getTime());

    // Format the IST time
    const istTime = istDate.toTimeString().slice(0, 8);

    return istTime;
  };

  listData = () => {
    const { AllSalesReturnBill, salesreturnbillBydate, searchData } =
      this.props;
    const value =
      salesreturnbillBydate && salesreturnbillBydate.length > 0
        ? salesreturnbillBydate[0]
        : [];
    // if (value.message == "Empty") {
    //   return ([])
    // } else if (this.state.searchValue) {
    //   return (searchData.data)
    // } else {
    //   return (salesreturnbillBydate.data)
    // }
    if (this.state.searchValue) {
      const sortedArray = searchData?.data?.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      return sortedArray;
    } else {
      return salesreturnbillBydate.data;
    }
  };
  render() {
    console.log(
      "#this.props.salesReturnListBill",
      this.props.AllSalesReturnBill
    );
    const { searchValue } = this.state;
    const { AllSalesReturnBill, searchData, salesreturnbillBydate } =
      this.props;
    const search = searchData || [];
    const Id = localStorage.getItem("roleName");

    return (
      <div>
        {this.props.progressBarStatus && <TopBarProgress />}
        {/* ==============Search Bar and Date Filter================= */}
        <Row className="title-row">
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="page-header">Sales Return Bill</div>
          </Col>
        </Row>
        <Row className="mt-4">
          {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}> */}
          <Search
            title="Sales Return Bill"
            placeholder="Customer Name, Sale Return Id, Barcode"
            searchopt={searchOptions}
            changeFunction={this.onChange}
            cancelState={this.cancelState}
            location={this.props.location}
          />
          {/* </Col> */}
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <DateWiseSearch
              keyId={"Sales Return Bill"}
              historyProps={this.props}
            />
          </Col>
        </Row>
        {/* ======================================== */}

        <div className="card  final-saleReturns-list">
          {console.log("return list", this.props.AllSalesReturnBill)}
          <div className="card-body">
            <Table
              dataSource={this.listData()}
              bordered="true"
              size="middle"
              pagination={{
                pageSize: 25,
                total: searchData
                  ? searchData?.data?.length
                  : salesreturnbillBydate?.data?.length,
              }}
              scroll={{ y: 440 }}
              rowClassName={(record, index) => {
                if (record?.isDeleted) {
                  return "selected-row-red";
                }
                return "";
              }}
              summary={() => (
                <Table.Summary fixed={"bottom"}>
                  <Table.Summary.Row>
                    <Table.Summary.Cell
                      className="grand-Total-summary"
                      index={1}
                      colSpan={2}
                    >
                      Grand Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1}>
                      {searchData.count
                        ? searchData.count
                        : salesreturnbillBydate && salesreturnbillBydate.count
                        ? salesreturnbillBydate.count
                        : ""}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1}>
                      {}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1}>
                      {" "}
                      {searchData.allQuantity
                        ? searchData.allQuantity
                        : salesreturnbillBydate &&
                          salesreturnbillBydate.allQuantity
                        ? salesreturnbillBydate.allQuantity
                        : ""}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1}>
                      {searchData.allTotalAmount
                        ? searchData.allTotalAmount
                        : salesreturnbillBydate &&
                          salesreturnbillBydate.allTotalAmount
                        ? parseInt(
                            salesreturnbillBydate.allTotalAmount
                          ).toFixed()
                        : ""}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )}
            >
              {console.log("test", this.props.AllSalesReturnBill)}
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Date"
                dataIndex="created_at"
                key="created_at"
                width={130}
                render={(value, item, index) =>
                  moment(item.created_at).format("YYYY-MM-DD")
                }
              />

              <Column
                title="Sale Return Id"
                dataIndex="saleReturnId"
                key="saleReturnId"
                width={150}
                {...this.getColumnSearchProps("saleReturnId")}
              />

              <Column
                title="Customer Name"
                dataIndex="userId"
                key="name"
                width={150}
                //render={ (userId) => userId.map(userId => userId.name).join()}
                // render={ item => Object.keys(item)[0]}
                render={(value, item, index) => `${item?.userId?.name}`}
                // {...this.getColumnSearchProps("userId")}
              />
              <Column
                title="Total Qnty"
                dataIndex="totalQuantity"
                key="totalQuantity"
                width={120}
              />
              <Column
                title="Total Amount"
                dataIndex="totalAmount"
                key="totalAmount"
                width={140}
              />
              <Column
                title="GR Creation Time"
                dataIndex="created_at"
                key="created_at"
                width={170}
                render={(value, item, index) =>
                  this.convertToIST(item?.created_at)
                }
              />
              {/* <Column
                title="Total Boxes"
                dataIndex="totalBoxes"
                key="totalBoxes"
                width={90}
              /> */}
              <Column
                title="Customer Remark"
                dataIndex="remark"
                key="remark"
                width={170}
                // render={(value, item, index) => `${ item?.saleBillData?.remark}`}
              />
              <Column
                title="Store Remark"
                dataIndex="remark2"
                key="remark2"
                width={140}
                // render={(value, item, index) => `${ item?.saleBillData?.remark}`}
              />
              <Column
                title="Action"
                key="action"
                width={150}
                fixed="right"
                render={(text, record) => {
                  if (Id === "DME" || Id === "CRM") {
                    return (
                      <Row
                        className={record?.isDeleted ? "custom-diabled" : ""}
                      >
                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          align="center  "
                        >
                          <a>
                            <ShowBillsaleReturn
                              saleReturnId={record?.saleReturnId}
                              data={record}
                              text={text}
                            />
                          </a>
                        </Col>
                      </Row>
                    );
                  } else {
                    return (
                      <Row
                        className={record?.isDeleted ? "custom-diabled" : ""}
                      >
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Popconfirm
                            title="Are you sure to Edit this bill?"
                            onConfirm={() => this.editSaleReturn(text, record)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <div className="btn">
                              <Tooltip title=" Edit Bill">
                                <EditOutlined />
                              </Tooltip>
                            </div>
                          </Popconfirm>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <div className="btn">
                            <ViewSalesReturnBill
                              text={text}
                              record={record}
                              saleObj={[]}
                            />
                          </div>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <a>
                            <ShowBillsaleReturn
                              saleReturnId={record?.saleReturnId}
                              data={record}
                              text={text}
                            />
                          </a>
                        </Col>
                      </Row>
                    );
                  }
                }}
              />

            {Id !== "CRM" &&   <Column
                title="Send Bill"
                key="action"
                width={150}
                fixed="right"
                render={(text, record) => {
                  if (Id !== "DME") {
                    return (
                      <Row
                        className={record?.isDeleted ? "custom-diabled" : ""}
                      >
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <div className="btn">
                            <SalesReturnBillWB
                              saleReturnId={record?.saleReturnId}
                              record={record}
                              text={text}
                            />
                          </div>
                        </Col>
                        
                        </Row>
                    );
                  }
                }}
              />
                        }
                
            </Table>{" "}
            {/* <div className=" mt-4">
              <div className="col-md-12 ml-auto">
                <div className="grand-totall">
                  <div className="title-wrap">
                    <h4 className="cart-bottom-title section-bg-gary-cart">
                      Sales Return Bill Total
                    </h4>
                  </div>
                  <h5>
                    Total Bills{" "}
                    <span>
                      {searchData.count ? searchData.count : salesreturnbillBydate && salesreturnbillBydate.count ? salesreturnbillBydate.count : ""}
                    </span>
                  </h5>
                  <h5>
                    Total Boxes{" "}
                    <span>
                      {searchData.allBoxes ? searchData.allBoxes : salesreturnbillBydate && salesreturnbillBydate.allBoxes ? salesreturnbillBydate.allBoxes : ""}
                    </span>
                  </h5>
                  <h5>
                    Total Quantity{" "}
                    <span>
                      {searchData.allQuantity ? searchData.allQuantity : salesreturnbillBydate && salesreturnbillBydate.allQuantity ? salesreturnbillBydate.allQuantity : ""}
                    </span>
                  </h5>
                  <h4 className="grand-totall-title">
                    Total Amount{" "}
                    <span>
                      {searchData.allTotalAmount ? searchData.allTotalAmount : salesreturnbillBydate && salesreturnbillBydate.allTotalAmount ? parseInt(salesreturnbillBydate.allTotalAmount).toFixed() : ""}
                    </span>
                  </h4>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

SaleReturnBillList.propTypes = {
  getAllSalesReturnBill: PropTypes.func.isRequired,
  getSalesUsingsaleReturnId: PropTypes.func.isRequired,
  salesReturnBySearchValue: PropTypes.func.isRequired,
  getAllSalesReturnBillBydate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    AllSalesReturnBill: state.saleData.AllSalesReturnBill,
    salesReturnOrders: state.saleData.salesReturnOrders,
    searchData: state.saleData.saleSearchData,
    salesreturnbillBydate: state.saleData.salesreturnbillBydate,
    progressBarStatus: state.progressBarReducer.showProgressBar,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getAllSalesReturnBill,
    getSalesUsingsaleReturnId,
    salesReturnBySearchValue,
    getAllSalesReturnBillBydate,
  })(SaleReturnBillList)
);
