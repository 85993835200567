import React, { Component } from "react";
import {
  Table,
  Popconfirm,
  Tooltip,
  Pagination,
  Button,
  Space,
  Input,
  Row,
  Col,
} from "antd";
import {
  DeleteOutlined,
  FileDoneOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getSalesReturnsDefected, getSalesReturnsDefectedBydate } from "../../../../redux/actions/saleAction.js";
import Highlighter from "react-highlight-words";
import moment from "moment";
import DateWiseSearch from "../../../../util/DateWiseSearch.js";
import TopBarProgress from "react-topbar-progress-indicator";
import ShowBillDefectiveSalesReturn from "./showBillDefectiveSalesReturn.js";
const { Column } = Table;

class SalesReturnsDefected extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
    };
  }
  componentDidMount() {
    let startDate = moment(new Date()).format("YYYY-MM-DD")
    let endDate = moment(new Date()).format("YYYY-MM-DD")
    let reportObj = {
      startDate: startDate,
      endDate: endDate + " " + moment().format("HH:mm:ss"),
    };
    // this.props.getSalesReturnsDefected(1);
    this.props.getSalesReturnsDefectedBydate(reportObj)

  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  listData = () => {
    const { salesreturndefectedBydate } = this.props
    const value = salesreturndefectedBydate && salesreturndefectedBydate.length > 0 ? salesreturndefectedBydate[0] : []
    if (value.message == "Empty") {
      return ([])
    } else if (salesreturndefectedBydate.data) {
      return (salesreturndefectedBydate.data)
    } else {
      return (this.props.saleReturnsDefected.data)
    }

  }

  render() {
    console.log("#saleReturns", this.props.saleReturnsDefected);
    const { salesreturndefectedBydate } = this.props;
    return (
      <div className="subCategory-dashboard">
        {/* {this.props.progressBarStatus && <TopBarProgress />} */}
        <div className="container-fluid category-list">

          {/* ==============Search Bar and Date Filter================= */}
          <Row className="title-row">
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className="page-header">Sales Return Defective</div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <DateWiseSearch keyId={"Sales Return Defective"} />
            </Col>
          </Row>
          {/* ======================================== */}

          <div className="card ">
            <div className="card-body">
              {console.log("testing div", this.props.saleReturnsDefected)}
              {this.props.progressBarStatus && <TopBarProgress />}
              <Table
                dataSource={this.listData()}
                // dataSource={salesreturndefectedBydate.data ? salesreturndefectedBydate.data : this.props.saleReturnsDefected && this.props.saleReturnsDefected.data ? this.props.saleReturnsDefected.data : []}
                bordered="true"
                size="middle"
                pagination={{
                  pageSize: 10,
                  total: this.props.saleReturnsDefected && this.props.saleReturnsDefected.count ? this.props.saleReturnsDefected.count : "",
                  onChange: (page) => {
                    this.props.getSalesReturnsDefected(page);
                  },
                }}
                scroll={{ y: 400 }}
              >
                <Column
                  title="SRN"
                  key="index"
                  render={(value, item, index) => index + 1}
                  width={60}
                />
                <Column
                  title="Bill Date"
                  dataIndex="date"
                  key="date"
                  width={130}
                  render={(value, item, index) =>
                    moment(item.date).format("YYYY-MM-DD")
                  }
                />
                <Column
                  title="Brand"
                  dataIndex="brand"
                  key="brand"
                  width={100}
                />
                <Column
                  title="Dealer Name"
                  {...this.getColumnSearchProps("dealerName")}
                  dataIndex="dealerName"
                  key="dealerName"
                  width={150}
                  render={(value, item, index) => `${item.userId.name}`}
                />

                <Column
                  title="Qnty"
                  dataIndex="quantity"
                  key="quantity"
                  width={100}
                />

                <Column
                  title="Amount"
                  dataIndex="amount"
                  key="amount"
                  width={120}
                />
                <Column
                  title="Total Amount"
                  dataIndex="amount"
                  key="amount"
                  width={120}
                  render={(value, item, index) => Number(item.quantity * item.amount)}
                />
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SalesReturnsDefected.propTypes = {
  getSalesReturnsDefected: PropTypes.func.isRequired,
  getSalesReturnsDefectedBydate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  saleReturnsDefected: state.saleData.saleReturnsDefected,
  salesreturndefectedBydate: state.saleData.salesreturndefectedBydate,
  progressBarStatus: state.progressBarReducer.showProgressBar
});

export default connect(mapStateToProps, {
  getSalesReturnsDefected, getSalesReturnsDefectedBydate
})(SalesReturnsDefected);
