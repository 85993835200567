import React, { Component, useState } from "react";
import { Row, Col, Breadcrumb, AutoComplete, Card } from "antd";
import AddRack from "./AddRack";
import RackList from "./RackList";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getRacks } from "../../../../redux/actions/rackManagementAction";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function Rack() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        dispatch(getRacks())
            .finally(() => {
                setLoading(false)
            })

    }, []);

    return (
        <>
            <Card className="subCategory-dashboard">
                <div className="container-fluid category-list">
                    <Row className="title-row">
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <div className="page-header">
                                <Link to="/dagaImpex/add-details" className="mr-4">
                                    <ArrowLeftOutlined />
                                </Link>
                                Rack</div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} align="end">
                            <AddRack />
                        </Col>
                    </Row>
                    <RackList loading={loading} />
                </div>
            </Card>
        </>
    )
}

export default Rack