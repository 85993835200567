import React, { Component } from "react";
import { Table, Button, Space, Input, Tag } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
// import { getTopCustomerReports } from "../../../../redux/actions/getTopCustomerReports";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { CSVLink } from "react-csv";
const { Column } = Table;
const data = [];

export class TopCustomerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      data: [],
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  render() {
    const { topCustomerReport } = this.props;
    const headers = [
      { label: "Name", key: "name" },
      { label: "Mobile No", key: "mobileNumber" },
      { label: "Email", key: "email" },
      { label: "State", key: "state" },
      { label: "Total Sale Amount", key: "totalNetAmount" },
      { label: "Total Bill", key: "salesCount" },
    ];

    const formattedData =
      topCustomerReport?.data?.flatMap((item) =>
        item.userData?.map((user) => ({
          name: user.name || "",
          mobileNumber: user.mobileNumber || "",
          email: user.email || "",
          state: user.state || "",
          totalNetAmount: item.totalNetAmount || 0, // Take from parent object
          salesCount: item.salesCount || 0, // Take from parent object
        }))
      ) || [];

    return (
      <>
        <div className="card mt-2 final-purchases-list">
          <div className="card-body">
            <Table
              dataSource={formattedData || []}
              // dataSource={  []}
              bordered="true"
              size="middle"
              scroll={{ y: 240 }}
              pagination={false}
              //   pagination={{
              //     pageSize: 10,
              //     total: this.props.reports.count,
              //     onChange: (page) => {
              //       this.props.getStockAgeingReports(page);
              //     },
              //   }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Name"
                // {...this.getColumnSearchProps("name")}
                dataIndex="name"
                key="name"
                width={100}
                // render={(value, item, index) => item?.userData[0]?.name}
              />
              <Column
                title="Mobile Number"
                // {...this.getColumnSearchProps("mobileNumber")}
                dataIndex="mobileNumber"
                key="mobileNumber"
                width={130}
                // render={(value, item, index) => item?.userData[0]?.mobileNumber}
              />
              <Column
                title="Email"
                dataIndex="email"
                key="email"
                width={100}
                // render={(value, item, index) => item?.userData[0]?.email}
              />
              <Column
                title="State"
                dataIndex="state"
                key="state"
                width={90}
                // render={(value, item, index) => item?.userData[0]?.state}
              />
              <Column
                title="Total Sale Amount"
                dataIndex="totalNetAmount"
                key="totalNetAmount"
                width={100}
                // render={(value, item, index) =>
                //   // Number(item?.totalNetAmount).toFixed()
                // }
              />
              <Column
                title="Total Bill"
                dataIndex="salesCount"
                key="salesCount"
                width={100}
                // render={(value, item, index) => Number(item?.totalNetAmount).toFixed()}
              />
            </Table>
          </div>
        </div>
        {(() => {
          if (formattedData?.length > 0) {
            return (
              <Button
                type="primary"
                htmlType="submit"
                className="save-modal mt-4"
                block
              >
                <CSVLink
                  filename={`Top_Customer_Report.csv`}
                  data={formattedData || []}
                  headers={headers}
                >
                  Download CSV
                </CSVLink>
              </Button>
            );
          }
        })()}
      </>
    );
  }
}

TopCustomerList.propTypes = {
  // getTopCustomerReports: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  topCustomerReport: state.reportData.topCustomerReport,
});

export default connect(mapStateToProps, {
  // getTopCustomerReports,
})(TopCustomerList);
