/* /////////////////////////////////////// */
/*
Date              created by               Modifications
 
25-07-2023        Mohit                    Add new Menu itme agent wise report
26-07-2023        Mohit                    manage role for ecom sales

*/
/* /////////////////////////////////////// */

import React, { Component } from "react";
import { Layout, Menu, Dropdown, Avatar, Drawer, Tooltip } from "antd";
import { NavLink } from "react-router-dom";
import {
  MenuUnfoldOutlined,
  PlusCircleOutlined,
  HomeOutlined,
  UserAddOutlined,
  DollarOutlined,
  DeleteOutlined,
  DollarCircleOutlined,
  BarcodeOutlined,
  FileSearchOutlined,
  InteractionOutlined,
  SwapOutlined,
  ShoppingCartOutlined,
  ShopOutlined,
  UploadOutlined,
  DeliveredProcedureOutlined,
  PicLeftOutlined,
  GoldOutlined
} from "@ant-design/icons";
import logo from "../../assets/images/Daga_Impex_Logo.png";
import jwt_decode from "jwt-decode";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const { SubMenu } = Menu;
let SubMenukey = "";
export default class SiderDrawerPhone extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false, placement: "left" };
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    const storedToken = localStorage.getItem("token");
    let decToken = "";
    if (storedToken) {
      decToken = jwt_decode(storedToken);
    }
    return (
      <span>
        <MenuUnfoldOutlined
          onClick={this.showDrawer}
          className="trigger-phone"
        />
        <Drawer
          placement={this.state.placement}
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          key={this.state.placement}
        >
          <div className="sider-menu">
            <div className="logo-admin">
              <img
                src={logo}
                alt="Logo"
                width={150}
                height={100}
                style={{ margin: "60px 0 0px 75px" }}
              />
            </div>
            <div className="profile-section-sider mb-3">
              <Avatar
                style={{
                  color: "#0060aa",
                  backgroundColor: "#afe7fc",
                  letterSpacing: "1px",
                }}
                size={60}
                className="mt-4"
              >
                {(this.props?.user?.name ?? "Unknown")
                  .match(/(^\S\S?|\b\S)?/g)
                  .join("")
                  .match(/(^\S|\S$)?/g)
                  .join("")
                  .toUpperCase()}
              </Avatar>
              <p className="profile-name-sider" title="  Daga Impex">
                {this.props.user.name}
              </p>
              <p
                className="profile-email-sider"
                title=" daga.impex@dagaimpex.com"
              >
                {this.props.user.email}
              </p>
            </div>
            {(() => {
              if (decToken.role === "6227b38275361cd819e16067") {
                //ADMIN
                return (
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={["/dagaImpex"]}
                  >
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex"
                      icon={<HomeOutlined />}
                    >
                      <NavLink className="sider-links " to="/dagaImpex">
                        Dashboard
                      </NavLink>
                    </Menu.Item>
                    {/* <SubMenu
                      key="2"
                      icon={<PlusCircleOutlined />}
                      title="Add Details"
                    >
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/dealers"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/dealers"
                        >
                          <i className="fas fa-user-tie"></i> Dealers
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item onClick={this.onClose} key="/dagaImpex/brands">
                        <NavLink className="sider-links" to="/dagaImpex/brands">
                          <i className="fas fa-trademark"></i> Brands
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/category"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/category"
                        >
                          <i className="fas fa-tshirt"></i> Category
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item onClick={this.onClose} key="/dagaImpex/color">
                        <NavLink className="sider-links" to="/dagaImpex/color">
                          <i className="fas fa-fill-drip"></i> Color
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item onClick={this.onClose} key="/dagaImpex/group">
                        <NavLink className="sider-links" to="/dagaImpex/group">
                          <i className="fas fa-sitemap"></i> Group
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/location"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/location"
                        >
                          <i className="fas fa-map-marked-alt"></i> Location
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item onClick={this.onClose} key="/dagaImpex/season">
                        <NavLink className="sider-links" to="/dagaImpex/season">
                          <i className="fas fa-sun"></i> Season
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/section"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/section"
                        >
                          <i className=" fas fa-boxes"></i> Section
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/texture"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/texture"
                        >
                          <i className=" fas fa-boxes"></i> Texture
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item onClick={this.onClose} key="/dagaImpex/style">
                        <NavLink className="sider-links" to="/dagaImpex/style">
                          <i className="fas fa-magic"></i> Style
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item onClick={this.onClose} key="/dagaImpex/size">
                        <NavLink className="sider-links" to="/dagaImpex/size">
                          <i className="fas fa-cut"></i> Size
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/ageGroup"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/ageGroup"
                        >
                          <i className="fas fa-users"></i> Age Group
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item onClick={this.onClose} key="/dagaImpex/floor">
                        <NavLink className="sider-links" to="/dagaImpex/floor">
                          <i className="fas fa-warehouse"></i> Floor
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item key="/dagaImpex/packaging-method" onClick={this.onClose}>
                    <NavLink className="sider-links " to="/dagaImpex/packaging-method">
                    <i className="fas fa-box"></i> Packaging Method
                    </NavLink>
                  </Menu.Item>
                    </SubMenu> */}
                    <Menu.Item key="/dagaImpex/add-details" title="Report">
                      <NavLink className="sider-links " to="/dagaImpex/add-details">
                        <FileSearchOutlined /> Add Details
                      </NavLink>
                    </Menu.Item>
                    <SubMenu
                      key="12"
                      icon={<DollarCircleOutlined />}
                      title="Purchase"
                    >
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/addPurchase"
                        title="Add Purchase"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/addPurchase"
                        >
                          <i className="fas fa-cart-arrow-down"></i> Add
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/purchase"
                        title="Purcahse List"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/purchase"
                        >
                          <i className="far fa-file"></i> List
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item
                        key="/dagaImpex/purchaseBill"
                        title="Purcahse Bill"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/purchaseBill"
                        >
                          <i className="far fa-file-alt"></i> Purcahse Bill
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu key="3" icon={<DollarOutlined />} title="Sales">
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/addSales"
                        title="Add Sale"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/addSales"
                        >
                          <i className="far fa-file"></i> Add Sales
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/salesOrder"
                        title="Sales Orders"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/salesOrder"
                        >
                          <i className="far fa-file-alt"></i> Sales Orders
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/salesList"
                        title="Sales List"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/salesList"
                        >
                          <i className="far fa-file-alt"></i> Sales List
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/salesPdf"
                        title="Sales List"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/salesPdf"
                        >
                          <i className="far fa-file-alt"></i> Sales PDF
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/mergeSalesBill"
                        title="Merge Bill"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/mergeSalesBill"
                        >
                          <i className="far fa-file-alt"></i> Merge Bill
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="/dagaImpex/warehouse"
                      icon={<ShopOutlined />}
                      title="Warehouse"
                    >
                      <Menu.Item
                        key="/dagaImpex/warehouse"
                        icon={<ShopOutlined />}
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/warehouse"
                        >
                          Warehouse List
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/warehouse/inventories"
                        icon={<PlusCircleOutlined />}
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/warehouse/addwarehousesale"
                        >
                          Add Sale
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>

                    <SubMenu
                      key="b3"
                      icon={<BarcodeOutlined />}
                      title="Barcode"
                    >
                      <Menu.Item
                        key="/dagaImpex/generator"
                        title="Generate Barcode"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/generator"
                        >
                          <i className="fas fa-print"></i> Generate Barcode
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/sample-barcode"
                        title="Sample Barcode"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/sample-barcode"
                        >
                          <i className="fas fa-print"></i> Sample Barcode
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="b4"
                      icon={<FileSearchOutlined />}
                      title="BI Report"
                    >
                      {/* <Menu.Item
                        key="/dagaImpex/BIreport/report-1"
                        title="Generate Barcode"
                      >
                        <NavLink className="sider-links" to="/dagaImpex/BIreport/report-1">
                          Report-1
                        </NavLink>
                      </Menu.Item> */}
                      <Menu.Item
                        key="/dagaImpex/BIreport/grossprofitreport"
                        title="Generate Barcode"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/BIreport/grossprofitreport"
                        >
                          {/* <i className="fas fa-print"></i>  */}
                          Gross Profit
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/BIreport/stockageReport"
                        title="Stock Age Report"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/BIreport/stockageReport"
                        >
                          {/* <i className="fas fa-print"></i>  */}
                          Stock Age Report
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/BIreport/sales-and-salesAge-Report"
                        title="Sales and Sales Age Report"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/BIreport/sales-and-salesAge-Report"
                        >
                          Sales and Sales Age Report
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/inventory"
                      title="Inventory"
                    >
                      <NavLink
                        className="sider-links "
                        to="/dagaImpex/inventory"
                      >
                        <i className=" fas fa-dolly-flatbed"></i> Inventory
                      </NavLink>
                    </Menu.Item>
                    {/* <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/rack-management"
                      title="Rack Management"
                    >
                      <NavLink
                        className="sider-links "
                        to="/dagaImpex/rack-management"
                      >
                        <PicLeftOutlined /> Rack Management
                      </NavLink>
                    </Menu.Item> */}
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/stock-verification-details"
                      title="Stock Verification Details"
                    >
                      <NavLink
                        className="sider-links "
                        to="/dagaImpex/stock-verification-details"
                      >
                        <i className=" fas fa-dolly-flatbed"></i> Stock
                        Verification Details
                      </NavLink>
                    </Menu.Item>
                    {/* <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/availableStock" title="Available Stock">
                      <NavLink className="sider-links" to="/dagaImpex/availableStock">
                        <StockOutlined /> Available Stock
                      </NavLink>
                    </Menu.Item> */}
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/openingStock"
                      title="Opening Stock"
                    >
                      <NavLink
                        className="sider-links "
                        to="/dagaImpex/openingStock"
                      >
                        <i className=" fas fa-plus-square"></i> Opening Stock
                      </NavLink>
                    </Menu.Item>
                    <SubMenu
                      key="sr1"
                      icon={<InteractionOutlined />}
                      title="Sales Return"
                    >
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/addSalesReturn"
                        title="Add Return"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/addSalesReturn"
                        >
                          <i className="far fa-file"></i> Add Return
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/salesReturns"
                        title="Sale Returns"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/salesReturns"
                        >
                          <i className="far fa-file-alt"></i> Sales Return
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/saleReturnBill"
                        title="SaleReturn Bill"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/saleReturnBill"
                        >
                          <i className="far fa-file-alt"></i> Bill Sale Return
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/salesReturnsDefective"
                        title="Defective Return Sales"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/salesReturnsDefective"
                        >
                          <i className="far fa-file-alt"></i> Defective Return
                          Sales
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/defectedProductBill"
                        title="DefectedProduct Bill"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/defectedProductBill"
                        >
                          <i className="far fa-file-alt"></i>Bill Defective
                          Return
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="pr1"
                      icon={<SwapOutlined />}
                      title="Purchase Return"
                    >
                      <Menu.Item
                        key="/dagaImpex/addPurchaseReturn"
                        title="Add Return"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/addPurchaseReturn"
                        >
                          <i className="far fa-file"></i> Add Return
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/purchaseReturns"
                        title="Purchase Returns"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/purchaseReturns"
                        >
                          <i className="far fa-file-alt"></i> Purchase Return
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/purchaseReturnBillList"
                        title="Purchase Returns Bill"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/purchaseReturnBillList"
                        >
                          <i className="far fa-file-alt"></i> Return Bill
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    {/* <SubMenu
                      key="po"
                      icon={<SwapOutlined />}
                      title="PO Credentials"
                    >
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/PO"
                        title="Add PO Credentials"
                      >
                        <NavLink className="sider-links" to="/dagaImpex/PO">
                          <i className="far fa-file-alt"></i>Create PO
                          Credentials
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/POList"
                        title="PO Credentials List"
                      >
                        <NavLink className="sider-links" to="/dagaImpex/POList">
                          <i className="far fa-file-alt"></i>PO Credentials List
                        </NavLink>
                      </Menu.Item>
                    </SubMenu> */}
                    <SubMenu
                      key="ecom1"
                      icon={<ShoppingCartOutlined />}
                      title="E-Comm"
                    >
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/flashSales"
                        title="E-com Offer "
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/flashSales"
                        >
                          <i className="fa fa-shopping-cart"></i> E-com Offer
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/ecomProduct"
                        title="E-com Offer "
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/ecomProduct"
                        >
                          <i className="fa fa-shopping-cart"></i> E-com Products
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/ecomOnlineSales"
                        title="E-com Cash Payment"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/ecomOnlineSales"
                        >
                          <i className="fa fa-shopping-cart"></i> Cash Payment
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item
                        key="/dagaImpex/ecomSaleCreditPayment"
                        title="Credit Payment"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/ecomSaleCreditPayment"
                        >
                          <i className="fa fa-shopping-cart"></i> Credit Payment
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item
                        key="/dagaImpex/ecomSaleOnlinePayment"
                        title="Online Payment"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/ecomSaleOnlinePayment"
                        >
                          <i className="fa fa-shopping-cart"></i> Online Payment
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/ecomSalesReturn"
                        title="Ecom Sales Return"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/ecomSalesReturn"
                        >
                          <i className="fa fa-shopping-cart"></i> Ecom Sales
                          Return
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu key="4" icon={<UserAddOutlined />} title="Users">
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/agentlist"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/agentlist"
                        >
                          <i className="fas fa-user-tie"></i> Agent List
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/addUser"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/addUser"
                        >
                          <i className="fas fa-user-plus"></i> Add User
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/listUsers"
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/listUsers"
                        >
                          <i className="fas fa-users"></i> User List
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/customers"
                        title="Customers List"
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/customers"
                        >
                          <i className="fas fa-users"></i> Customers List
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/employees"
                        title="Employees List"
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/employees"
                        >
                          <i className="fas fa-user-friends"></i> Employees List
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/settleGR"
                        title="Settle GR Amount"
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/settleGR"
                        >
                          <i className="fas fa-rupee-sign"></i> Settle GR Amount
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/changeCategories"
                      title="Change All"
                    >
                      <NavLink
                        className="sider-links "
                        to="/dagaImpex/changeCategories"
                      >
                        Change Categories
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/bannerUpload"
                      title="Upload Bannner"
                    >
                      <NavLink
                        className="sider-links "
                        to="/dagaImpex/bannerUpload"
                      >
                        <UploadOutlined /> Upload Bannner
                      </NavLink>
                    </Menu.Item>{" "}
                    <SubMenu
                      key="rps2"
                      icon={<DeliveredProcedureOutlined />}
                      title="Upload LR"
                    >
                      <Menu.Item
                        key="/dagaImpex/addLR"
                        title="Add LR"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/addLR"
                        >
                          <i className=" fas fa-arrow-right"></i> Add LR
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/LRList"
                        title="LR List"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/LRList"
                        >
                          <i className=" fas fa-arrow-right"></i> LR List
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/report"
                      title="Report"
                    >
                      <NavLink className="sider-links " to="/dagaImpex/report">
                        <FileSearchOutlined /> Reports
                      </NavLink>
                    </Menu.Item>
                    {/* <Menu.Item
                        key="/dagaImpex/salesageingReport"
                        title="Inactive Customer Report"
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/salesageingReport"
                        >
                          <i className="fas fa-rupee-sign"></i> Sales Ageing Report
                        </NavLink>
                      </Menu.Item> */}
                    {/* ====================================|| Reports ||================================= */}
                    {/* <SubMenu
                  key="rps1"
                  icon={<FileSearchOutlined />}
                  title="Reports"
                >
                  <Menu.Item
                    key="/dagaImpex/CreditandDebitReport"
                    title="Credit and Debit Report"
                  >
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/CreditandDebitReport"
                    >
                      <i className="fas fa-rupee-sign"></i> Credit and Debit Report
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key="/dagaImpex/salesCustomerReport"
                    title="Sales Customer Report"
                  >
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/salesCustomerReport"
                    >
                      <i className="fas fa-user-clock"></i> Sales Customer Report
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key="/dagaImpex/purchaseBillReport"
                    title="Purchase Bill Report"
                  >
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/purchaseBillReport"
                    >
                      <i className="fas fa-cart-plus"></i> Purchase Bill Report
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item
                    key="/dagaImpex/purchaseReport"
                    title="Purchase Report"
                  >
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/purchaseReport"
                    >
                      <i className="fas fa-money"></i> Purchase Report
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key="/dagaImpex/salesSummaryReport"
                    title="Sales Summary Report"
                  >
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/salesSummaryReport"
                    >
                      <i className="fas fa-rupee-sign"></i> Sales Summary Report
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key="/dagaImpex/dealerPurchaseReport"
                    title="Dealer Purchases Report"
                  >
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/dealerPurchaseReport"
                    >
                      <i className="fas fa-user-tag"></i> Dealer Purchases Report
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key="/dagaImpex/stockAgeingReport"
                    title="Stock Ageing Report"
                  >
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/stockAgeingReport"
                    >
                      <i className=" fas fa-dolly-flatbed"></i> Stock Ageing
                      Report
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key="/dagaImpex/topCustomerReport"
                    title="Top Customer Report"
                  >
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/topCustomerReport"
                    >
                      <i className=" fas fa-award"></i> Top Customer Report
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="/dagaImpex/salesReport" title="Sales Report">
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/salesReport"
                    >
                      <i className=" fas fa-bookmark"></i> Sales Report
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item key="/dagaImpex/stockReport" title="Stock Report">
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/stockReport"
                    >
                      <i className=" fas fa-bookmark"></i> Stock Report
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key="/dagaImpex/inactiveCustomerReport"
                    title="Inactive Customer Report"
                  >
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/inactiveCustomerReport"
                    >
                      <i className="far fa-file-alt"></i> Inactive Customer
                      Report
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key="/dagaImpex/agentWiseReport"
                    title="Agent Wise Report"
                  >
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/agentWiseReport"
                    >
                      <i className="fas fa-user-clock"></i> Agent Wise Report
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item
                    key="/dagaImpex/barcodeReport"
                    title="Barcode Report"
                  >
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/barcodeReport"
                    >
                      <i className="fa fa-barcode"></i> Barcode Report
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key="/dagaImpex/bestSellingItemReport"
                    title="Best Selling Item Report"
                  >
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/bestSellingItemReport"
                    >
                      <i className="fas fa-trophy"></i> Best Selling Item Report
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key="/dagaImpex/salesPersonReport"
                    title="Sales Person Report"
                  >
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/salesPersonReport"
                    >
                      <i className="fas fa-user-tie"></i> Sales Person Report
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key="/dagaImpex/salesPersonComparisionReport"
                    title="Sales Person Comparision Report"
                  >
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/salesPersonComparisionReport"
                    >
                      <i className="fas fa-user-tie"></i> Sales Person Comparision
                      Report
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key="/dagaImpex/salesReturnReport"
                    title="Sales Return Report"
                  >
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/salesReturnReport"
                    >
                      <i className="fas fa-store-alt-slash"></i> Sales Return Report
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key="/dagaImpex/purchaseReturnReport"
                    title="Purchase Return Report"
                  >
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/purchaseReturnReport"
                    >
                      <i className="fas fa-store-alt-slash"></i> Purchase Return
                      Report
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key="/dagaImpex/fastMovingItemsReport"
                    title="Fast Moving Items Report"
                  >
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/fastMovingItemsReport"
                    >
                      <i className="fas fa-shipping-fast"></i> Fast Moving Items
                      Report
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key="/dagaImpex/nonMovingItemsReport"
                    title="Non Moving Items Report"
                  >
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/nonMovingItemsReport"
                    >
                      <i className="fas fa-boxes"></i> Non Moving Items Report
                    </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key="/dagaImpex/customerReport"
                    title="Customer Report"
                  >
                    <NavLink
                      className="sider-links "
                      to="/dagaImpex/customerReport"
                    >
                      <i className="fas fa-user-tie"></i>Customer Report
                    </NavLink>
                  </Menu.Item>
                </SubMenu> */}
                    {/* <SubMenu key="b46" icon={<FileSearchOutlined />} title="Recycle Bin">
                      <Menu.Item
                        key="/dagaImpex/recycle/purchaseReturn"
                        title="Recycle Purchase Return"
                        onClick={this.onClose}
                      >
                        <Tooltip title="Recycle Purchase Return" placement="right">
                          <NavLink className="sider-links" to="/dagaImpex/recycle/purchaseReturn">
                            Recycle Purchase Return
                          </NavLink>
                        </Tooltip>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/recycle/sales"
                        title="Recycle Sales"
                        onClick={this.onClose}
                      >
                        <Tooltip title="Recycle Sales" placement="right">
                          <NavLink className="sider-links" to="/dagaImpex/recycle/sales">
                            Recycle Sales
                          </NavLink>
                        </Tooltip>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/recycle/salesReturn"
                        title="Recycle Sales Return"
                        onClick={this.onClose}
                      >
                        <Tooltip title="Recycle Sales Return" placement="right">
                          <NavLink className="sider-links" to="/dagaImpex/recycle/salesReturn">
                            Recycle Sales Return
                          </NavLink>
                        </Tooltip>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/recycle/salesReturnDefective"
                        title="Recycle Sales Return Defective"
                        onClick={this.onClose}
                      >
                        <Tooltip title="Recycle Sales Return Defective" placement="right">
                          <NavLink className="sider-links" to="/dagaImpex/recycle/salesReturnDefective">
                            Recycle Sales Return Defective
                          </NavLink>
                        </Tooltip>
                      </Menu.Item>
                    </SubMenu> */}
                    {/* <SubMenu
                      key="rps2"
                      icon={<DeleteOutlined />}
                      title="Delete Bills"
                    >
                      <Menu.Item key="/dagaImpex/deletePurchase" title="Delete Purchase" onClick={this.onClose}>
                        <NavLink className="sider-links " to="/dagaImpex/deletePurchase">
                          <i className=" fas fa-trash"></i> Delete Purchase Bill
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item key="/dagaImpex/deleteSales" title="Delete Sales" onClick={this.onClose}>
                        <NavLink className="sider-links " to="/dagaImpex/deleteSales">
                          <i className=" fas fa-trash"></i> Delete Sales Bill
                        </NavLink>
                      </Menu.Item>
                    </SubMenu> */}
                  </Menu>
                );
              } else if (decToken.role === "629346b53e2dcbf2f6d43763") {
                //SALES_PERSON
                return (
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={["/dagaImpex"]}
                  >
                    {" "}
                    <SubMenu key="3" icon={<DollarOutlined />} title="Sales">
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/AddNewSales"
                        title="Add Sale"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/AddNewSales"
                        >
                          <i className="far fa-file"></i> Add Sales
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/salesBillNew"
                        title="Sales List"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/salesBillNew"
                        >
                          <i className="far fa-file-alt"></i> Sales Bills
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/mergeNewBill"
                        title=" Merge Bill"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/mergeNewBill"
                        >
                          <i className="far fa-file-alt"></i> Merge Bill
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/salesApp/inventory"
                      title="Inventory"
                    >
                      <NavLink
                        className="sider-links "
                        to="/dagaImpex/salesApp/inventory"
                      >
                        <i className=" fas fa-dolly-flatbed"></i> Inventory
                      </NavLink>
                    </Menu.Item>
                    {/* <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/salesApp/rack-management"
                      title="Rack Management"
                    >
                      <NavLink
                        className="sider-links "
                        to="/dagaImpex/salesApp/inventory"
                      >
                        <i className=" fas fa-dolly-flatbed"></i> Rack Management
                      </NavLink>
                    </Menu.Item> */}
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/salesApp/stock"
                      title="Inventory"
                    >
                      <NavLink
                        className="sider-links "
                        to="/dagaImpex/salesApp/stock"
                      >
                        <i className="fas fa-layer-group"></i> Stock
                      </NavLink>
                    </Menu.Item>
                    <SubMenu
                      key="rps2"
                      icon={<DeliveredProcedureOutlined />}
                      title="Upload LR"
                    >
                      <Menu.Item
                        key="/dagaImpex/addLR"
                        title="Add LR"
                        onClick={this.onClose}
                      >
                        <NavLink className="sider-links " to="/dagaImpex/addLR">
                          <i className=" fas fa-arrow-right"></i> Add LR
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/LRList"
                        title="LR List"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/LRList"
                        >
                          <i className=" fas fa-arrow-right"></i> LR List
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                  </Menu>
                );
              } else if (decToken.role === "6227b3be2fe1c37b839077b8") {
                //SALES
                return (
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={["/dagaImpex"]}
                  >
                    {" "}
                    {/* <Menu.Item key="/dagaImpex" icon={<HomeOutlined />}>
                      <NavLink className="sider-links " to="/dagaImpex">
                        Dashboard
                      </NavLink>
                    </Menu.Item> */}
                    <SubMenu key="3" icon={<DollarOutlined />} title="Sales">
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/addSales"
                        title="Add Sale"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/addSales"
                        >
                          <i className="far fa-file"></i> Add Sales
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/salesOrder"
                        title="Sales Orders"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/salesOrder"
                        >
                          <i className="far fa-file"></i> Sales Orders
                        </NavLink>
                      </Menu.Item>{" "}
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/salesList"
                        title="Sales List"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/salesList"
                        >
                          <i className="far fa-file-alt"></i> Sales List
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/mergeSalesBill"
                        title="Merge Bill"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/mergeSalesBill"
                        >
                          <i className="far fa-file-alt"></i> Merge Bill
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/inventory"
                      title="Inventory"
                    >
                      <NavLink
                        className="sider-links "
                        to="/dagaImpex/inventory"
                      >
                        <i className=" fas fa-dolly-flatbed"></i> Inventory
                      </NavLink>
                    </Menu.Item>
                    {/* <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/rack-management"
                      title="Rack Management"
                    >
                      <NavLink
                        className="sider-links "
                        to="/dagaImpex/rack-management"
                      >
                        <i className=" fas fa-dolly-flatbed"></i> Rack Management
                      </NavLink>
                    </Menu.Item> */}
                    {/* <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/availableStock" title="Available Stock">
                      <NavLink className="sider-links" to="/dagaImpex/availableStock">
                        <StockOutlined /> Available Stock
                      </NavLink>
                    </Menu.Item> */}
                    <SubMenu
                      key="sr1"
                      icon={<InteractionOutlined />}
                      title="Sales Return"
                    >
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/addSalesReturn"
                        title="Add Return"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/addSalesReturn"
                        >
                          <i className="far fa-file"></i> Add Return
                        </NavLink>
                      </Menu.Item>{" "}
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/salesReturns"
                        title="Sale Returns"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/salesReturns"
                        >
                          <i className="far fa-file-alt"></i> Sales Return
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/saleReturnBill"
                        title="SaleReturn Bill"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/saleReturnBill"
                        >
                          <i className="far fa-file-alt"></i> Bill Sale Return
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/salesReturnsDefective"
                        title="Defective Return Sales"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/salesReturnsDefective"
                        >
                          <i className="far fa-file-alt"></i> Defective Return
                          Sales
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/defectedProductBill"
                        title="DefectedProduct Bill"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/defectedProductBill"
                        >
                          <i className="far fa-file-alt"></i>Bill Defective
                          Return
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu key="4" icon={<UserAddOutlined />} title="Users">
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/addUser"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/addUser"
                        >
                          <i className="fas fa-user-plus"></i> Add User
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/listUsers"
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/listUsers"
                        >
                          <i className="fas fa-users"></i> User List
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                  </Menu>
                );
              } else if (decToken.role === "622a47b0d10a0814f49814aa") {
                //ACCOUNTS
                return (
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={["/dagaImpex"]}
                  >
                    <SubMenu key="3" icon={<DollarOutlined />} title="Sales">
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/salesList"
                        title="Sales List"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/salesList"
                        >
                          <i className="far fa-file-alt"></i> Sales List
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/mergeSalesBill"
                        title="Merge Bill"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/mergeSalesBill"
                        >
                          <i className="far fa-file-alt"></i> Merge Bill
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="sr1"
                      icon={<InteractionOutlined />}
                      title="Sales Return"
                    >
                      {/* <Menu.Item key="/dagaImpex/addSalesReturn" title="Add Return">
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/addSalesReturn"
                        >
                          <i className="far fa-file"></i> Add Return
                        </NavLink>
                      </Menu.Item> */}
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/salesReturns"
                        title="Sale Returns"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/salesReturns"
                        >
                          <i className="far fa-file-alt"></i> Sales Return
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/saleReturnBill"
                        title="SaleReturn Bill"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/saleReturnBill"
                        >
                          <i className="far fa-file-alt"></i> Bill Sale Return
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/salesReturnsDefective"
                        title="Defective Return Sales"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/salesReturnsDefective"
                        >
                          <i className="far fa-file-alt"></i> Defective Return
                          Sales
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/defectedProductBill"
                        title="DefectedProduct Bill"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/defectedProductBill"
                        >
                          <i className="far fa-file-alt"></i>Bill Defective
                          Return
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>

                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/report"
                      title="Report"
                    >
                      <NavLink className="sider-links " to="/dagaImpex/report">
                        <FileSearchOutlined /> Reports
                      </NavLink>
                    </Menu.Item>
                  </Menu>
                );
              } else if (decToken.role === "622a47c4a9618355f53d9449") {
                //PURCHASE
                return (
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={["/dagaImpex"]}
                  >
                    {/* <Menu.Item key="/dagaImpex" icon={<HomeOutlined />}>
                      <NavLink className="sider-links " to="/dagaImpex">
                        Dashboard
                      </NavLink>
                    </Menu.Item> */}
                    <Menu.Item key="/dagaImpex/add-details" title="Report">
                      <NavLink className="sider-links " to="/dagaImpex/add-details">
                        <FileSearchOutlined /> Add Details
                      </NavLink>
                    </Menu.Item>
                    {/* <SubMenu
                      key="2"
                      icon={<PlusCircleOutlined />}
                      title="Add Details"
                    >
                      <Menu.Item onClick={this.onClose} key="/dagaImpex/color">
                        <NavLink className="sider-links" to="/dagaImpex/color">
                          <i className="fas fa-fill-drip"></i> Color
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item onClick={this.onClose} key="/dagaImpex/group">
                        <NavLink className="sider-links" to="/dagaImpex/group">
                          <i className="fas fa-sitemap"></i> Group
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/section"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/section"
                        >
                          <i className=" fas fa-boxes"></i> Section
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/texture"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/texture"
                        >
                          <i className=" fas fa-boxes"></i> Texture
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item onClick={this.onClose} key="/dagaImpex/size">
                        <NavLink className="sider-links" to="/dagaImpex/size">
                          <i className="fas fa-cut"></i> Size
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/ageGroup"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/ageGroup"
                        >
                          <i className="fas fa-users"></i> Age Group
                        </NavLink>
                      </Menu.Item>
                    </SubMenu> */}
                    <SubMenu
                      key="12"
                      icon={<DollarCircleOutlined />}
                      title="Purchase"
                    >
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/addPurchase"
                        title="Add Purchase"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/addPurchase"
                        >
                          <i className="fas fa-cart-arrow-down"></i> Add
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/purchase"
                        title="Purcahse List"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/purchase"
                        >
                          <i className="far fa-file"></i> List
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item
                        key="/dagaImpex/purchaseBill"
                        title="Purcahse Bill"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/purchaseBill"
                        >
                          <i className="far fa-file-alt"></i> Purcahse Bill
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="pr1"
                      icon={<SwapOutlined />}
                      title="Purchase Return"
                    >
                      <Menu.Item
                        key="/dagaImpex/addPurchaseReturn"
                        title="Add Return"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/addPurchaseReturn"
                        >
                          <i className="far fa-file"></i> Add Return
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/purchaseReturns"
                        title="Purchase Returns"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/purchaseReturns"
                        >
                          <i className="far fa-file-alt"></i> Purchase Return
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/purchaseReturnBillList"
                        title="Purchase Returns Bill"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/purchaseReturnBillList"
                        >
                          <i className="far fa-file-alt"></i> Return Bill
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>

                    <SubMenu
                      key="/dagaImpex/warehouse"
                      icon={<ShopOutlined />}
                      title="Warehouse"
                    >
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/warehouse"
                        icon={<ShopOutlined />}
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/warehouse"
                        >
                          Warehouse List
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/warehouse/inventories"
                        icon={<PlusCircleOutlined />}
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/warehouse/addwarehousesale"
                        >
                          Add Sale
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    {/* <SubMenu key="b3" icon={<BarcodeOutlined />} title="Barcode">
                      <Menu.Item
                        key="/dagaImpex/generator"
                        title="Generate Barcode"
                      >
                        <NavLink className="sider-links" to="/dagaImpex/generator">
                          <i className="fas fa-print"></i> Generate Barcode
                        </NavLink>
                      </Menu.Item>
                    </SubMenu> */}

                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/inventory"
                      title="Inventory"
                    >
                      <NavLink
                        className="sider-links "
                        to="/dagaImpex/inventory"
                      >
                        <i className=" fas fa-dolly-flatbed"></i> Inventory
                      </NavLink>
                    </Menu.Item>
                    {/* <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/availableStock" title="Available Stock">
                      <NavLink className="sider-links" to="/dagaImpex/availableStock">
                        <StockOutlined /> Available Stock
                      </NavLink>
                    </Menu.Item> */}
                    <SubMenu
                      key="ecom1"
                      icon={<ShoppingCartOutlined />}
                      title="E-Comm"
                    >
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/ecomProduct"
                        title="E-com Offer "
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/ecomProduct"
                        >
                          <i className="fa fa-shopping-cart"></i> E-com Products
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                  </Menu>
                );
              } else if (decToken.role === "629346953e2dcbf2f6d53242") {
                //SALES_ADMIN
                return (
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={["/dagaImpex"]}
                  >
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex"
                      icon={<HomeOutlined />}
                    >
                      <NavLink className="sider-links " to="/dagaImpex">
                        Dashboard
                      </NavLink>
                    </Menu.Item>
                    <SubMenu
                      key="12"
                      icon={<DollarCircleOutlined />}
                      title="Purchase"
                    >
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/purchaseBill"
                        title="Purcahse Bill"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/purchaseBill"
                        >
                          <i className="far fa-file-alt"></i> Purcahse Bill
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu key="3" icon={<DollarOutlined />} title="Sales">
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/addSales"
                        title="Add Sale"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/addSales"
                        >
                          <i className="far fa-file"></i> Add Sales
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/salesOrder"
                        title="Sales Orders"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/salesOrder"
                        >
                          <i className="far fa-file-alt"></i> Sales Orders
                        </NavLink>
                      </Menu.Item>{" "}
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/salesList"
                        title="Sales List"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/salesList"
                        >
                          <i className="far fa-file-alt"></i> Sales List
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/mergeSalesBill"
                        title="Merge Bill"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/mergeSalesBill"
                        >
                          <i className="far fa-file-alt"></i> Merge Bill
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="sr1"
                      icon={<InteractionOutlined />}
                      title="Sales Return"
                    >
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/addSalesReturn"
                        title="Add Return"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/addSalesReturn"
                        >
                          <i className="far fa-file"></i> Add Return
                        </NavLink>
                      </Menu.Item>{" "}
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/salesReturns"
                        title="Sale Returns"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/salesReturns"
                        >
                          <i className="far fa-file-alt"></i> Sales Return
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/saleReturnBill"
                        title="SaleReturn Bill"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/saleReturnBill"
                        >
                          <i className="far fa-file-alt"></i> Bill Sale Return
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/salesReturnsDefective"
                        title="Defective Return Sales"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/salesReturnsDefective"
                        >
                          <i className="far fa-file-alt"></i> Defective Return
                          Sales
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/defectedProductBill"
                        title="DefectedProduct Bill"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/defectedProductBill"
                        >
                          <i className="far fa-file-alt"></i>Bill Defective
                          Return
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/inventory"
                      title="Inventory"
                    >
                      <NavLink
                        className="sider-links "
                        to="/dagaImpex/inventory"
                      >
                        <i className=" fas fa-dolly-flatbed"></i> Inventory
                      </NavLink>
                    </Menu.Item>
                    {/* <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/availableStock" title="Available Stock">
                      <NavLink className="sider-links" to="/dagaImpex/availableStock">
                        <StockOutlined /> Available Stock
                      </NavLink>
                    </Menu.Item> */}
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/openingStock"
                      title="Opening Stock"
                    >
                      <NavLink
                        className="sider-links "
                        to="/dagaImpex/openingStock"
                      >
                        <i className=" fas fa-plus-square"></i> Opening Stock
                      </NavLink>
                    </Menu.Item>
                    <SubMenu key="4" icon={<UserAddOutlined />} title="Users">
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/addUser"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/addUser"
                        >
                          <i className="fas fa-user-plus"></i> Add User
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/listUsers"
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/listUsers"
                        >
                          <i className="fas fa-users"></i> User List
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/settleGR"
                      title="Settle GR Amount"
                    >
                      <NavLink
                        className="sider-links "
                        to="/dagaImpex/settleGR"
                      >
                        <i className="fas fa-rupee-sign"></i> Settle GR Amount
                      </NavLink>
                    </Menu.Item>

                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/report"
                      title="Report"
                    >
                      <NavLink className="sider-links " to="/dagaImpex/report">
                        <FileSearchOutlined /> Reports
                      </NavLink>
                    </Menu.Item>
                  </Menu>
                );
              } else if (decToken.role === "629346b53e2dcbf2f6d53244") {
                //PURCHASE_ADMIN
                return (
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={["/dagaImpex"]}
                  >
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex"
                      icon={<HomeOutlined />}
                    >
                      <NavLink className="sider-links " to="/dagaImpex">
                        Dashboard
                      </NavLink>
                    </Menu.Item>
                    {/* <SubMenu
                      key="2"
                      icon={<PlusCircleOutlined />}
                      title="Add Details"
                    >
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/dealers"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/dealers"
                        >
                          <i className="fas fa-user-tie"></i> Dealers
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item onClick={this.onClose} key="/dagaImpex/brands">
                        <NavLink className="sider-links" to="/dagaImpex/brands">
                          <i className="fas fa-trademark"></i> Brands
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/category"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/category"
                        >
                          <i className="fas fa-tshirt"></i> Category
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item onClick={this.onClose} key="/dagaImpex/color">
                        <NavLink className="sider-links" to="/dagaImpex/color">
                          <i className="fas fa-fill-drip"></i> Color
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item onClick={this.onClose} key="/dagaImpex/group">
                        <NavLink className="sider-links" to="/dagaImpex/group">
                          <i className="fas fa-sitemap"></i> Group
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/location"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/location"
                        >
                          <i className="fas fa-map-marked-alt"></i> Location
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item onClick={this.onClose} key="/dagaImpex/season">
                        <NavLink className="sider-links" to="/dagaImpex/season">
                          <i className="fas fa-sun"></i> Season
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/texture"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/texture"
                        >
                          <i className=" fas fa-boxes"></i> Texture
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/section"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/section"
                        >
                          <i className=" fas fa-boxes"></i> Section
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item onClick={this.onClose} key="/dagaImpex/style">
                        <NavLink className="sider-links" to="/dagaImpex/style">
                          <i className="fas fa-magic"></i> Style
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item onClick={this.onClose} key="/dagaImpex/size">
                        <NavLink className="sider-links" to="/dagaImpex/size">
                          <i className="fas fa-cut"></i> Size
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/ageGroup"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/ageGroup"
                        >
                          <i className="fas fa-users"></i> Age Group
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item onClick={this.onClose} key="/dagaImpex/floor">
                        <NavLink className="sider-links" to="/dagaImpex/floor">
                          <i className="fas fa-warehouse"></i> Floor
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item onClick={this.onClose} key="/dagaImpex/rack">
                        <NavLink className="sider-links" to="/dagaImpex/rack">
                          <i className="fas fa-warehouse"></i> Rack
                        </NavLink>
                      </Menu.Item> 
                    </SubMenu> */}
                    <Menu.Item key="/dagaImpex/add-details" title="Report">
                      <NavLink className="sider-links " to="/dagaImpex/add-details">
                        <FileSearchOutlined /> Add Details
                      </NavLink>
                    </Menu.Item>
                    <SubMenu
                      key="12"
                      icon={<DollarCircleOutlined />}
                      title="Purchase"
                    >
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/addPurchase"
                        title="Add Purchase"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/addPurchase"
                        >
                          <i className="fas fa-cart-arrow-down"></i> Add
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/purchase"
                        title="Purcahse List"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/purchase"
                        >
                          <i className="far fa-file"></i> List
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item
                        key="/dagaImpex/purchaseBill"
                        title="Purcahse Bill"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/purchaseBill"
                        >
                          <i className="far fa-file-alt"></i> Purcahse Bill
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>

                    <SubMenu
                      key="pr1"
                      icon={<SwapOutlined />}
                      title="Purchase Return"
                    >
                      <Menu.Item
                        key="/dagaImpex/addPurchaseReturn"
                        title="Add Return"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/addPurchaseReturn"
                        >
                          <i className="far fa-file"></i> Add Return
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/purchaseReturns"
                        title="Purchase Returns"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/purchaseReturns"
                        >
                          <i className="far fa-file-alt"></i> Purchase Return
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/purchaseReturnBillList"
                        title="Purchase Returns Bill"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/purchaseReturnBillList"
                        >
                          <i className="far fa-file-alt"></i> Return Bill
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu key="3" icon={<DollarOutlined />} title="Sales">
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/salesList"
                        title="Sales List"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/salesList"
                        >
                          <i className="far fa-file-alt"></i> Sales List
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>

                    <SubMenu
                      key="/dagaImpex/warehouse"
                      icon={<ShopOutlined />}
                      title="Warehouse"
                    >
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/warehouse"
                        icon={<ShopOutlined />}
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/warehouse"
                        >
                          Warehouse List
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/warehouse/inventories"
                        icon={<PlusCircleOutlined />}
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/warehouse/addwarehousesale"
                        >
                          Add Sale
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="b3"
                      icon={<BarcodeOutlined />}
                      title="Barcode"
                    >
                      <Menu.Item
                        key="/dagaImpex/generator"
                        title="Generate Barcode"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/generator"
                        >
                          <i className="fas fa-print"></i> Generate Barcode
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/sample-barcode"
                        title="Sample Barcode"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/sample-barcode"
                        >
                          <i className="fas fa-print"></i> Sample Barcode
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <Menu.Item
                      key="/dagaImpex/sample-barcode"
                      title="Sample Barcode"
                      onClick={this.onClose}
                    >
                      <NavLink
                        className="sider-links"
                        to="/dagaImpex/sample-barcode"
                      >
                        <i className="fas fa-print"></i> Sample Barcode
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/inventory"
                      title="Inventory"
                    >
                      <NavLink
                        className="sider-links "
                        to="/dagaImpex/inventory"
                      >
                        <i className=" fas fa-dolly-flatbed"></i> Inventory
                      </NavLink>
                    </Menu.Item>
                    {/* <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/availableStock" title="Available Stock">
                      <NavLink className="sider-links" to="/dagaImpex/availableStock">
                        <StockOutlined /> Available Stock
                      </NavLink>
                    </Menu.Item> */}
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/openingStock"
                      title="Opening Stock"
                    >
                      <NavLink
                        className="sider-links "
                        to="/dagaImpex/openingStock"
                      >
                        <i className=" fas fa-plus-square"></i> Opening Stock
                      </NavLink>
                    </Menu.Item>
                    <SubMenu
                      key="ecom1"
                      icon={<ShoppingCartOutlined />}
                      title="E-Comm"
                    >
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/flashSales"
                        title="E-com Offer "
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/flashSales"
                        >
                          <i className="fa fa-shopping-cart"></i> E-com Offer
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/ecomProduct"
                        title="E-com Offer "
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/ecomProduct"
                        >
                          <i className="fa fa-shopping-cart"></i> E-com Products
                        </NavLink>
                      </Menu.Item>
                      {/* <Menu.Item
                        key="/dagaImpex/ecomOnlineSales"
                        title="E-com Online Sales"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/ecomOnlineSales"
                        >
                          <i className="fa fa-shopping-cart"></i> E-com Online Sales
                        </NavLink>
                      </Menu.Item> */}
                    </SubMenu>
                    <SubMenu key="4" icon={<UserAddOutlined />} title="Users">
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/addUser"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/addUser"
                        >
                          <i className="fas fa-user-plus"></i> Add User
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/listUsers"
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/listUsers"
                        >
                          <i className="fas fa-users"></i> User List
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/report"
                      title="Report"
                    >
                      <NavLink className="sider-links " to="/dagaImpex/report">
                        <FileSearchOutlined /> Reports
                      </NavLink>
                    </Menu.Item>
                  </Menu>
                );
              } else if (decToken.role === "629346b53e2dcbf2f6d14565") {
                //ECOMERCE_SALES
                return (
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={["/dagaImpex"]}
                  >
                    {/* <Menu.Item key="/dagaImpex" icon={<HomeOutlined />}>
                      <NavLink className="sider-links " to="/dagaImpex">
                        Dashboard
                      </NavLink>
                    </Menu.Item> */}
                    <SubMenu
                      key="ecom1"
                      icon={<ShoppingCartOutlined />}
                      title="E-Comm"
                    >
                      {/* <Menu.Item
                        key="/dagaImpex/flashSales"
                        title="E-com Offer ">
                        <NavLink className="sider-links " to="/dagaImpex/flashSales">
                          <i className="fa fa-shopping-cart"></i> E-com Offer
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item key="/dagaImpex/ecomProduct" title="E-com Offer ">
                        <NavLink className="sider-links " to="/dagaImpex/ecomProduct">
                          <i className="fa fa-shopping-cart"></i> E-com Products
                        </NavLink>
                      </Menu.Item> */}
                      {/* <Menu.Item
                        key="/dagaImpex/ecomOnlineSales"
                        title="E-com Online Sales"
                        onClick={this.onClose}
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/ecomOnlineSales"
                        >
                          <i className="fa fa-shopping-cart"></i> E-com Online Sales
                        </NavLink>
                      </Menu.Item> */}
                    </SubMenu>
                  </Menu>
                );
              } else if (decToken.role === "629346b53e2dcbf2f6d15432") {
                // MIS
                return (
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={["/dagaImpex"]}
                  >
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/report"
                      title="Report"
                    >
                      <NavLink className="sider-links " to="/dagaImpex/report">
                        <FileSearchOutlined /> Reports
                      </NavLink>
                    </Menu.Item>
                  </Menu>
                );
              } else if (decToken.role === "66e967351d83b294823aa3e7") {
                // RECEPTION
                return (
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={["/dagaImpex"]}
                  >
                    <SubMenu key="3" icon={<DollarOutlined />} title="Sales">
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/salesList"
                        title="Sales List"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/salesList"
                        >
                          <i className="far fa-file-alt"></i> Sales List
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>

                    <SubMenu key="4" icon={<UserAddOutlined />} title="Users">
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/agentlist"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/agentlist"
                        >
                          <i className="fas fa-user-tie"></i> Agent List
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/addUser"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/addUser"
                        >
                          <i className="fas fa-user-plus"></i> Add User
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        onClick={this.onClose}
                        key="/dagaImpex/customers"
                        title="Customers List"
                      >
                        <NavLink
                          className="sider-links "
                          to="/dagaImpex/customers"
                        >
                          <i className="fas fa-users"></i> Customers List
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                  </Menu>
                );
              } else if (decToken.role === "66e96761812e5a81f544bc96") {
                // DME
                return (
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={["/dagaImpex/report"]}
                  >
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/report"
                      title="Report"
                    >
                      <NavLink className="sider-links " to="/dagaImpex/report">
                        <FileSearchOutlined /> Reports
                      </NavLink>
                    </Menu.Item>
                  </Menu>
                );
              } else if (decToken.role === "66e966f2b3a3d123c7934fd8") {
                // CRM
                return (
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={["/dagaImpex/report"]}
                  >
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/salesList"
                      title="Sales List"
                    >
                      <NavLink
                        className="sider-links"
                        to="/dagaImpex/salesList"
                      >
                        <i className="far fa-file-alt"></i> Sales List
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/mergeSalesBill"
                      title="Merge Bill"
                    >
                      <NavLink
                        className="sider-links"
                        to="/dagaImpex/mergeSalesBill"
                      >
                        <i className="far fa-file-alt"></i> Merge Bill
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/salesPdf"
                      title="Sales PDF"
                    >
                      <NavLink className="sider-links" to="/dagaImpex/salesPdf">
                        <i className="far fa-file-alt"></i> Sales PDF
                      </NavLink>
                    </Menu.Item>

                    <SubMenu
                      key="sr1"
                      icon={<InteractionOutlined />}
                      title="Sales Return"
                    >
                      <Menu.Item
                        key="/dagaImpex/saleReturnBill"
                        title="SaleReturn Bill"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/saleReturnBill"
                        >
                          <i className="far fa-file-alt"></i> Bill Sale Return
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/salesReturnsDefective"
                        title="Defective Return Sales"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/salesReturnsDefective"
                        >
                          <i className="far fa-file-alt"></i> Defective Return
                          Sales
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="/dagaImpex/defectedProductBill"
                        title="DefectedProduct Bill"
                      >
                        <NavLink
                          className="sider-links"
                          to="/dagaImpex/defectedProductBill"
                        >
                          <i className="far fa-file-alt"></i>Bill Defective
                          Return
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>

                    <Menu.Item
                      onClick={this.onClose}
                      key="/dagaImpex/report"
                      title="Report"
                    >
                      <NavLink className="sider-links " to="/dagaImpex/report">
                        <FileSearchOutlined /> Reports
                      </NavLink>
                    </Menu.Item>
                  </Menu>
                );
              }
            })()}
          </div>
        </Drawer>
      </span>
    );
  }
}
