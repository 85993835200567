import React, { Component } from "react";
import { Table, Tooltip, Button, Space, Input, Modal, Row, Col } from "antd";
import {
  EyeOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getSaleProductByInvoiceId } from "../../../redux/actions/saleAction";
import Highlighter from "react-highlight-words";
import gifLoader from "../../../assets/images/giphy.gif";
import moment from "moment";
import { CaretRightOutlined } from "@ant-design/icons";

const { Column } = Table;

class ShowBillProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowId: null,
      page: "",
      current: 1,
      search: "",
      visible: false,
    };
  }

  showModal = () => {
    this.props.getSaleProductByInvoiceId(this.props.invoiceId);
    this.setState({
      visible: true,
    });
  };
  componentDidMount = () => {
    window.addEventListener("keydown", this.onKeyDown);
  };
  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }
  onKeyDown = (event) => {
    const { selectedRowId } = this.state;
    const currentIndex = this.props?.saleProducts?.findIndex(
      (item) => item._id === selectedRowId
    );
    let nextIndex;
    if (event.key === "ArrowDown") {
      // Move to the next row
      nextIndex = (currentIndex + 1) % this.props.saleProducts.length;
    } else if (event.key === "ArrowUp") {
      // Move to the previous row
      nextIndex =
        (currentIndex - 1 + this.props.saleProducts.length) %
        this.props.saleProducts.length;
    } else {
      return; // Do nothing if a different key is pressed
    }

    this.setState({
      selectedRowId: this.props.saleProducts[nextIndex]._id,
    });
  };
  onRowClick = (record) => {
    this.setState({
      selectedRowId: record._id,
    });
  };

  handleModalCancel = () => {
    this.props.getSaleProductByInvoiceId("reset");
    this.setState({ visible: false });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (dataIndex == "brand") {
        return (
          record.details &&
          record.details.brand
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      } else if (dataIndex == "designNumber") {
        return (
          record.details &&
          record.details.designNumber
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      } else if (dataIndex == "barcode") {
        return record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : false;
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  render() {
    const { visible } = this.state;
    const { saleProducts } = this.props;
    return (
      <div>
        {this.props?.isMobile ? (
          <Button
            type="primary"
            size="small"
            // disabled={!invoiceData}
            style={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              padding: "4px 8px",
            }}
            onClick={this.showModal}
            disabled={
              this.props.data?.remark === "Bill Deleted." ? true : false
            }
          >
            <Tooltip title="View Products">
              <EyeOutlined />
            </Tooltip>
          </Button>
        ) : (
          <button
            className="btn"
            type="button"
            data-original-title=""
            onClick={this.showModal}
            disabled={
              this.props.data?.remark === "Bill Deleted." ? true : false
            }
          >
            <Tooltip title="View Products">
              <EyeOutlined />
            </Tooltip>
          </button>
        )}
        <Modal
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleModalCancel}
          title="View Products"
          style={{ top: 20 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
          width={1500}
        >
          {saleProducts && saleProducts.length > 0 ? (
            <>
              {/* <Row gutter={24} className="mb-4 grand-totall billHeadingContainer" >
                <Col sm={24} md={12} lg={6}>
                  <span className=" billsTopHeading">Bill Date:</span>
                  <span className="billsSecHeading">{`${moment(this.props.data.date).format("YYYY-MM-DD")} (${moment(this.props.data.date).format("HH:mm:ss")} )`}</span>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <span className=" billsTopHeading">Invoice No:</span>
                  <span className="billsSecHeading">{this.props.data.invoiceId}</span>
                </Col>
                <Col sm={24} md={24} lg={10}>
                  <span className=" billsTopHeading">Customer Name:</span>
                  <span className="billsSecHeading">{this.props.data.userId.name}</span>
                </Col>
              </Row> */}
              <Row
                gutter={24}
                className="mb-4 grand-totall billHeadingContainer"
              >
                <Col sm={24} md={24} lg={24} className='breadcrumbHeadingView'>
                  <div className=" billsTopHeading">
                    Bill Date
                    <CaretRightOutlined className="mr-2" />{" "}
                    {`${moment(this.props?.data?.date).format(
                      "YYYY-MM-DD"
                    )} (${moment(this.props?.data?.date).format("HH:mm:ss")} )`}
                  </div>
                  <div className=" billsTopHeading breadcrumbSubHeadings">
                    Invoice No
                    <CaretRightOutlined className="mr-2" />{" "}
                    {this.props?.data?.invoiceId}
                  </div>
                  <div className=" billsTopHeading breadcrumbSubHeadings">
                    Customer Name
                    <CaretRightOutlined className="mr-2" />{" "}
                    {this.props?.data?.userId?.name}
                  </div>
                </Col>
              </Row>
              <Table
                dataSource={
                  saleProducts && saleProducts.length > 0 ? saleProducts : []
                }
                onRow={(record) => ({
                  onClick: () => this.onRowClick(record),
                })}
                rowClassName={(record) =>
                  record._id === this.state.selectedRowId ? "selected-row" : ""
                }
                bordered="true"
                size="middle"
                pagination={false}
                scroll={{ y: 500 }}
              >
                <Column
                  title="SRN"
                  key="index"
                  render={(value, item, index) => index + 1}
                  width={60}
                />

                <Column
                  title="Barcode"
                  dataIndex="barcode"
                  key="barcode"
                  width={100}
                  {...this.getColumnSearchProps("barcode")}
                />
                <Column
                  title="Design No."
                  dataIndex="designNumber"
                  {...this.getColumnSearchProps("designNumber")}
                  key="designNumber"
                  render={(value, item, index) =>
                    `${item.details.designNumber}`
                  }
                  width={115}
                />
                <Column
                  title="Brand"
                  {...this.getColumnSearchProps("brand")}
                  dataIndex="brand"
                  key="brand"
                  render={(value, item, index) => `${item.details.brand}`}
                  width={120}
                />
                <Column
                  title="Size"
                  dataIndex="size"
                  key="size"
                  width={80}
                  render={(value, item, index) => `${item.details.size}`}
                />
                <Column
                  title="Color"
                  dataIndex="color"
                  key="color"
                  width={90}
                  render={(value, item, index) => `${item.details.color}`}
                />
                <Column
                  title="Qnty"
                  dataIndex="quantity"
                  key="quantity"
                  width={60}
                />
                {/* <Column
              title="Total Boxes"
              dataIndex="totalBox"
              key="totalBox"
              render={(value, item, index) =>
                `${item.quantity / item.details.MOQ}`
              }
              width={90}
            /> */}

                <Column
                  title="Price"
                  dataIndex="wsp"
                  key="wsp"
                  render={(value, item, index) => `${item.details.wsp}`}
                  width={90}
                />
                <Column
                  title="Amount"
                  //  dataIndex="purchasePrice"
                  key="wsp"
                  render={(value, item, index) =>
                    Number(item?.details?.wsp) * Number(item?.quantity)
                  }
                  width={100}
                />
              </Table>

              <div className="mt-4">
                <div className="col-md-12 ml-auto">
                  <div
                    className="grand-totall"
                    style={{ padding: "18px 17px 4px" }}
                  >
                    <div className="title-wrap">
                      <h4 className="cart-bottom-title section-bg-gary-cart">
                        Sales Total
                      </h4>
                    </div>
                    <h5>
                      Total Bills{" "}
                      <span>
                        {saleProducts && saleProducts.length > 0
                          ? saleProducts.length
                          : 0}
                      </span>
                    </h5>
                    <h5>
                      Total Boxes <span>{this.props.data.totalBoxes}</span>
                    </h5>
                    <h5>
                      Total Quantity{" "}
                      <span>{this.props.data.totalQuantity}</span>
                    </h5>
                    <h5>
                      Total Amount <span>{this.props.data.totalAmount}</span>
                    </h5>
                    <h5 className="grand-totall-title">
                      Net Amount{" "}
                      <span>{Number(this.props.data.netAmount).toFixed()}</span>
                    </h5>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center align-items-center">
                <img src={gifLoader} width={"300px"} height={"300px"} />
              </div>
            </>
          )}
        </Modal>
      </div>
    );
  }
}

ShowBillProducts.propTypes = {
  getSaleProductByInvoiceId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  saleProducts: state.saleData.saleProducts,
});

export default connect(mapStateToProps, { getSaleProductByInvoiceId })(
  ShowBillProducts
);
