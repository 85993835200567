import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import HeaderOne from "../../../wrappers/header/HeaderOne";
import BrandImage from "../../../assets/images/EMPIRE.png";
import ArrowLeft from "../../../assets/images/Group 116.png";
import "./TopBrands.css";
import { useDispatch, useSelector } from "react-redux";
import { getOnBrandEcom } from "../../../redux/actions/brandAction";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { fetchAllProductsSidebarList, fetchFilterProductSidebarList, fetchProductsByFiltereing } from "../../../redux/actions/productActions";
import { useState } from "react";

// const brands = [
//   { name: "Empire", logo: "Logo_Empire" },
//   { name: "Empire", logo: "Logo_Empire" },
//   { name: "Empire", logo: "Logo_Empire" },
// ];

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

const TopBrands = () => {
  const history = useHistory();
  const { width, height } = useWindowSize();
  const dispatch = useDispatch();
  const brands = useSelector((state) => state.brandsData?.ecomBrands);
  console.log("getBrands-------------------->", brands)

  useEffect(() => {
    const fetchBrands = async () => {
      const resp = await dispatch(getOnBrandEcom());
      console.log("Brands Response:", resp);
    };
    fetchBrands();
  }, [dispatch]);


  const handleBrandClick = async (brandId) => {
    const obj = {
      category: [],
      style: [],
      brand: [brandId],
      color: [],
      size: [],
    };
    const params = new URLSearchParams();
    params.set('All', true);
    params.set('limit', 1);
    params.set('brand', brandId);
    params.delete('style');
    params.delete('color');
    params.delete('size');
    params.delete('category');

    let response = await dispatch(fetchAllProductsSidebarList());
    let brands = await response;
    if (brands) {
      history.push({
        pathname: '/shop-grid-standard',
        search: params.toString(),
        state: {
          stateData: 'Brand',
          selectTypeData: brands,
          loading: true,
          selectedBrands: ''
        },
      });
      await dispatch(fetchProductsByFiltereing(obj));
      await dispatch(fetchFilterProductSidebarList(obj));
      history.push({
        pathname: '/shop-grid-standard',
        search: params.toString(),
        state: {
          stateData: 'Brand',
          selectTypeData: brands,
          loading: false,
          selectedBrands: ''
        },
      });
    }
  };

  return (
    <>
      <div className="breadcrumb-area shadow-sm mb-4">
        <div className="p-0">
          <div className="breadcrumb-content mx-2 d-flex align-items-center justify-content-start">
            <Link to="/">
              <ArrowLeftOutlined className="text-white" />
            </Link>
            <div className="w-100 breadCrumb-Text ml-3 text-center">
              Top Brands
            </div>
          </div>
        </div>
      </div>

      {
        width > 700
          ?
          <Row gutter={24} className="px-5">
            {
              brands?.map((brand, index) => {
                return (
                  <Col sm={8} md={6} lg={4}
                    className=" p-2"
                    onClick={() => handleBrandClick(brand._id)}>
                    <div className="blog-wrap scroll-zoom mt-4 border-BrandSection-card">
                      <div className="product-grid mb-0">
                        <div className="product-image">
                          <a className="image">
                            <img className="productImage" src={brand?.image} alt="" />
                          </a>
                        </div>
                        <div className="product-content">
                          <h3 className="title m-0"><a >{brand.name}</a></h3>
                        </div>
                      </div>
                    </div>
                  </Col>
                )
              })
            }
          </Row>
          :
          <div className="brandmobile-page">
            <div className="brandmobile-grid">
              {brands?.map((brand, index) => (
                <div key={index} onClick={() => handleBrandClick(brand._id)} className="mx-2">
                  <div className="brandmobile-card">
                    <img
                      style={{ width: "90px", height: "35px" }}
                      src={brand.image}
                      alt={brand.name}
                    />
                  </div>
                  <div className="brandmobile-name">{brand.name}</div>
                </div>
              ))}
            </div>
          </div>
      }
    </>
  );
};

export default TopBrands;
