import React, { Component } from "react";
import {
  Table,
  Tooltip,
  Pagination,
  Button,
  Space,
  Input,
  Modal,
  Row,
  Col,
} from "antd";
import {
  EyeOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getsaleReturnBillBysaleReturnId } from "../../../../../redux/actions/saleAction";
import Highlighter from "react-highlight-words";
import gifLoader from "../../../../../assets/images/giphy.gif";
import moment from "moment";
import { CaretRightOutlined } from "@ant-design/icons";

const { Column } = Table;

class ShowBillsaleReturn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      visible: false,
    };
  }

  showModal = () => {
    this.props.getsaleReturnBillBysaleReturnId(this.props.saleReturnId, 1);
    this.setState({
      visible: true,
    });
  };

  handleModalCancel = () => {
    this.props.getsaleReturnBillBysaleReturnId("reset");
    this.setState({ visible: false });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  render() {
    const { saleReturnBillSaleReturnId } = this.props;
    // let count = 0;
    // let d = new Date();
    // const num = d.getFullYear();
    // const last2Str = String(num).slice(-2);
    // let month = "" + (d.getMonth() + 1);
    // if (month.length < 2) month = "0" + month;
    // let out = month + last2Str;
    return (
      <div>
        <button
          className="btn"
          type="button"
          data-original-title=""
          onClick={this.showModal}
        >
          <Tooltip title="View Products">
            <EyeOutlined />
          </Tooltip>
        </button>
        <Modal
          visible={
            saleReturnBillSaleReturnId &&
            saleReturnBillSaleReturnId.data &&
            saleReturnBillSaleReturnId.data.length > 0
              ? this.state.visible
              : false
          }
          onOk={this.handleOk}
          onCancel={this.handleModalCancel}
          title="View Products"
          style={{ top: 20 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
          width={1500}
        >
          {saleReturnBillSaleReturnId &&
          saleReturnBillSaleReturnId.data &&
          saleReturnBillSaleReturnId.data.length > 0 ? (
            <>
              {/* <Row gutter={24} className="mb-4 grand-totall billHeadingContainer">
                <Col sm={24} md={12} lg={6}>
                  <span className=" billsTopHeading">Bill Date:</span>
                  <span className="billsSecHeading">{`${moment(this.props.data.date).format("YYYY-MM-DD")} (${moment(this.props.data.date).format("HH:mm:ss")} )`}</span>
                </Col>
                <Col sm={24} md={12} lg={6}>
                  <span className=" billsTopHeading">Sale's Return ID:</span>
                  <span className="billsSecHeading">{this.props.data.saleReturnId}</span>
                </Col>
                <Col sm={24} md={24} lg={10}>
                  <span className=" billsTopHeading">Customer Name:</span>
                  <span className="billsSecHeading">{this.props.data.customerName}</span>
                </Col>
              </Row> */}

              <Row
                gutter={24}
                className="mb-4 grand-totall billHeadingContainer"
              >
                <Col sm={24} md={24} lg={24} className="breadcrumbHeadingView">
                  <div className=" billsTopHeading">
                    Bill Date
                    <CaretRightOutlined className="mr-2" />
                    {`${moment(this.props.data.date).format(
                      "YYYY-MM-DD"
                    )} (${moment(this.props.data.date).format("HH:mm:ss")} )`}
                  </div>

                  <div className=" billsTopHeading breadcrumbSubHeadings">
                    Sale's Return ID
                    <CaretRightOutlined className=" mr-2" />
                    {this.props.data.saleReturnId}
                  </div>

                  <div className=" billsTopHeading breadcrumbSubHeadings">
                    Customer Name
                    <CaretRightOutlined className=" mr-2" />{" "}
                    {this.props.data.customerName}
                  </div>
                </Col>
              </Row>
              <Table
                dataSource={
                  this.props.saleReturnBillSaleReturnId &&
                  this.props.saleReturnBillSaleReturnId
                    ? this.props.saleReturnBillSaleReturnId.data
                    : []
                }
                bordered="true"
                size="middle"
                pagination={{
                  pageSize: 10,
                  total:
                    this.props.saleReturnBillSaleReturnId &&
                    this.props.saleReturnBillSaleReturnId.count
                      ? this.props.saleReturnBillSaleReturnId.count
                      : "",
                  onChange: (page) => {
                    this.props.getsaleReturnBillBysaleReturnId(
                      this.props.saleReturnId,
                      page
                    );
                  },
                }}
                scroll={{ y: 240 }}
              >
                {console.log(
                  "Line No: 204",
                  this.props.saleReturnBillSaleReturnId
                )}
                <Column
                  title="SRN"
                  key="index"
                  render={(value, item, index) => index + 1}
                  width={60}
                />
                <Column
                  title="Barcode"
                  dataIndex="barcode"
                  key="barcode"
                  width={60}
                />
                <Column
                  title="Design No"
                  dataIndex="details"
                  key="details"
                  render={(value, item, index) => item?.details?.designNumber}
                  width={60}
                />
                <Column
                  title="Brand"
                  dataIndex="details"
                  key="details"
                  width={60}
                  render={(value, item, index) => item?.details?.brand}
                />
                <Column
                  title="Size"
                  dataIndex="details"
                  key="details"
                  width={60}
                  render={(value, item, index) => item?.details?.size}
                />
                <Column
                  title="Color"
                  dataIndex="details"
                  key="details"
                  width={60}
                  render={(value, item, index) => item?.details?.color}
                />
                <Column
                  title="Quantity"
                  dataIndex="quantity"
                  key="quantity"
                  width={60}
                />
                <Column
                  title="Rate"
                  dataIndex="details"
                  key="details"
                  render={(value, item, index) => item?.details?.wsp}
                  width={60}
                />
                <Column
                  title="Total"
                  dataIndex="price"
                  key="price"
                  width={60}
                />
              </Table>

              <div className="mt-4">
                <div className="col-md-12 ml-auto">
                  <div
                    className="grand-totall"
                    style={{ padding: "18px 17px 4px" }}
                  >
                    <div className="title-wrap">
                      <h4 className="cart-bottom-title section-bg-gary-cart">
                        Sales Return Total
                      </h4>
                    </div>
                    <h5>
                      Total Bills{" "}
                      <span>
                        {this.props &&
                        this.props.saleReturnBillSaleReturnId &&
                        this.props.saleReturnBillSaleReturnId.data
                          ? this.props.saleReturnBillSaleReturnId.data.length
                          : 0}
                      </span>
                    </h5>
                    <h5>
                      Total Boxes <span>{this.props.data.totalBoxes}</span>
                    </h5>
                    <h5>
                      Total Quantity{" "}
                      <span>{this.props.data.totalQuantity}</span>
                    </h5>
                    {/* <h4 className="grand-totall-title">
                      Total Amount{" "}
                      <span>
                        {parseInt(this.props.data.totalAmount).toFixed()}
                      </span>
                    </h4> */}

                    <h5 className="grand-totall-title">
                      Total Amount{" "}
                      <span>
                        {" "}
                        {parseInt(this.props.data.totalAmount).toFixed()}
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center align-items-center">
                <img src={gifLoader} width={"300px"} height={"300px"} />
              </div>
            </>
          )}
        </Modal>
      </div>
    );
  }
}

ShowBillsaleReturn.propTypes = {
  getsaleReturnBillBysaleReturnId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    saleReturnBillSaleReturnId: state.saleData.saleReturnBillSaleReturnId,
  };
};

export default connect(mapStateToProps, { getsaleReturnBillBysaleReturnId })(
  ShowBillsaleReturn
);
