import React, { Component } from "react";
import { Table, Row, Col, Tooltip, Popconfirm } from "antd";
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
const { Column } = Table;

export default class AddedListReturn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      returnData: [],
    };
  }
  shouldComponentUpdate(nextProps, prevState) {
    if (
      nextProps.data != undefined &&
      nextProps.data.length != prevState.returnData.length
    ) {
      this.setState({
        returnData: nextProps.data,
      });
    }

    return true;
  }

  onDeleteClick = (id) => {
    this.props.deleteSale(id);
  };
  onRemoveQnty = (obj) => {
    this.props.onRemoveQnty(obj);
  };
  onAddQnty = (obj) => {
    this.props.onAddQnty(obj);
  };
  onDeleteClick2 = (_id) => {
    console.log("addedList", _id);
    this.props.deletesaleReturn(_id);
  };
  render() {
    let data;

    console.log("#this.props.data", this.props.data);
    return (
      <div className="sale-list mt-3">
        <Table
          dataSource={this.props.data}
          bordered="true"
          size="middle"
          pagination={false}
          scroll={{ y: 240 }}
        >
          <Column
            title="SRN"
            key="index"
            render={(value, item, index) => index + 1}
            width={60}
          />
          <Column
            title="Invoice Id"
            dataIndex="invoiceId"
            key="invoiceId"
            width={90}
          />
          <Column
            title="Barcode"
            dataIndex="barcode"
            key="barcode"
            width={110}
          />
          <Column
            title="Name"
            dataIndex="customerName"
            key="customerName"
            width={110}
          />
          <Column
            title="Design Number"
            dataIndex="designNumber"
            key="designNumber"
            width={150}
          />
          <Column title="Brand" dataIndex="brand" key="brand" width={110} />
          <Column title="Size" dataIndex="size" key="size" width={110} />
          <Column title="Color" dataIndex="color" key="color" width={110} />
          <Column title="MOQ" dataIndex="MOQ" key="MOQ" width={110} />
          <Column title="WSP" dataIndex="wsp" key="wsp" width={110} />
          <Column
            title="Qnty"
            dataIndex="quantity"
            key="quantity"
            width={100}
            onClick={this.props.quantity}
          />
          <Column
            title="Amount"
            dataIndex="quantity"
            key="quantity"
            render={(item, value, index) =>
              Number(value.quantity) * Number(value.wsp)
            }
            width={100}
          />
          {/* {console.log("test check???????", this.props.data,this.state.quantity)} */}
          <Column
            title="Action"
            key="action"
            fixed="right"
            width={100}
            render={(text, record, index) => (
              <Row gutter={[8, 0]}>
                {(() => {
                  data = JSON.parse(JSON.stringify(text));
                  data.index = index;
                  data.text = text;
                })()}
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  {(() => {
                    if (text.id) {
                      {
                        console.log("delete .id", text.id);
                      }
                      return (
                        <Popconfirm
                          title="Are you sure to delete this Record?"
                          onConfirm={() => this.onDeleteClick(text.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Tooltip title="Delete Record">
                            <DeleteOutlined />
                          </Tooltip>
                        </Popconfirm>
                      );
                    } else {
                      {
                        console.log("delete ._id", text._id);
                      }
                      return (
                        <Popconfirm
                          title="Are you sure to delete this Record?"
                          onConfirm={() => this.onDeleteClick2(text._id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Tooltip title="Delete Record">
                            <DeleteOutlined />
                          </Tooltip>
                        </Popconfirm>
                      );
                    }
                  })()}
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Tooltip title="Add Qnty">
                    <a type="button" onClick={() => this.onAddQnty(text)}>
                      <PlusCircleOutlined />
                    </a>
                  </Tooltip>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  {(() => {
                    if (text.quantity <= 1) {
                      return (
                        <Tooltip title="Remove Qnty">
                          <a type="button" aria-disabled>
                            <MinusCircleOutlined />
                          </a>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Tooltip
                          title="Remove Qnty"
                          onClick={() => this.onRemoveQnty(text)}
                        >
                          <a type="button">
                            <MinusCircleOutlined />
                          </a>
                        </Tooltip>
                      );
                    }
                  })()}
                </Col>
              </Row>
            )}
          />
        </Table>
      </div>
    );
  }
}
