import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { WhatsAppOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getSaleDetailsByinvoiceIds,
  salesBillDetails,
  sendMBillByWhatsAppToAgent,
  sendMergeBillByWhatsApp,
  sendMessageOnWhatsapp,
  uploadImage,
} from "../../../../redux/actions/saleAction";
import moment from "moment";
import logo from "../../../../assets/images/Daga_Impex_Logo.png";
import QR from "../../../../assets/images/QR.png";
import { Button, message, Popconfirm } from "antd";
import Swal from "sweetalert2";

const MB_AgentWhatsapp = ({ invoiceData, agent }) => {
  const dispatch = useDispatch();
  const customerData = useSelector(
    (state) => state.saleData.slaesbillByCustomerId
  );
  const handleDownload = async () => {
    if (!agent?.mobileNumber) {
      message.error("Agent's Mobile Number Not Found!");
      return;
    }
    let invoiceeIds = invoiceData?.map((obj) => obj.invoiceId);
    let obj = {
      invoiceId: invoiceeIds,
    };

    const res = await dispatch(getSaleDetailsByinvoiceIds(obj));
    const data = res?.data?.data;
    let netAmount = 0;
    let totalQuantity = 0;

    const doc = new jsPDF({
      compress: true,
    });
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(14);
    doc.text("JAY SHREE KRISHNA", doc.internal.pageSize.getWidth() / 2, 25, {
      align: "center",
    });

    doc.setFont("helvetica", "bold");
    doc.text("Estimate", doc.internal.pageSize.getWidth() / 2, 33, {
      align: "center",
    });

    doc.addImage(logo, "PNG", 12, 10, 30, 15);
    doc.setFont("helvetica", "normal");
    doc.setLineWidth(0.5);
    doc.line(10, 38, doc.internal.pageSize.getWidth() - 10, 38);

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(`Customer Name: ${data[0]?.userId?.name || ""}`, 12, 45, {
      align: "left",
    });

    doc.setFontSize(11);
    doc.text(`Agent Name: ${data[0]?.userId?.agentId?.name || ""}`, 12, 52, {
      align: "left",
    });

    for (let i = 0; i < data?.length; i++) {
      const tableY = i == 0 ? 70 : doc.autoTable.previous.finalY + 15;

      // Add the header row
      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");
      doc.text(
        `Date: ${moment(data[i]?.date || "").format("DD/MM/YY H:mm")}`,
        14,
        tableY - 8,
        {
          align: "left",
        }
      );

      doc.text(`Invoice Number: ${data[i]?.invoiceId || ""}`, 150, tableY - 8, {
        align: "left",
      });
      doc.text(`Remark: ${data[i]?.remark || ""}`, 14, tableY - 3, {
        align: "left",
      });

      let tQuant = 0;
      let tAmount = 0;
      // Create the table
      let tableData = data[i]?.salesDetails?.map((item, itemIndex) => {
        const quantity = item.quantity || 0;
        const totalBoxes = item.quantity / item.purchaseDetails?.MOQ || 0;
        const wsp = item.purchaseDetails?.wsp || 0;
        const amount = quantity * wsp;

        tQuant += quantity;
        tAmount += amount;

        return [
          itemIndex + 1, // Sl No.
          item.purchaseDetails?.brand || "", // Brand
          item.purchaseDetails?.designNumber || "", // D-Number
          item.purchaseDetails?.size || "", // Size
          item.purchaseDetails?.color || "", // Color
          item.purchaseDetails?.MOQ || "", // MOQ
          totalBoxes || "", // Boxes
          quantity, // Quantity
          wsp, // Rate
          amount, // Amount
        ];
      });

      // const netAmount = tAmount + (tAmount*)
      // Create the total row
      const tableData2 = [
        ...tableData,
        [
          "",
          "Total:",
          "",
          "",
          "",
          "",
          "",
          data[i]?.totalQuantity,
          "",
          data[i]?.netAmount,
        ],
      ];

      doc.autoTable({
        head: [
          [
            "Sl No.",
            "Brand",
            "D-Number",
            "Size",
            "Color",
            "MOQ",
            "Set",
            "Quantity",
            "WSP",
            "Total",
          ],
        ],
        body: tableData2,
        didParseCell: function (data) {
          if (data.row.index === tableData2.length - 1) {
            data.cell.styles.fontStyle = "bold";
          }
        },
        theme: "grid",
        startY: tableY,
        styles: {
          fontSize: 8,
          textColor: [0, 0, 0],
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          halign: "center",
          cellPadding: 1,
        },
        headStyles: {
          fontSize: 9,
          textColor: [0, 0, 0],
          lineWidth: 0.05,
          lineColor: [0, 0, 0],
          fillColor: [200, 200, 200],
          halign: "center",
        },
        columnStyles: {
          0: { columnWidth: 10 },
          1: { columnWidth: 30 },
          2: { columnWidth: 19 },
          3: { columnWidth: 19 },
          4: { columnWidth: 15 },
          5: { columnWidth: 15 },
          6: { columnWidth: 19 },
          7: { columnWidth: 19 },
          8: { columnWidth: 19 },
          9: { columnWidth: 19 },
        },
      });

      
      const discount = data[i]?.discount;
      const totalAmount = data[i]?.totalAmount;
      console.log("totalAmount",totalAmount)
      const discountedAmount = (totalAmount * discount) / 100;
      console.log("discountedAmount",discountedAmount)
      const tax = data[i]?.tax;
      console.log("tax",tax)
      const taxAmount = ((totalAmount - discountedAmount) * tax) / 100;
      console.log("taxAmount",taxAmount)
      const netAmt = Math.round(totalAmount - discountedAmount + taxAmount)
      

      let summaryTableData = [
        ["Tax Amount", Math.round(taxAmount)],
        ["Net Amount", netAmt],
      ];

      doc.autoTable({
        body: summaryTableData,
        theme: "grid",
        startY: doc.autoTable.previous.finalY,
        margin: { left: 160 },
        styles: {
          fontSize: 8,
          textColor: [0, 0, 0],
          lineColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          halign: "center",
          cellPadding: 1,
        },
        columnStyles: {
          0: { columnWidth: 19, fillColor: [200, 200, 200], fontStyle: "bold" },
          1: { columnWidth: 19 },
        },
      });

      totalQuantity += data[i]?.totalQuantity;
      netAmount += netAmt;
    }

    const table1Y = doc.autoTable.previous.finalY;

    // Create a new table outside the loop
    doc.autoTable({
      body: [["Total", "", "", "", "", "", totalQuantity, "", netAmount]],
      didParseCell: function (data) {
        data.cell.styles.fontStyle = "bold";
      },
      theme: "grid",
      startY: table1Y + 5,
      styles: {
        fontSize: 10,
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        halign: "center",
        cellPadding: 1,
      },
      headStyles: {
        fontSize: 10,
        textColor: [0, 0, 0],
        lineWidth: 0.05,
        lineColor: [0, 0, 0],
        fillColor: [200, 200, 200],
        halign: "center",
      },
      columnStyles: {
        0: { columnWidth: 40 },
        1: { columnWidth: 19 },
        2: { columnWidth: 19 },
        3: { columnWidth: 15 },
        4: { columnWidth: 15 },
        5: { columnWidth: 19 },
        6: { columnWidth: 19 },
        7: { columnWidth: 19 },
        8: { columnWidth: 19 },
      },
    });
    const finalY = doc.autoTable.previous.finalY;
    const pageWidth = doc.internal.pageSize.getWidth();
    const halfPageWidth = pageWidth / 2 - 5;

    doc.setFontSize(12);
    doc.setTextColor(90, 90, 90);
    doc.text("* Terms and conditions", 12, finalY + 8, {
      maxWidth: halfPageWidth,
    });
    doc.setTextColor(128, 128, 128);
    doc.setFontSize(9);
    doc.text("1. Goods once sold will not be taken back", 12, finalY + 13, {
      maxWidth: halfPageWidth,
    });
    doc.text("2. No Claim No Guarantee", 12, finalY + 18, {
      maxWidth: halfPageWidth,
    });
    doc.text(
      "3. Any disputes arising from this transaction will be resolved through Jurisdiction in Indore.",
      12,
      finalY + 23,
      {
        maxWidth: halfPageWidth,
      }
    );
    doc.text(
      "4. A late payment fee of 10% will be applied to overdue balances after 30 days.",
      12,
      finalY + 32,
      {
        maxWidth: halfPageWidth,
      }
    );
    doc.text(
      "5. The total shown on the bill represents the net amount after the calculation of tax and discount.",
      12,
      finalY + 41,
      {
        maxWidth: halfPageWidth,
      }
    );

    const qrX = halfPageWidth + 63;

    // Add the QR image
    doc.addImage(QR, "PNG", qrX, finalY + 3, 33, 33);

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.text(
      "|| Thank You Visit Again ||",
      doc.internal.pageSize.getWidth() / 2,
      finalY + 80,
      {
        align: "center",
      }
    );

    const pdfBlob = doc.output("blob");
    // const link = document.createElement("a");
    // link.href = URL.createObjectURL(pdfBlob);
    // link.download = `${invoiceData[0]?.invoiceId || "invoice"}.pdf`;

    // Append the link to the body (required for Firefox)
    // document.body.appendChild(link);
    // link.click();
    // // Programmatically click the link to trigger the download
    const formData = new FormData();
    formData.append("image", pdfBlob, invoiceData[0]?.invoiceId);
    formData.append("invoiceId", invoiceData[0]);
    const response = await dispatch(uploadImage(formData))
      .then(async (response) => {
        const res = await dispatch(
          dispatch(
            sendMBillByWhatsAppToAgent({
              pdfUrl: response?.data?.filePath,
              userDetails: customerData[0].userId,
              totalQuantity: totalQuantity,
              netAmount: netAmount,
              invoiceId: invoiceeIds
            })
          ).then((res) => {
            if (res.status == 200) {
              Swal.fire(
                "Success!",
                "Bill sent successfully on WhatsApp!",
                "success"
              );
            } else {
              Swal.fire("Something went Wrong!", "error");
            }
          })
        );
      })
      .catch((error) => {
        Swal.fire(
          "Error!",
          "An error occurred while sending the bill.",
          "error"
        );
      });
  };

  return (
    <Popconfirm
      title="Send Bill On Whatsapp?"
      onConfirm={() => handleDownload()}
      okText="Yes"
      cancelText="No"
      className="w-100"
    >
      <Button
        type="primary"
        disabled={!invoiceData?.length > 0}
        style={{ width: "100%" }}
      >
        {console.log("agent", agent)}
        <WhatsAppOutlined /> Send Bill To Agent
      </Button>
    </Popconfirm>
  );
};

export default MB_AgentWhatsapp;