import React, { useEffect, useState } from 'react';
import BackButton from "../../Report/Button";
import TopBarProgress from "react-topbar-progress-indicator";
import { Row, Col, Tooltip, Form, Select, DatePicker, Button, Checkbox } from "antd";
import { getOpeningStockList } from '../../../../redux/actions/reportsActions';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import OpeningStockReportList from './OpeningStockReportList';

const dateFormat = "YYYY-MM-DD";
const { Option } = Select;

const OpeningStockReport = () => {
    const [formData, setFormData] = useState({
        startDate: moment().format(dateFormat),
        endDate: moment().format(dateFormat) + " " + moment().format("HH:mm:ss"),
        searchType: "",
        type: "",
    });
    const [formRef] = Form.useForm();

    const dispatch = useDispatch();
    const reportData = useSelector((state) => state.reportData.openingStockList?.data);
    const progressBarStatus = useSelector((state) => state.progressBarReducer.showProgressBar);
    
    const handleStartDate = (date, dateString) => {
        setFormData((prevData) => ({
            ...prevData,
            startDate: dateString,
        }));
    };

    useEffect(() => {
        if (formData) {
            const obj = {
                startDate: formData?.startDate,
                endDate: formData?.endDate,
            };
            dispatch(getOpeningStockList(obj));
        }
    }, [formData]);

    const handleReset = () => {
        dispatch(getOpeningStockList("reset"));
        formRef.resetFields();
        setFormData((prevData) => ({
            ...prevData,
            startDate: moment().format(dateFormat),
            endDate: moment().format(dateFormat) + " " + moment().format("HH:mm:ss"),
        }));
    };

    const handleEndDate = (date, dateString) => {
        dispatch(getOpeningStockList("reset"));
        setFormData((prevData) => ({
            ...prevData,
            endDate: dateString,
        }));
    };

    const handleSelectChange = (value) => {
        setFormData((prevData) => ({
            ...prevData,
            searchType: value,
        }));
    };

    const handleSearch = () => {
        const obj = {
            startDate: formData.startDate,
            endDate: formData.endDate,
        };

        console.log("Obj---------------->", obj);
        dispatch(getOpeningStockList(obj));
    };

    // Download functionality
    const handleDownload = () => {
        if (!reportData || reportData.length === 0) {
            alert("No data available to download");
            return;
        }

        const csvData = convertToCSV(reportData);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);

        link.setAttribute("href", url);
        link.setAttribute("download", "opening_stock_report.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const convertToCSV = (data) => {
        const headers = Object.keys(data[0]).join(",") + "\n";
        const rows = data
            .map((row) => Object.values(row).join(","))
            .join("\n");
        return headers + rows;
    };

    return (
        <div className="subCategory-dashboard">
            {progressBarStatus && <TopBarProgress />}
            <div className="container-fluid category-list">
                <Row className="title-row">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <div className="page-header">Opening Stock Report</div>
                            </Col>
                            <Col span={12}>
                                <BackButton />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form
                            form={formRef}
                            name="add-Purchase"
                            className="add-Purchase"
                            onFinish={handleSearch}
                            initialValues={{ remember: true }}
                        >
                            <Row gutter={[16, 0]} className="mt-4">
                                <Col xs={24} sm={7} md={7} lg={4} xl={4}>
                                    <Tooltip title="Select Start Date">
                                        <Form.Item
                                            name="startDate"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please Select Start Date!",
                                                },
                                            ]}
                                            initialValue={moment()}
                                        >
                                            <DatePicker
                                                style={{ width: "100%" }}
                                                onChange={handleStartDate}
                                                placeholder="Select Start Date"
                                                format={dateFormat}
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col xs={24} sm={7} md={7} lg={4} xl={4}>
                                    <Tooltip title="Select End Date">
                                        <Form.Item
                                            name="endDate"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please Select End Date!",
                                                },
                                            ]}
                                            initialValue={moment()}
                                        >
                                            <DatePicker
                                                disabledDate={(current) => current && current > Date.now()}
                                                style={{ width: "100%" }}
                                                onChange={handleEndDate}
                                                placeholder="Select End Date"
                                                format={dateFormat}
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col xs={24} sm={7} md={7} lg={4} xl={2}>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="close-modal"
                                            block
                                        >
                                            Search
                                        </Button>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={7} md={7} lg={4} xl={2}>
                                    <Form.Item>
                                        <Button
                                            type="primary" block
                                            onClick={handleReset}
                                        >
                                            Reset
                                        </Button>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={7} md={7} lg={4} xl={4}>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            onClick={handleDownload}
                                            block
                                        >
                                            Download Report
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>

                <OpeningStockReportList reportData={reportData} />
            </div>
        </div>
    );
};

export default OpeningStockReport;
