import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Input } from "antd"
import './style.css'
import { CloseOutlined, } from '@ant-design/icons';


function AddDetails() {

    const getRoleName = localStorage.getItem("roleName")
    const reportNav = [
        ...(getRoleName === "PURCHASE" || getRoleName === "ADMIN" || getRoleName === "PURCHASE_ADMIN" ? [{
            id: 1,
            name: 'Color',
            path: '/dagaImpex/color',
        }] : []),
        ...(getRoleName === "PURCHASE" || getRoleName === "ADMIN" || getRoleName === "PURCHASE_ADMIN" ? [{
            id: 2,
            name: 'Group',
            path: '/dagaImpex/group',
        }] : []),
        ...(getRoleName === "PURCHASE" || getRoleName === "ADMIN" || getRoleName === "PURCHASE_ADMIN" ? [{
            id: 3,
            name: 'Section',
            path: '/dagaImpex/section',
        }] : []),
        ...(getRoleName === "PURCHASE" || getRoleName === "ADMIN" || getRoleName === "PURCHASE_ADMIN" ? [{
            id: 4,
            name: 'Texture',
            path: '/dagaImpex/texture',
        }] : []),
        ...(getRoleName === "PURCHASE" || getRoleName === "ADMIN" || getRoleName === "PURCHASE_ADMIN" ? [{
            id: 5,
            name: 'Size',
            path: '/dagaImpex/size',
        }] : []),
        ...(getRoleName === "PURCHASE" || getRoleName === "ADMIN" || getRoleName === "PURCHASE_ADMIN" ? [{
            id: 6,
            name: 'Age Group',
            path: '/dagaImpex/ageGroup',
        }] : []),
        // ...(getRoleName === "PURCHASE" || getRoleName === "ADMIN" || getRoleName === "PURCHASE_ADMIN" ? [{
        //     id: 7,
        //     name: 'Rack',
        //     path: '/dagaImpex/rack',
        // }] : []),
        ...(getRoleName === "ADMIN" || getRoleName === "PURCHASE_ADMIN" ? [{
            id: 8,
            name: 'Dealer',
            path: '/dagaImpex/dealers',
        }] : []),
        ...(getRoleName === "ADMIN" || getRoleName === "PURCHASE_ADMIN" ? [{
            id: 9,
            name: 'Brands',
            path: '/dagaImpex/brands',
        }] : []),
        ...(getRoleName === "ADMIN" || getRoleName === "PURCHASE_ADMIN" ? [{
            id: 10,
            name: 'Category',
            path: '/dagaImpex/category',
        }] : []),
        ...(getRoleName === "ADMIN" || getRoleName === "PURCHASE_ADMIN" ? [{
            id: 11,
            name: 'Location',
            path: '/dagaImpex/location',
        }] : []),
        ...(getRoleName === "ADMIN" || getRoleName === "PURCHASE_ADMIN" ? [{
            id: 12,
            name: 'Season',
            path: '/dagaImpex/season',
        }] : []),
        ...(getRoleName === "ADMIN" || getRoleName === "PURCHASE_ADMIN" ? [{
            id: 13,
            name: 'Style',
            path: '/dagaImpex/style',
        }] : []),
        ...(getRoleName === "ADMIN" || getRoleName === "PURCHASE_ADMIN" ? [{
            id: 14,
            name: 'Floor',
            path: '/dagaImpex/floor',
        }] : []),
        ...(getRoleName === "ADMIN"? [{
            id: 15,
            name: 'Packaging Method',
            path: '/dagaImpex/packaging-method',
        }] : []),
    ]


    const [value, setValue] = useState("")
    const [reportData, setReportData] = useState(reportNav)

    const resetFun = () => {
        setValue("")
        setReportData(reportNav)
    }
    const onValueChange = (e) => {
        setValue(e.target.value)
        handleFilter(e.target.value)
    }
    const handleFilter = (inputValue) => {
        const newValue = reportNav.filter((item) => {
            return item.name.toLowerCase().includes(inputValue.toLowerCase());
        });
        setReportData(newValue)
    };
    return (
        <div className="subCategory-dashboard">
            <Row className="title-row mb-6">
                <Col span={16}>
                    <div className="page-header">Add Details</div>
                </Col>
                <Col span={8}>
                    <Input
                        placeholder={"Search Add Details"}
                        value={value}
                        onChange={(e) => onValueChange(e)}
                        suffix={
                            <CloseOutlined
                                onClick={() => resetFun()}
                                style={{
                                    color: 'rgba(0,0,0,.45)', cursor: "pointer"
                                }}
                            />
                        }
                    // onPressEnter={this.handleSearchButtonClick}
                    />
                </Col>
            </Row>
            <div >
                <div className="row mt-4">
                    {reportData.sort((a, b) => a.name.localeCompare(b.name)).map((item) => (
                        <Link to={item.path} className="col-xl-3 col-lg-4 col-md-6" key={item.id}>
                            <div className="reportCard l-bg-cherry">
                                <div className="reportCard-statistic-3 p-4">
                                    {/* <div className="reportCard-icon reportCard-icon-large">{item.icon}</div> */}
                                    <div className="row align-items-center mb-2 d-flex">
                                        <div className="">
                                            <h4 className="d-flex align-items-center mb-0 text-white mt-2">
                                                {item.name}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AddDetails;






// Color           ->   ADMIN             PURCHASE_ADMIN
// Group           ->   ADMIN                 PURCHASE_ADMIN
// Section         ->   ADMIN                 PURCHASE_ADMIN
// Texture         ->   ADMIN                 PURCHASE_ADMIN
// Size            ->   ADMIN             PURCHASE_ADMIN
// Age Group       ->   ADMIN             PURCHASE_ADMIN
// Rack            ->   ADMIN             PURCHASE_ADMIN


// Dealer               PURCHASE_ADMIN
// Brands                 PURCHASE_ADMIN
// Category                   PURCHASE_ADMIN
// Location                   PURCHASE_ADMIN
// Season                 PURCHASE_ADMIN
// Style                  PURCHASE_ADMIN
// Floor                  PURCHASE_ADMIN
//Packaging Method

