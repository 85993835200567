import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { Col, Drawer, Row, Input } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import BoyImage from "../../../assets/images/boy.png";
import Arrow from "../../../assets/images/Group 137.png";
import { fetchFilterProductSidebarList, fetchProductsByFiltereing } from '../../../redux/actions/productActions';
import "./CategoriesPage.css";

function SubCategroiesDrawer({ setDrawerVisibleParent, categoryName, styles, id }) {
    const history = useHistory()
    const location = useLocation();
    const dispatch = useDispatch()
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    const filteredStyles = styles?.filter(style =>
        style.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleNavigation = async (id, styleId) => {
        setDrawerVisibleParent(false)
        closeDrawer()
        try {
            await dispatch(getProductsByStyle(id, styleId));

            console.log("API Executed");

            // Redirect('/shop-grid-standard');
            history.push({
                pathname: process.env.PUBLIC_URL + "/shop-grid-standard",
            })
        } catch (error) {
            console.error("Failed to fetch products:", error);
        }
    };


    const getProductsByStyle = async (catId, styleId) => {
        const obj = {
            category: [catId],
            style: [styleId],
            brand: [],
            color: [],
            size: [],
        };

        const response = await dispatch(
            fetchFilterProductSidebarList({ category: obj.category })
        );

        let styles = null;
        if (response && response?.data?.data?.styleDetails) {
            styles = response?.data;
        }

        // Prepare query params
        const params = new URLSearchParams(location.search);
        params.set("All", true);
        params.set("limit", 1);
        params.set("category", catId);
        params.set("style", styleId);
        params.delete("brand");
        params.delete("color");
        params.delete("size");

        // Navigate to the shop grid page
        history.push({
            pathname: `/shop-grid-standard`,
            search: params.toString(),
            state: {
                stateData: "Style",
                selectTypeData: styles,
                loading: true,
            },
        });

        await dispatch(fetchProductsByFiltereing(obj));
    };


    return (
        <>
            <li className="menu-item-has-children">
                <a onClick={showDrawer} className="styleButton">
                    <div>
                        <img src={BoyImage} alt="Category" />
                    </div>

                    <div className="category-name">
                        <span>{categoryName}</span>
                        <div>
                            <img
                                style={{ width: "15px", height: "15px" }}
                                src={Arrow}
                            />
                        </div>
                    </div>
                </a>
            </li>
            <Drawer
                title={
                    <div className='breadCrumb-Text text-white text-center' style={{ fontSize: '18px', fontWeight: 'bold' }}>
                        All Styles
                    </div>
                }
                placement="right"
                onClose={closeDrawer}
                visible={drawerVisible}
                width="100dvw"
                className='styleDrawerHeader'
                closeIcon={<ArrowLeftOutlined style={{ fontSize: '20px', color: '#fff' }} />}
            >
                <Row gutter={24}>
                    <Col span={1} align="center"></Col>
                    <Col span={22} align="center">
                        <Input
                            placeholder="Search styles..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{ marginBottom: "20px" }}
                        />
                    </Col>
                    <Col span={1} align="center"></Col>
                </Row>

                {/* <Row gutter={24}>
                    <Col sm={6} md={6} key={style._id} align="center" className="my-3"> */}
                <div className="category-page">
                    <div className="category-grid">
                        {filteredStyles?.map((style) => (
                            <button
                                className='bg-transparent border-none styleButton'
                                onClick={(e) => { handleNavigation(id, style._id) }}
                            >
                                <div className="category-card">
                                    <img
                                        style={{ visibility: "hidden" }}
                                        src={BoyImage}
                                        alt={style.name}
                                    />
                                </div>

                                <div className="category-name">
                                    <span className="card-subCategory">
                                        {style.name}{" "}
                                    </span>
                                    <img
                                        style={{ width: "15px", height: "15px" }}
                                        src={Arrow}
                                        alt="Arrow"
                                    />
                                </div>
                            </button>
                        ))}
                        {/* </Col>
                </Row> */}
                    </div>
                </div>
            </Drawer>
        </>
    );
}

export default SubCategroiesDrawer;
