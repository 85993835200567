/* /////////////////////////////////////// */
/* 
Date              created by               Modifications
  
29-08-2023        Mohit                    Dashboard Update with new bar graph chart   


*/
/* /////////////////////////////////////// */

import React, { Component } from "react";
import { Row, Col, Statistic } from "antd";
import {
  LineChart,
  Line,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { connect } from "react-redux";

export class CardLineGraphDetails extends Component {
  render() {
    const { roleId } = this.props;
    console.log(this.props?.dashboardData);
    const sortedDatapurchase =
      this.props?.dashboardData?.inventoryData?.inventoryData
        ?.slice()
        .sort((a, b) => {
          const dateA = a._id.split("-").reverse().join("-"); // convert to YYYY-MM-DD format
          const dateB = b._id.split("-").reverse().join("-"); // convert to YYYY-MM-DD format
          return new Date(dateA) - new Date(dateB);
        })
        .map((item) => {
          return { ...item, totalNetAmount: Math.round(item.totalNetAmount) };
        });

    const sortedDatassales =
      this.props?.dashboardData?.salesData?.salesBillsData
        ?.slice()
        .sort((a, b) => {
          const dateA = a._id.split("-").reverse().join("-"); // convert to YYYY-MM-DD format
          const dateB = b._id.split("-").reverse().join("-"); // convert to YYYY-MM-DD format
          return new Date(dateA) - new Date(dateB);
        })
        .map((item) => {
          return { ...item, totalNetAmount: Math.round(item.totalNetAmount) };
        });
    const sortedEcomsalesData =
      this.props?.dashboardData?.ecomSalesData?.EcomSaleData?.slice()
        .sort((a, b) => {
          const dateA = a._id.split("-").reverse().join("-"); // convert to YYYY-MM-DD format
          const dateB = b._id.split("-").reverse().join("-"); // convert to YYYY-MM-DD format
          return new Date(dateA) - new Date(dateB);
        })
        .map((item) => {
          return { ...item, totalNetAmount: Math.round(item.totalNetAmount) };
        });
    return (
      <div className="card-line-details mt-4">
        <Row gutter={[40, 32]}>
          {console.log("roleId", roleId)}
          {(roleId == "629346b53e2dcbf2f6d53244" ||
            roleId == "6227b38275361cd819e16067" ||
            roleId === "66e96761812e5a81f544bc96") && (
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <div className="card order-graph">
                <div className="card-header ">
                  <h6
                    style={{
                      letterSpacing: "2px",
                    }}
                  >
                    Purchase (Last 30 Days!)
                  </h6>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div className="inventory-Chart">
                        <ResponsiveContainer width="100%" height={400}>
                          <BarChart data={sortedDatapurchase || []}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              className="check"
                              dataKey="_id"
                              angle={-25}
                              textAnchor="end"
                            />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar
                              dataKey="totalQuantity"
                              stackId="a"
                              fill="#8884d8"
                              name="Total Quantity"
                            />
                            <Bar
                              dataKey="totalNetAmount"
                              stackId="a"
                              fill="#82ca9d"
                              name="Total Net Amount"
                            />
                            {/* <Bar dataKey="totalBoxes" stackId="a" fill="#FFBC58" name="Total Boxes" /> */}
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-body mt-30">
                  <div className="media">
                    <div className="media-body">
                      <div className="inventory-chart-total">
                        <div>
                          <span>Total Quantity</span>
                          <h3 className="mb-0">
                            {this.props &&
                            this.props.dashboardData &&
                            this.props.dashboardData.inventoryData &&
                            this.props.dashboardData.inventoryData
                              .totalInventoryData
                              ? this.props?.dashboardData?.inventoryData
                                  ?.totalInventoryData[0]?.totalQuantity
                              : 0}
                          </h3>
                        </div>
                        <div>
                          <span>Total Amount</span>
                          <h3 className="mb-0">
                            {this.props &&
                            this.props.dashboardData &&
                            this.props.dashboardData.inventoryData &&
                            this.props.dashboardData.inventoryData
                              .totalInventoryData
                              ? Number(
                                  this.props?.dashboardData?.inventoryData
                                    ?.totalInventoryData[0]?.totalNetAmount
                                ).toFixed()
                              : 0}
                          </h3>
                        </div>
                        {/* <div>
                    <span>Total Boxes</span>
                    <h3 className="mb-0">{this.props && this.props.dashboardData && this.props.dashboardData.inventoryData && this.props.dashboardData.inventoryData.totalInventoryData ?
                    this.props?.dashboardData?.inventoryData?.totalInventoryData[0]?.totalBoxes : 0}</h3>
                    </div> */}
                        <div>
                          <span>Total Count</span>
                          <h3 className="mb-0">
                            {this.props &&
                            this.props.dashboardData &&
                            this.props.dashboardData.inventoryData &&
                            this.props.dashboardData.inventoryData
                              .totalInventoryData
                              ? this.props?.dashboardData?.inventoryData
                                  ?.totalInventoryData[0]?.totalCount
                              : 0}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}

          {(roleId == "629346953e2dcbf2f6d53242" ||
            roleId == "6227b38275361cd819e16067" ||
            roleId === "66e96761812e5a81f544bc96") && (
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <div className="card order-graph">
                <div className="card-header ">
                  <h6
                    style={{
                      letterSpacing: "2px",
                    }}
                  >
                    Sales (Last 30 Days!)
                  </h6>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div className="inventory-Chart">
                        <ResponsiveContainer width="100%" height={400}>
                          <BarChart data={sortedDatassales || []}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              className="check"
                              dataKey="_id"
                              angle={-25}
                              textAnchor="end"
                            />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar
                              dataKey="totalQuantity"
                              stackId="a"
                              fill="#BD212C"
                              name="Total Quantity"
                            />
                            <Bar
                              dataKey="totalNetAmount"
                              stackId="a"
                              fill="#7EBDC2"
                              name="Total Net Amount"
                            />
                            <Bar
                              dataKey="totalBoxes"
                              stackId="a"
                              fill="#282D33"
                              name="Total Boxes"
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-body mt-30">
                  <div className="media">
                    <div className="media-body">
                      <div className="inventory-chart-total">
                        <div>
                          <span>Total Quantity</span>
                          <h3 className="mb-0">
                            {
                              this.props?.dashboardData?.salesData
                                ?.totalOfSalesBill[0]?.totalQuantity
                            }
                          </h3>
                        </div>
                        <div>
                          <span>Total Amount</span>
                          <h3 className="mb-0">
                            {this.props?.dashboardData?.salesData?.totalOfSalesBill[0]?.totalNetAmount.toFixed()}
                          </h3>
                        </div>
                        <div>
                          <span>Total Boxes</span>
                          <h3 className="mb-0">
                            {
                              this.props?.dashboardData?.salesData
                                ?.totalOfSalesBill[0]?.totalBoxes
                            }
                          </h3>
                        </div>
                        <div>
                          <span>Total Count</span>
                          <h3 className="mb-0">
                            {
                              this.props?.dashboardData?.salesData
                                ?.totalOfSalesBill[0]?.totalCount
                            }
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}

          {(roleId == "629346953e2dcbf2f6d53242" ||
            roleId == "6227b38275361cd819e16067" ||
            roleId === "66e96761812e5a81f544bc96") && (
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <div className="card order-graph">
                <div className="card-header ">
                  <h6
                    style={{
                      letterSpacing: "2px",
                    }}
                  >
                    Ecommerce Sales (Last 30 Days!)
                  </h6>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <div className="inventory-Chart">
                        <ResponsiveContainer width="100%" height={400}>
                          <BarChart data={sortedEcomsalesData || []}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              className="check"
                              dataKey="_id"
                              angle={-25}
                              textAnchor="end"
                            />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar
                              dataKey="totalQuantity"
                              stackId="a"
                              fill="#BD212C"
                              name="Total Quantity"
                            />
                            <Bar
                              dataKey="totalNetAmount"
                              stackId="a"
                              fill="#7EBDC2"
                              name="Total Net Amount"
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-body mt-30">
                  <div className="media">
                    <div className="media-body">
                      <div className="inventory-chart-total">
                        <div>
                          <span>Total Quantity</span>
                          <h3 className="mb-0">
                            {
                              this.props?.dashboardData?.ecomSalesData &&
                              this.props.dashboardData.ecomSalesData
                                .totalofEcomSaleData?.length > 0
                                ? this.props.dashboardData.ecomSalesData
                                    .totalofEcomSaleData[0].totalQuantity
                                : "" // Fallback content
                            }
                          </h3>
                        </div>
                        <div>
                          <span>Total Amount</span>
                          <h3 className="mb-0">
                            {
                              this.props?.dashboardData?.ecomSalesData &&
                              this.props.dashboardData.ecomSalesData
                                .totalofEcomSaleData?.length > 0
                                ? this.props.dashboardData.ecomSalesData.totalofEcomSaleData[0].totalNetAmount.toFixed()
                                : "" // Fallback content
                            }
                          </h3>
                        </div>
                        <div>
                          <span>Total Count</span>
                          <h3 className="mb-0">
                            {
                              this.props?.dashboardData?.ecomSalesData &&
                              this.props.dashboardData.ecomSalesData
                                .totalofEcomSaleData?.length > 0
                                ? this.props.dashboardData.ecomSalesData
                                    .totalofEcomSaleData[0].totalCount
                                : "" // Fallback content
                            }
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}

          {/* <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <div className="card order-graph">
              <div className="card-header ">
                <h6
                  style={{
                    letterSpacing: "2px",
                  }}
                >
                  Total purchase
                </h6>
                <Row>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <LineChart width={250} height={100} data={data}>
                      <Line
                        type="monotone"
                        dataKey="pv"
                        stroke="#13c9ca"
                        strokeWidth={1}
                      />
                      <Tooltip />
                    </LineChart>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="stats-line-percent">
                      <Statistic
                        value={9.3}
                        precision={2}
                        valueStyle={{ color: "#cf1322" }}
                        prefix={<ArrowDownOutlined />}
                        suffix="%"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <div className="media">
                  <div className="media-body">
                    <span>Monthly purchase</span>
                    <h2 className="mb-0">2154</h2>
                    <p>
                      0.13%{" "}
                      <span>
                        <i className="fa fa-angle-up"></i>
                      </span>
                    </p>
                    <h6 className="font-weight-bold">Avg Gross purchase</h6>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting
                    </p>
                  </div>
                  <div className="bg-info rounded">
                    <div className="small-box">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-credit-card"
                      >
                        <rect
                          x="1"
                          y="4"
                          width="22"
                          height="16"
                          rx="2"
                          ry="2"
                        ></rect>
                        <line x1="1" y1="10" x2="23" y2="10"></line>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col> */}

          {/* <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <div className="card order-graph">
              <div className="card-header ">
                <h6
                  style={{
                    letterSpacing: "2px",
                  }}
                >
                  Total transaction
                </h6>
                <Row>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <LineChart width={250} height={100} data={data}>
                      <Line
                        type="monotone"
                        dataKey="pv"
                        stroke="#ffbc58"
                        strokeWidth={1}
                      />
                      <Tooltip />
                    </LineChart>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="stats-line-percent">
                      <Statistic
                        value={9.3}
                        precision={2}
                        valueStyle={{ color: "#cf1322" }}
                        prefix={<ArrowDownOutlined />}
                        suffix="%"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <div className="media">
                  <div className="media-body">
                    <span>Cash on hand</span>
                    <h2 className="mb-0">4672</h2>
                    <p>
                      0.8%{" "}
                      <span>
                        <i className="fa fa-angle-up"></i>
                      </span>
                    </p>
                    <h6 className="font-weight-bold">Details about cash</h6>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting
                    </p>
                  </div>
                  <div className="bg-warning rounded">
                    <div className="small-box">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-shopping-cart"
                      >
                        <circle cx="9" cy="21" r="1"></circle>
                        <circle cx="20" cy="21" r="1"></circle>
                        <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <div className="card order-graph">
              <div className="card-header ">
                <h6>User registered</h6>
                <Row>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <LineChart width={250} height={100} data={data}>
                      <Line
                        type="monotone"
                        dataKey="pv"
                        stroke="#a5a5a5 "
                        strokeWidth={1}
                      />
                      <Tooltip />
                    </LineChart>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className="stats-line-percent">
                      <Statistic
                        value={11.28}
                        precision={2}
                        valueStyle={{ color: "#3f8600" }}
                        prefix={<ArrowUpOutlined />}
                        suffix="%"
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="card-body">
                <div className="media">
                  <div className="media-body">
                    <span
                      style={{
                        letterSpacing: "2px",
                      }}
                    >
                      Total Users
                    </span>
                    <h2 className="mb-0">0782</h2>
                    <p>
                      0.25%{" "}
                      <span>
                        <i className="fa fa-angle-up"></i>
                      </span>
                    </p>
                    <h6 className="font-weight-bold">
                      Avg user registration per week
                    </h6>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting
                    </p>
                  </div>
                  <div className="bg-secondary rounded">
                    <div className="small-box">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-calendar"
                      >
                        <rect
                          x="3"
                          y="4"
                          width="18"
                          height="18"
                          rx="2"
                          ry="2"
                        ></rect>
                        <line x1="16" y1="2" x2="16" y2="6"></line>
                        <line x1="8" y1="2" x2="8" y2="6"></line>
                        <line x1="3" y1="10" x2="21" y2="10"></line>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col> */}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboardData: state.dashboardData.dashboardData,
});
export default connect(mapStateToProps, {})(CardLineGraphDetails);
